import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import classApi from "../../api/Classes";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import ClassesTable from "../../components/tables/primeTable/classes/ClassesTable";
import TableLoading from "../../components/skeleton/TableLoading";

const SchoolClasses = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [classData, setClassData] = useState([]);
  const [classModalVisibility, setClassModalVisibility] = useState(false);
  const [selectedClassDetail, setSelectedClassDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [modalType, setModalType] = useState("update");

  useEffect(() => {
    // fetch classes
    try {
      getClasses();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load classes. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { classAdded: false, classUpdated: false },
        });
      }
    }
  }, [user]);

  // function to get all classes
  const getClasses = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClassData([]);
      } else {
        setClassData(response_data.response);
      }

      // check if a new class was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const classAdded = !empty(paramState.classAdded)
          ? paramState.classAdded
          : false;
        const classUpdated = !empty(paramState.classUpdated)
          ? paramState.classUpdated
          : false;
        if (classAdded === true || classUpdated === true) {
          const actionType = classAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Class ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { classAdded: false, classUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  //function to toggle class visibility
  const toggleClassVisiblity = async () => {
    try {
      if (classModalVisibility) setClassModalVisibility(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const active =
        !empty(selectedClassDetail) && !empty(selectedClassDetail.active)
          ? "Yes"
          : "No";
      const classId =
        !empty(selectedClassDetail) && !empty(selectedClassDetail._id)
          ? selectedClassDetail._id
          : "";
      const response = await classApi.toggleClassVisibility(
        classId,
        active,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to ${
                !empty(selectedClassDetail) &&
                !empty(selectedClassDetail.active) &&
                selectedClassDetail.active === false
                  ? "enable"
                  : "disable"
              } class with title, ${
                !empty(selectedClassDetail.title)
                  ? selectedClassDetail.title
                  : ""
              }`;
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Class, ${
          !empty(selectedClassDetail.title) ? selectedClassDetail.title : ""
        }, was ${
          !empty(selectedClassDetail) &&
          !empty(selectedClassDetail.active) &&
          selectedClassDetail.active === true
            ? "disabled"
            : "enabled"
        } successfully!`
      );
      return getClasses();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  // delete class
  const deleteClass = async () => {
    try {
      if (classModalVisibility) setClassModalVisibility(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const classId =
        !empty(selectedClassDetail) && !empty(selectedClassDetail._id)
          ? selectedClassDetail._id
          : "";
      const response = await classApi.deleteClass(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to delete class";
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Class, ${
          !empty(selectedClassDetail.title) ? selectedClassDetail.title : ""
        }, was deleted successfully!`
      );
      return getClasses();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to show confirm modal
  const showDisableConfirm = (data) => {
    setModalType("update");
    setSelectedClassDetail(data);
    setClassModalVisibility(true);
  };

  //function to hide confirm modal
  const hideClassModal = () => {
    setClassModalVisibility(false);
  };

  //go to update class page
  const gotToUpdateClass = (data) => {
    const classId = !empty(data) && !empty(data._id) ? data._id : "";
    navigate(`/class/${classId}/update`);
  };

  // delete class
  const showDeleteClassModal = (data) => {
    setModalType("delete");
    setSelectedClassDetail(data);
    setClassModalVisibility(true);
  };

  const classModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideClassModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={() =>
          modalType === "update" ? toggleClassVisiblity() : deleteClass()
        }
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              button
              buttonText="Add Class"
              link="/class/add"
              title="Class"
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <ClassesTable
                classes={classData}
                confirmDisableSelected={showDisableConfirm}
                goToUpdateClassScreen={gotToUpdateClass}
                goToDeleteClassScreen={showDeleteClassModal}
              />
            ) : (
              <TableLoading rows={20} cols={3} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Dialog
        visible={classModalVisibility}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={classModalFooter}
        onHide={hideClassModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to{" "}
              {modalType === "update" ? (
                !empty(selectedClassDetail) &&
                !empty(selectedClassDetail.active) &&
                selectedClassDetail.active ? (
                  <span style={{ color: "red" }}>
                    <strong>disable</strong>
                  </span>
                ) : (
                  <span style={{ color: "green" }}>
                    <strong>enable</strong>
                  </span>
                )
              ) : (
                <span style={{ color: "red" }}>
                  <strong>delete</strong>
                </span>
              )}{" "}
              the selected class -{" "}
              <strong>
                {!empty(selectedClassDetail) &&
                !empty(selectedClassDetail.title)
                  ? selectedClassDetail.title
                  : ""}
              </strong>
              {selectedClassDetail && (
                <b>
                  {!empty(selectedClassDetail.name)
                    ? selectedClassDetail.name
                    : ""}
                </b>
              )}
              ?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default SchoolClasses;
