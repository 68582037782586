import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useNavigate, useLocation } from "react-router-dom";
import {
  empty,
  findGrade,
  prepareResponseData,
  reIndex,
} from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";
import "./Assessment.css";

// api
import studentApi from "../../api/Student";
import subjectApi from "../../api/Subject";
import classApi from "../../api/Classes";
import assessmentApi from "../../api/Assessment";
import termApi from "../../api/Terms";
import sessionApi from "../../api/Session";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import StudentsTable from "../../components/tables/primeTable/students/StudentsTable";
import { Form, Formik } from "formik";
import InputField from "../../components/form/InputField";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import * as Yup from "yup";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { FaExclamationTriangle, FaTrash } from "react-icons/fa";
import { Button } from "primereact/button";
import TableLoading from "../../components/skeleton/TableLoading";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const validationSchema = Yup.object().shape({
  ca1: Yup.string().max(2).optional(),
  ca2: Yup.string().max(2).optional(),
  exam: Yup.string().max(2).optional(),
});

const validationCommentSchema = Yup.object().shape({
  comment: Yup.string().required("This field is required"),
});

const RecordAssessment = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const paramState = !empty(location.state) ? location.state : {};
  const classId =
    !empty(paramState) && !empty(paramState.classId) ? paramState.classId : "";
  const sessionId =
    !empty(user) && !empty(user.currentSessionId) ? user.currentSessionId : "";
  const termId =
    !empty(user) && !empty(user.currentTermId) ? user.currentTermId : "";
  const subjectId =
    !empty(paramState) && !empty(paramState.subjectId)
      ? paramState.subjectId
      : "";
  //ref
  const toastTR = useRef(null);
  // states
  const [studentData, setStudentData] = useState([]);
  const [subjectData, setSubjectData] = useState({});
  const [classData, setClassData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [search, setSearch] = useState("");
  const [studentAssessment, setStudentAssessment] = useState([]);
  const [reIndexedSubjects, setReIndexedSubjects] = useState({});
  const [isStudentDialog, setIsStudentDialog] = useState(false);
  const [activeDetails, setActiveDetails] = useState({});
  const [termData, setTermData] = useState({});
  const [sessionData, setSessionData] = useState({});
  const [removingData, setRemovingData] = useState({});
  const [assessmentModalVisible, setAssessmentModalVisible] = useState(false);
  const [studentReportComment, setStudentReportComment] = useState("");
  const [remarks, setRemarks] = useState([]);
  const [classCategoryId, setClassCategoryId] = useState([]);
  const [defaultCategoryId, setDefaultCategoryId] = useState([]);
  const [scoreGrades, setScoreGrades] = useState([]);
  const [hasPaid, setHasPaid] = useState(false);
  const [initialValues, setInitialValues] = useState({
    ca1: 0,
    ca2: 0,
    exam: 0,
  });
  const [selectedStudentTotal, setSelectedStudentTotal] = useState(0);
  const currentTermTitle =
    !empty(user) && !empty(user.currentTermTitle) ? user.currentTermTitle : "";
  const currentSessionTitle =
    !empty(user) && !empty(user.currentSessionTitle)
      ? user.currentSessionTitle
      : "";
  const currentTermId =
    !empty(user) && !empty(user.currentTermId) ? user.currentTermId : "";
  const currentSessionId =
    !empty(user) && !empty(user.currentSessionId) ? user.currentSessionId : "";

  useEffect(() => {
    // fetch student
    try {
      if (!empty(classId) && !empty(subjectId)) {
        getStudents();
        getSubjectDetails();
        getClassDetails();
        getSubjectsInClass();
        getTermDetails();
        getSessionDetails();
      } else {
        navigate("/404");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load student. Please try again later."
      );
    }
  }, [first, rows, search, classId]);

  // function to get all student
  const getStudents = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const page = first / rows;
      let response;
      response = await studentApi.getStudentsInClass(
        schoolId,
        page,
        rows,
        search,
        classId,
        "assessment",
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudentData([]);
      } else {
        setStudentData(response_data.response);
        setTotalRecords(!empty(response_data.count) ? response_data.count : 0);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  // function to get all student
  const getSingleStudent = async (studentId) => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.getSingleStudent(
        studentId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return assessmentData({});
      } else {
        const data = !empty(response_data.response)
          ? response_data.response
          : {};
        assessmentData(data);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    }
  };

  const getSubjectDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSingleSubject(
        subjectId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setSubjectData({});
      } else {
        setSubjectData(response_data.response);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    }
  };

  const getClassDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSingleClass(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setClassData({});
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : {};
        const _classCategoryId =
          !empty(data) && !empty(data.categoryId) ? data.categoryId : "";
        const _defaultCategoryId =
          !empty(data) && !empty(data.defaultCategoryId)
            ? data.defaultCategoryId
            : "";
        setClassCategoryId(_classCategoryId);
        setDefaultCategoryId(_defaultCategoryId);
        const scoreGrades =
          !empty(user) && !empty(user.scoreGrades) ? user.scoreGrades : [];
        const reIndexedScoreGrades = !empty(scoreGrades)
          ? reIndex(scoreGrades, "categoryId")
          : {};
        const _remark =
          !empty(reIndexedScoreGrades) &&
          !empty(reIndexedScoreGrades[classCategoryId]) &&
          !empty(reIndexedScoreGrades[classCategoryId].remarks)
            ? reIndexedScoreGrades[classCategoryId].remarks
            : [];
        setRemarks(_remark);
        setScoreGrades(reIndexedScoreGrades);
        setClassData(data);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    }
  };

  const getTermDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await termApi.getSingleTerm(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setTermData({});
      } else {
        setTermData(response_data.response);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    }
  };

  const getSessionDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await sessionApi.getSession(sessionId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setSessionData({});
      } else {
        setSessionData(response_data.response);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    }
  };

  const getSubjectsInClass = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSubjectsInClass(
        classId,
        schoolId,
        1000,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setReIndexedSubjects({});
      } else {
        const classSubjects = response_data.response;
        const reIndexedSubjects = reIndex(classSubjects, "_id");
        setReIndexedSubjects(reIndexedSubjects);
      }
    } catch (error) {
      responseDailog("error", "Internal Server", `Something went wrong.`);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const handleSubmit = async (values) => {
    try {
      if (!isActionLoading) setIsActionLoading(true);
      if (empty(values)) {
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(activeDetails) && !empty(activeDetails._id)
          ? activeDetails._id
          : "";
      const ca1 = !empty(values.ca1) ? values.ca1 : "";
      const ca2 = !empty(values.ca2) ? values.ca2 : "";
      const exam = !empty(values.exam) ? values.exam : "";
      const response = await assessmentApi.recordAssessment(
        schoolId,
        studentId,
        ca1,
        ca2,
        exam,
        sessionId,
        termId,
        subjectId,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      } else {
        setIsStudentDialog(false);
        await getStudents();
        responseDailog("success", "Success", "Operation successful");
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleCommentSubmit = async (values) => {
    try {
      if (!isActionLoading) setIsActionLoading(true);
      if (empty(values)) {
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(activeDetails) && !empty(activeDetails._id)
          ? activeDetails._id
          : "";
      const comment = !empty(values.comment) ? values.comment : "";
      const response = await assessmentApi.addStudentPerformanceComment(
        schoolId,
        studentId,
        sessionId,
        termId,
        comment,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      } else {
        responseDailog("success", "Success", "Comment added successfully.");
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    } finally {
      setIsActionLoading(false);
    }
  };

  const openAssessmentModal = (data) => {
    const hasPaid =
      !empty(data) && !empty(data.paid) && data.paid === "Yes" ? true : false;
    if (hasPaid) {
      assessmentData(data);
    }
    setHasPaid(hasPaid);
    setSelectedStudentTotal(0);
    setIsStudentDialog(true);
  };

  const assessmentData = (data) => {
    try {
      const studentAssessmentData =
        !empty(data) && !empty(data.assessment) ? data.assessment : [];
      const studentReport =
        !empty(data) && !empty(data.reports) ? data.reports : [];
      const currentStudentAssessmentData = studentAssessmentData.filter(
        (item) => {
          const itemSessionId =
            !empty(item) && !empty(item.sessionId) ? item.sessionId : "";
          const itemTermId =
            !empty(item) && !empty(item.termId) ? item.termId : "";
          const subjectId =
            !empty(item) && !empty(item.subjectId) ? item.subjectId : "";
          return (
            itemSessionId === sessionId &&
            itemTermId === termId &&
            !empty(reIndexedSubjects[subjectId])
          );
        }
      );
      const reIndexedAssessment = reIndex(
        currentStudentAssessmentData,
        "subjectId"
      );
      const currentSubject =
        !empty(reIndexedAssessment) && !empty(reIndexedAssessment[subjectId])
          ? reIndexedAssessment[subjectId]
          : {};
      const ca1 =
        !empty(currentSubject) && !empty(currentSubject.ca1)
          ? currentSubject.ca1
          : 0;
      const ca2 =
        !empty(currentSubject) && !empty(currentSubject.ca2)
          ? currentSubject.ca2
          : 0;
      const exam =
        !empty(currentSubject) && !empty(currentSubject.exam)
          ? currentSubject.exam
          : 0;
      setInitialValues({
        ca1,
        ca2,
        exam,
      });

      let comment = "";
      for (let i = 0; i < studentReport.length; i++) {
        const itemSessionId =
          !empty(studentReport[i]) && !empty(studentReport[i].sessionId)
            ? studentReport[i].sessionId
            : "";
        const itemTermId =
          !empty(studentReport[i]) && studentReport[i].termId
            ? studentReport[i].termId
            : "";
        if (itemSessionId === sessionId && itemTermId === termId) {
          comment =
            !empty(studentReport[i]) && !empty(studentReport[i].comment)
              ? studentReport[i].comment
              : "";
          break;
        }
      }

      setStudentReportComment(comment);
      setStudentAssessment(currentStudentAssessmentData);
      setActiveDetails(data);
    } catch (error) {}
  };

  const removeAssessment = async () => {
    let studentId = "";
    try {
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      if (empty(removingData)) {
        responseDailog("error", "Error Alert", "Failed to remove assessment.");
      }
      studentId =
        !empty(removingData) && !empty(removingData.studentId)
          ? removingData.studentId
          : "";
      const termId =
        !empty(removingData) && !empty(removingData.termId)
          ? removingData.termId
          : "";
      const sessionId =
        !empty(removingData) && !empty(removingData.sessionId)
          ? removingData.sessionId
          : "";
      const subjectId =
        !empty(removingData) && !empty(removingData.subjectId)
          ? removingData.subjectId
          : "";
      const response = await assessmentApi.removeAssessment(
        schoolId,
        studentId,
        sessionId,
        termId,
        subjectId,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      } else {
        await getStudents();
        await getSingleStudent(studentId);
        return responseDailog(
          "success",
          "Success",
          "Assessment removed successfully."
        );
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    } finally {
      setIsActionLoading(false);
    }
  };

  const removeAssessments = async () => {
    let studentId = "";
    try {
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      if (empty(removingData)) {
        responseDailog("error", "Error Alert", "Failed to remove assessment.");
      }
      studentId =
        !empty(removingData) && !empty(removingData.studentId)
          ? removingData.studentId
          : "";
      const response = await assessmentApi.removeAssessments(
        schoolId,
        studentId,
        currentSessionId,
        currentTermId,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      } else {
        await getStudents();
        await getSingleStudent(studentId);
        return responseDailog(
          "success",
          "Success",
          "Assessment removed successfully."
        );
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    } finally {
      setIsActionLoading(false);
    }
  };

  const openRemoveAssessmentModal = (
    modalType = "",
    sessionId = "",
    termId = "",
    studentId = "",
    subjectId = ""
  ) => {
    setAssessmentModalVisible(true);
    setRemovingData({ modalType, subjectId, sessionId, studentId, termId });
  };

  //function to hide school edit modal
  const closeAssessmentDialog = () => {
    setSelectedStudentTotal(0);
    setIsStudentDialog(false);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setFirst(0);
  };

  const subjectTemplate = (data) => {
    const assessmentSubjectId =
      !empty(data) && !empty(data.subjectId) ? data.subjectId : "";
    const subjectTitle =
      !empty(reIndexedSubjects) &&
      !empty(reIndexedSubjects[assessmentSubjectId]) &&
      !empty(reIndexedSubjects[assessmentSubjectId].title)
        ? reIndexedSubjects[assessmentSubjectId].title
        : "";

    return subjectTitle;
  };

  const ca1Template = (data) => {
    const ca1 = !empty(data) && !empty(data.ca1) ? data.ca1 : 0;
    return ca1;
  };

  const ca2Template = (data) => {
    const ca2 = !empty(data) && !empty(data.ca2) ? data.ca2 : 0;
    return ca2;
  };

  const examTemplate = (data) => {
    const exam = !empty(data) && !empty(data.exam) ? data.exam : 0;
    return exam;
  };

  let assessmentSum = 0;
  let assessmentCount = 0;
  const assessmentLength = studentAssessment.length;
  const totalTemplate = (ca1, ca2, examScore) => {
    const total = parseInt(ca1) + parseInt(ca2) + parseInt(examScore);
    assessmentSum += total;
    if (assessmentCount + 1 === assessmentLength) {
      setSelectedStudentTotal(assessmentSum);
    }
    assessmentCount++;

    let score_tag = "";
    if (total >= 70) {
      score_tag = "green";
    } else if (total >= 60 && total <= 69.9) {
      score_tag = "#633ccd";
    } else if (total >= 50 && total <= 59.9) {
      score_tag = "orange";
    } else if (total >= 45 && total <= 49.9) {
      score_tag = "gray";
    } else {
      score_tag = "red";
    }

    return <span style={{ color: score_tag }}>{total}</span>;
  };

  const gradeTemplate = (ca1, ca2, exam) => {
    const total = parseInt(ca1) + parseInt(ca2) + parseInt(exam);

    const getGrade = findGrade(remarks, scoreGrades, defaultCategoryId, total);
    const grade =
      !empty(getGrade) && !empty(getGrade.grade) ? getGrade.grade : "";

    return grade;
  };

  const remarkTemplate = (ca1, ca2, exam) => {
    const total = parseInt(ca1) + parseInt(ca2) + parseInt(exam);

    const getGrade = findGrade(remarks, scoreGrades, defaultCategoryId, total);
    const remark =
      !empty(getGrade) && !empty(getGrade.remark) ? getGrade.remark : "";

    return remark;
  };

  const removeAssessmentTemplate = (data) => {
    const _sessionId =
      !empty(data) && !empty(data.sessionId) ? data.sessionId : "";
    const _termId = !empty(data) && !empty(data.termId) ? data.termId : "";
    const _subjectId =
      !empty(data) && !empty(data.subjectId) ? data.subjectId : "";
    const _studentId =
      !empty(activeDetails) && !empty(activeDetails._id)
        ? activeDetails._id
        : "";
    return (
      <FaTrash
        onClick={() =>
          openRemoveAssessmentModal(
            "single",
            _sessionId,
            _termId,
            _studentId,
            _subjectId
          )
        }
        color="red"
        style={{ cursor: "pointer" }}
      />
    );
  };

  //function to hide confirm modal
  const hideRemoveAssessmentModal = () => {
    setAssessmentModalVisible(false);
  };

  const disableAssessmentModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideRemoveAssessmentModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={() => {
          setAssessmentModalVisible(false);
          !empty(removingData) &&
          !empty(removingData.modalType) &&
          removingData.modalType === "single"
            ? removeAssessment()
            : removeAssessments();
        }}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              title="Record Assessments"
              children={
                <>
                  <span style={{ marginRight: 30 }}>
                    Session: <strong>{currentSessionTitle}</strong>
                  </span>
                  <span style={{ marginRight: 30 }}>
                    Term: <strong>{currentTermTitle}</strong>
                  </span>
                  <span style={{ marginRight: 30 }}>
                    Class:{" "}
                    <strong>
                      {!empty(classData) && !empty(classData.title)
                        ? classData.title
                        : ""}
                    </strong>
                  </span>

                  <span>
                    Subject:{" "}
                    <strong>
                      {!empty(subjectData) && !empty(subjectData.title)
                        ? subjectData.title
                        : ""}
                    </strong>
                  </span>
                </>
              }
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <StudentsTable
                students={studentData}
                openAssessmentModal={openAssessmentModal}
                onPageChange={onPageChange}
                loading={isLoading}
                rows={rows}
                totalRecords={totalRecords}
                first={first}
                search={search}
                onSearchChange={onSearchChange}
                assessment={true}
                recordScores={true}
              />
            ) : (
              <TableLoading rows={7} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Dialog
        visible={isStudentDialog}
        style={{ width: "70rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={`Record Assessment`}
        modal
        className="p-fluid"
        onHide={closeAssessmentDialog}
      >
        <>
          <p>
            <span style={{ marginRight: 40 }}>
              Student Name:
              <strong>
                {!empty(activeDetails) && !empty(activeDetails.fullName)
                  ? " " + activeDetails.fullName
                  : ""}
              </strong>
            </span>
            <span style={{ marginRight: 40 }}>
              Subject:
              <strong>
                {!empty(subjectData) && !empty(subjectData.title)
                  ? " " + subjectData.title
                  : ""}
              </strong>
            </span>
            <span>
              Class:
              <strong>
                {!empty(classData) && !empty(classData.title)
                  ? " " + classData.title
                  : ""}
              </strong>
            </span>
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 8,
                }}
              >
                <InputField
                  placeholder="Enter CA1 Score"
                  name="ca1"
                  height={50}
                  type="number"
                  labelTitle="CA1"
                />
                <InputField
                  placeholder="Enter CA2 Score"
                  name="ca2"
                  height={50}
                  type="number"
                  labelTitle="CA2"
                />
                <InputField
                  placeholder="Enter Exam Score"
                  name="exam"
                  height={50}
                  type="number"
                  labelTitle="Exam"
                />
              </div>
              <ButtonIcon
                height={45}
                marginTop={2}
                color="#ffffff"
                backgroundColor="#633ccd"
                width={120}
                borderColor="#633ccd"
                buttonText="Update"
                type="submit"
              />
            </Form>
          </Formik>
          {hasPaid ? (
            <>
              <div>
                <div style={{ marginTop: 50, marginBottom: 20 }}>
                  <strong style={{ fontSize: 20 }}>Recorded Assessments</strong>
                  <p style={{ marginTop: 10, fontSize: 14, color: "red" }}>
                    <em>
                      <strong>Note: </strong>For selected{" "}
                      <strong>Session</strong> and <strong>Term</strong>
                    </em>
                  </p>
                </div>

                <div className="mb-30">
                  <ButtonIcon
                    backgroundColor="red"
                    borderColor="red"
                    color="#ffffff"
                    textMarginLeft={12}
                    buttonText={"Delete Assessments"}
                    icon={<FaTrash />}
                    onClick={() =>
                      openRemoveAssessmentModal(
                        "multiple",
                        currentSessionId,
                        currentTermId,
                        !empty(activeDetails) && !empty(activeDetails._id)
                          ? activeDetails._id
                          : ""
                      )
                    }
                  />
                </div>
                {
                  <DataTable
                    value={studentAssessment}
                    tableStyle={{ minWidth: "30rem" }}
                    dataKey="_id"
                  >
                    <Column
                      field="subjects"
                      header="Subjects"
                      body={subjectTemplate}
                    ></Column>
                    <Column
                      field="ca1"
                      header="CA1"
                      body={ca1Template}
                    ></Column>
                    <Column
                      field="ca2"
                      header="CA2"
                      body={ca2Template}
                    ></Column>
                    <Column
                      field="exam"
                      header="Exam"
                      body={examTemplate}
                    ></Column>
                    <Column
                      field="total"
                      header="Total"
                      body={(rowData) => {
                        const examScore = examTemplate(rowData);
                        const ca1 = ca1Template(rowData);
                        const ca2 = ca2Template(rowData);
                        return totalTemplate(ca1, ca2, examScore);
                      }}
                    ></Column>
                    <Column
                      field="grade"
                      header="Grade"
                      body={(rowData) => {
                        const examScore = examTemplate(rowData);
                        const ca1 = ca1Template(rowData);
                        const ca2 = ca2Template(rowData);
                        return gradeTemplate(ca1, ca2, examScore);
                      }}
                    ></Column>
                    <Column
                      field="remark"
                      header="Remark"
                      body={(rowData) => {
                        const examScore = examTemplate(rowData);
                        const ca1 = ca1Template(rowData);
                        const ca2 = ca2Template(rowData);
                        return remarkTemplate(ca1, ca2, examScore);
                      }}
                    ></Column>
                    <Column
                      field="action"
                      header="Action"
                      body={removeAssessmentTemplate}
                    ></Column>
                  </DataTable>
                }
                <div className="mt-20 flex assessment_summary">
                  <span className="mr-60">
                    <strong>No. of Subjects: </strong>
                    {studentAssessment.length}
                  </span>
                  <span className="mr-60">
                    <strong>Total: </strong>
                    {selectedStudentTotal}
                  </span>
                  <span>
                    <strong>Average: </strong>
                    {typeof selectedStudentTotal === NaN ||
                    typeof studentAssessment.length === NaN ||
                    studentAssessment.length <= 0
                      ? 0
                      : (
                          selectedStudentTotal / studentAssessment.length
                        ).toFixed(1)}
                    %
                  </span>
                </div>
                <div style={{ marginTop: 40, marginBottom: 10 }}>
                  <strong style={{ fontSize: 18 }}>
                    Class Teacher's Comment
                  </strong>
                  <br />
                  <strong>
                    {currentSessionTitle} - {currentTermTitle}
                  </strong>
                  <p style={{ marginTop: 10, fontSize: 14, color: "red" }}>
                    <em>
                      <strong>Note: </strong>Enter comment for student's overall
                      performance
                    </em>
                  </p>
                </div>
              </div>
              <Formik
                initialValues={{ comment: studentReportComment }}
                validationSchema={validationCommentSchema}
                onSubmit={handleCommentSubmit}
              >
                <Form
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 8,
                    }}
                  >
                    <InputField
                      placeholder="Enter comment for student performance"
                      name="comment"
                      height={150}
                      labelTitle="Comment"
                      as="textarea"
                      rows={8}
                      cols={10}
                    />
                  </div>
                  <ButtonIcon
                    height={45}
                    marginTop={2}
                    color="#ffffff"
                    backgroundColor="#633ccd"
                    width={120}
                    borderColor="#633ccd"
                    buttonText="Update"
                    type="submit"
                  />
                </Form>
              </Formik>{" "}
            </>
          ) : (
            <div className="mt-50 assessment_not_paid">
              <FaExclamationTriangle color="red" size={25} />
              <p className="mt-10 fs-18 mb-5">
                <strong>Recorded Assessment Unavailable</strong>
              </p>
              <p>This student has an outstanding portal levy</p>
            </div>
          )}
        </>
      </Dialog>

      <Dialog
        visible={assessmentModalVisible}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={disableAssessmentModalFooter}
        onHide={hideRemoveAssessmentModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to delete the selected student
              assessment(s)?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default RecordAssessment;
