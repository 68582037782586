import { empty } from "../Utilities/utils";
import client from "./Client";

const updateSchoolSettings = (
  closing_date = "",
  opening_date = "",
  remarks = [],
  school_id,
  type = "",
  token
) =>
  client.post(
    `/settings/update`,
    {
      closing_date,
      opening_date,
      remarks,
      school_id,
      type,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const updatePassword = (
  school_id = "",
  current_password = "",
  new_password = "",
  confirm_password = "",
  token
) =>
  client.post(
    `/settings/password/update`,
    {
      school_id,
      current_password,
      new_password,
      confirm_password,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getNumberOfStudentsInSchool = (school_id, token) =>
  client.get(`/${school_id}/stat/students`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getSchoolAssessmentsData = (school_id, token) =>
  client.get(`/${school_id}/classes/students/assessments-data`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getNumberOfSubjectsInSchool = (school_id, token) =>
  client.get(`/${school_id}/stat/subjects`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getNoOfSchoolStudentsByGender = (school_id, token) =>
  client.get(`/${school_id}/stat/students/gender-data`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getNumberOfStaff = (school_id, token) =>
  client.get(`/${school_id}/stat/staff`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getNumberOfClasses = (school_id, token) =>
  client.get(`/${school_id}/stat/classes`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getStudentAssessmentPerformance = (school_id, token) =>
  client.get(`/${school_id}/stat/students/assessment`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getNoOfSchoolStaffByGender = (school_id, token) =>
  client.get(`/${school_id}/stat/staff/gender-data`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getRecentlyRegisteredStudents = (
  school_id,
  page = 0,
  rows = 10,
  search = "",
  token
) => {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (rows) {
    params.rows = rows;
  }
  if (search) {
    params.search = search;
  }

  return client.get(`/${school_id}/recently-registered/students`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const getRecentlyRegisteredStaff = (
  school_id,
  page = 0,
  rows = 10,
  search = "",
  token
) => {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (rows) {
    params.rows = rows;
  }
  if (search) {
    params.search = search;
  }

  return client.get(`/${school_id}/recently-registered/staff`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export default {
  updateSchoolSettings,
  getNumberOfStudentsInSchool,
  getSchoolAssessmentsData,
  getNumberOfSubjectsInSchool,
  getNoOfSchoolStudentsByGender,
  getNumberOfStaff,
  getNumberOfClasses,
  getStudentAssessmentPerformance,
  getNoOfSchoolStaffByGender,
  getRecentlyRegisteredStudents,
  updatePassword,
  getRecentlyRegisteredStaff,
};
