import { useContext, useRef, useState, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import classApi from "../../api/Classes";
import staffApi from "../../api/Staff";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { AuthContext } from "../Root/ProtectedRoute";
import { empty, prepareResponseData } from "../../Utilities/utils";
import InputField from "../../components/form/InputField";
import { useNavigate } from "react-router-dom";
import SelectField from "../../components/form/SelectField";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  class_teacher_id: Yup.string().required(required),
  class_id: Yup.string().required(required),
});

const UpdateClassTeachers = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const toastTR = useRef(null);
  const [classData, setClassData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getClasses();
    getStaff();
  }, []);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getClasses = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setClassData([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setClassData(data);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const getStaff = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getStaff(schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setStaffData([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setStaffData(data);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        responseDailog("error", "Error Alert", "Something went wrong.");
      }
      const classTeacherId = !empty(values.class_teacher_id)
        ? values.class_teacher_id
        : "";
      const classId = !empty(values.class_id) ? values.class_id : "";

      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.updateClassTeacher(
        classId,
        schoolId,
        classTeacherId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        if (
          !empty(response_data) &&
          !empty(response_data.statusCodeType) &&
          response_data.statusCodeType === "unauthorized"
        ) {
          return navigate("/");
        }
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to added class teacher!"
        );
      }

      responseDailog("success", "Success", "Class Teacher added successfully.");
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader title="Update Class Teachers" />
            <div className="form-container mt-30">
              <div
                style={{
                  fontSize: 22,
                  color: "#633ccd",
                  fontWeight: "bold",
                  marginBottom: 20,
                }}
              >
                Class Teachers
              </div>
              {classData.map((data, key) => {
                const classId =
                  !empty(data) && !empty(data._id) ? data._id : "";
                const title =
                  !empty(data) && !empty(data.title) ? data.title : "";
                const classTearcherId =
                  !empty(data) && !empty(data.classTeacherId)
                    ? data.classTeacherId
                    : "";

                return (
                  <Formik
                    key={key}
                    initialValues={{
                      class_id: classId,
                      class_teacher_id: classTearcherId,
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ handleSubmit, values, handleChange }) => (
                      <Form style={{ width: "100%", marginBottom: 50 }}>
                        <div
                          style={{
                            fontSize: 16,
                            color: "#633ccd",
                          }}
                          className="flex space-between"
                        >
                          <span>{title}</span>
                        </div>
                        <div
                          style={{
                            border: "dotted 1px #633ccd",
                            marginBottom: 20,
                            marginTop: 10,
                            display: "flex",
                            flexDirection: "row",
                            columnGap: 12,
                            padding: "0 25px",
                          }}
                        >
                          <div
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <SelectField
                                labelTitle="Class Teacher"
                                placeholder="Select Class Teacher"
                                name="class_teacher_id"
                                options={staffData}
                                height={50}
                                valueKey="id"
                                display="nameTitle"
                                selectedOption={values.class_teacher_id}
                                handleChangeFunc={handleChange}
                              />
                              <InputField
                                placeholder=""
                                name="class_id"
                                height={10}
                                value={classId}
                                required={true}
                                labelTitle=""
                                isHidden={true}
                              />
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                marginBottom: 20,
                              }}
                            >
                              <ButtonIcon
                                height={45}
                                color="#ffffff"
                                backgroundColor="#633ccd"
                                width={100}
                                borderColor="#633ccd"
                                buttonText="Update"
                                type="submit"
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                );
              })}
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default UpdateClassTeachers;
