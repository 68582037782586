import client from "./Client";

const addStudent = (
  school_id,
  first_name,
  last_name,
  middle_name,
  gender,
  address,
  entry_class,
  previous_school,
  state_of_residence,
  lga_of_residence,
  state_of_origin,
  lga_of_origin,
  nationality,
  email,
  phone_number,
  religion,
  date_of_birth,
  active,
  token
) =>
  client.post(
    "/student/register",
    {
      school_id,
      first_name,
      last_name,
      middle_name,
      gender,
      address,
      state_of_residence,
      lga_of_residence,
      state_of_origin,
      lga_of_origin,
      nationality,
      email,
      phone_number,
      religion,
      date_of_birth,
      active,
      entry_class,
      previous_school,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getStudents = (
  schoolId,
  page = 0,
  rows = 50,
  search = "",
  type = "",
  token
) => {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (rows) {
    params.rows = rows;
  }
  if (search) {
    params.search = search;
  }
  if (type) {
    params.type = type;
  }
  return client.get(`/${schoolId}/students`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const toggleStudentVisibility = (student_id, active, school_id, token) =>
  client.post(
    `/student/visibility/toggle`,
    {
      id: student_id,
      school_id,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getStudentsInClass = (
  school_id,
  page,
  rows,
  search,
  class_id,
  type = "",
  token
) => {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (rows) {
    params.rows = rows;
  }
  if (search) {
    params.search = search;
  }
  if (type) {
    params.type = type;
  }

  return client.get(`/${school_id}/class/${class_id}/students`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const updateStudent = (
  student_id,
  school_id,
  first_name,
  last_name,
  middle_name,
  gender,
  address,
  entry_class,
  previous_school,
  state_of_residence,
  lga_of_residence,
  state_of_origin,
  lga_of_origin,
  nationality,
  email,
  phone_number,
  religion,
  date_of_birth,
  active,
  token
) =>
  client.post(
    "/student/update",
    {
      student_id,
      school_id,
      first_name,
      last_name,
      middle_name,
      gender,
      address,
      entry_class,
      previous_school,
      state_of_residence,
      lga_of_residence,
      state_of_origin,
      lga_of_origin,
      nationality,
      email,
      phone_number,
      religion,
      date_of_birth,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getSingleStudent = (studentId, schoolId, token) =>
  client.get(`/${schoolId}/student/${studentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getStudentProfile = (studentId, schoolId, token) =>
  client.get(`/${schoolId}/student/${studentId}/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getStudentPercentage = (studentId, schoolId, token) =>
  client.get(`/${schoolId}/stat/student/${studentId}/assessment/percentage`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const activateStudent = (student_ids, school_id, token) =>
  client.post(
    "/students/activate",
    {
      student_ids,
      school_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const promoteStudent = (
  student_ids,
  school_id,
  comment,
  class_id,
  current_class_id,
  token
) =>
  client.post(
    "/students/promote",
    {
      student_ids,
      school_id,
      comment,
      class_id,
      current_class_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

export default {
  addStudent,
  getStudents,
  toggleStudentVisibility,
  updateStudent,
  getSingleStudent,
  getStudentsInClass,
  getStudentProfile,
  getStudentPercentage,
  activateStudent,
  promoteStudent,
};
