import { useContext } from "react";
import {
  FaBars,
  FaRegBell,
  FaCaretDown,
  FaRegEnvelope,
  FaMoneyBill,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { API_BASE_URL } from "../../config/config";
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty, toCurrency } from "../../Utilities/utils";

// css
import "./Navbar.css";

// images
import appLogo from "../../assets/logo/blue-logo.png";
import colors from "../../config/colors";

const Navbar = ({ toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const name = !empty(user) && !empty(user.name) ? user.name : "NA";
  const creditBalance =
    !empty(user) && !empty(user.credits) ? user.credits : "NA";
  const schoolAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";
  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={toggleSidebar}>
        <FaBars className="menu_bar" />
      </div>
      <div className="navbar_left">
        <span style={{ fontWeight: "bold", fontSize: 20 }}>{name}</span>
      </div>
      <div className="navbar_right">
        <div className="credit-nav-box">
          <strong>Credit: &nbsp;</strong>{" "}
          <FaMoneyBill
            style={{ marginTop: 2 }}
            size={20}
            color={colors.primary}
          />
          &nbsp;&nbsp;<strong>{toCurrency(creditBalance)}</strong>
        </div>
        <NavLink to="/inbox" style={{ textDecoration: "none" }} className="">
          <div className="icon_box">
            <FaRegEnvelope />
          </div>
        </NavLink>
        <NavLink
          to="/notification"
          style={{ textDecoration: "none" }}
          className=""
        >
          <div className="icon_box">
            <FaRegBell />
            <div></div>
          </div>
        </NavLink>
        <div className="navbar_avatar_box ">
          <NavLink to="/profile" style={{ textDecoration: "none" }}>
            <img
              className="navbar_avatar_box_img"
              src={!empty(schoolAvatar) ? API_BASE_URL + schoolAvatar : appLogo}
              alt="avatar"
            />
          </NavLink>
          <span className="navbar_user_name">{name}</span>
          <span>
            <FaCaretDown />
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
