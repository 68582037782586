import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import staffApi from "../../api/Staff";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import SchoolStaffTable from "../../components/tables/primeTable/staff/SchoolStaffTable";
import TableLoading from "../../components/skeleton/TableLoading";

const SchoolStaff = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [staffData, setStaffData] = useState([]);
  const [staffToToggleDialog, setStaffToToggleDialog] = useState(false);
  const [selectedStaffDetail, setSelectedStaffDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  useEffect(() => {
    // fetch staff
    try {
      getStaff();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load staff. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { staffAdded: false, staffUpdated: false },
        });
      }
    }
  }, [user]);

  // function to get all staff
  const getStaff = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getStaff(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStaffData([]);
      } else {
        setStaffData(response_data.response);
      }

      // check if a new staff was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const staffAdded = !empty(paramState.staffAdded)
          ? paramState.staffAdded
          : false;
        const staffUpdated = !empty(paramState.staffUpdated)
          ? paramState.staffUpdated
          : false;
        if (staffAdded === true || staffUpdated === true) {
          const actionType = staffAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Staff ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { staffAdded: false, staffUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  //function to toggle staff visibility
  const toggleStaffVisiblity = async () => {
    try {
      if (staffToToggleDialog) setStaffToToggleDialog(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const active =
        !empty(selectedStaffDetail) && !empty(selectedStaffDetail.active)
          ? selectedStaffDetail.active
          : false;
      const staffId =
        !empty(selectedStaffDetail) && !empty(selectedStaffDetail._id)
          ? selectedStaffDetail._id
          : "";
      const response = await staffApi.toggleStaffVisibility(
        staffId,
        active,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to ${
                !empty(selectedStaffDetail) &&
                !empty(selectedStaffDetail.active) &&
                selectedStaffDetail.active === false
                  ? "enable"
                  : "disable"
              } staff with name, ${
                !empty(selectedStaffDetail.lastName) &&
                !empty(selectedStaffDetail.firstName)
                  ? `${selectedStaffDetail.lastName} ${selectedStaffDetail.firstName}`
                  : ""
              }`;
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Staff, ${
          !empty(selectedStaffDetail.lastName) &&
          !empty(selectedStaffDetail.firstName)
            ? `${selectedStaffDetail.lastName} ${selectedStaffDetail.firstName}`
            : ""
        }, was ${
          !empty(selectedStaffDetail) &&
          !empty(selectedStaffDetail.active) &&
          selectedStaffDetail.active === true
            ? "disabled"
            : "enabled"
        } successfully!`
      );
      return getStaff();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to show confirm modal
  const showDisableConfirm = (data) => {
    setSelectedStaffDetail(data);
    setStaffToToggleDialog(true);
  };

  //function to hide confirm modal
  const hideDisableStaffDialog = () => {
    setStaffToToggleDialog(false);
  };

  //go to update staff page
  const gotToUpdateStaff = (data) => {
    const staffId = !empty(data) && !empty(data._id) ? data._id : "";
    navigate(`/staff/${staffId}/update`);
  };

  const disableStaffDialogFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideDisableStaffDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={toggleStaffVisiblity}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              button
              buttonText="Add Staff"
              link="/staff/add"
              title="Staff"
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <SchoolStaffTable
                schoolStaff={staffData}
                confirmDisableSelected={showDisableConfirm}
                goToUpdateStaffScreen={gotToUpdateStaff}
              />
            ) : (
              <TableLoading rows={50} cols={8} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Dialog
        visible={staffToToggleDialog}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={disableStaffDialogFooter}
        onHide={hideDisableStaffDialog}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to{" "}
              {!empty(selectedStaffDetail) &&
              !empty(selectedStaffDetail.active) &&
              selectedStaffDetail.active ? (
                <span style={{ color: "red" }}>
                  <strong>disable</strong>
                </span>
              ) : (
                <span style={{ color: "green" }}>
                  <strong>enable</strong>
                </span>
              )}{" "}
              the selected staff -{" "}
              <strong>
                {!empty(selectedStaffDetail) &&
                !empty(selectedStaffDetail.title)
                  ? selectedStaffDetail.title
                  : ""}
              </strong>
              {selectedStaffDetail && (
                <b>
                  {!empty(selectedStaffDetail.name)
                    ? selectedStaffDetail.name
                    : ""}
                </b>
              )}
              ?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default SchoolStaff;
