import client from "./Client";

const toggleTermVisibility = (schoolId, termId, active, token) =>
  client.post(
    "/term/visibility/toggle",
    {
      school_id: schoolId,
      term_id: termId,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getTerms = (schoolId, token) =>
  client.get(`/terms/${schoolId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getSingleTerm = (schoolId, termId, token) =>
  client.get(`/${schoolId}/term/${termId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

export default {
  getTerms,
  getSingleTerm,
  toggleTermVisibility,
};
