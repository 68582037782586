import client from "./Client";

const addDiscipline = (school_id, title, token) =>
  client.post(
    "/discipline/add",
    {
      school_id,
      title,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const updateDiscipline = (discipline_id, school_id, title, token) =>
  client.post(
    "/discipline/update",
    {
      id: discipline_id,
      school_id,
      title,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getDisciplines = (schoolId, token) =>
  client.get(`/${schoolId}/disciplines`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const deleteDiscipline = (discipline_id, school_id, token) =>
  client.post(
    "/discipline/delete",
    {
      id: discipline_id,
      school_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getSingleDiscipline = (discipline_id, school_id, token) =>
  client.get(`/${school_id}/discipline/${discipline_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

export default {
  addDiscipline,
  updateDiscipline,
  getSingleDiscipline,
  deleteDiscipline,
  getDisciplines,
};
