import { token } from "./ReturnToken";
import { empty, prepareResponseData } from "../Utilities/utils";

//api
import generalApi from "./General";

export const getSchoolDetails = async () => {
  try {
    const { decodedToken, storedToken } = await token();
    if (!empty(storedToken) && !empty(decodedToken)) {
      const schoolId =
        !empty(decodedToken) && !empty(decodedToken._id)
          ? decodedToken._id
          : "";
      const sign = "yes";
      const response = await generalApi.getSchoolData(
        schoolId,
        storedToken,
        sign
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || empty(response_data.success)) {
        const statusCodeType = !empty(response_data.statusCodeType)
          ? response_data.statusCodeType
          : "";
        const error = { success: false, statusCodeType };
        return error;
      }

      const userDetails = !empty(response_data.response)
        ? response_data.response
        : {};
      if (!empty(userDetails.refreshedToken)) {
        localStorage.setItem("schoolToken", userDetails.refreshedToken);
      }

      return {
        success: true,
        response: userDetails,
        storedToken,
      };
    }

    return { success: false, response: "Something went wrong." };
  } catch (error) {
    console.log(error);
    return { success: false, response: "Something went wrong." };
  }
};
