import client from "./Client";

const addClass = (school_id, title, active, category, token) =>
  client.post(
    "/class/add",
    {
      school_id,
      title,
      active,
      category,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const addClassCategory = (school_id, title, token) =>
  client.post(
    "/class/category/add",
    {
      school_id,
      title,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getClasses = (schoolId, token) =>
  client.get(`/${schoolId}/classes`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getClassCategories = (schoolId, token) =>
  client.get(`/${schoolId}/categories/class`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const toggleClassVisibility = (class_id, active, school_id, token) =>
  client.post(
    `/class/visibility/toggle`,
    {
      school_id,
      id: class_id,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const updateClass = (class_id, school_id, title, active, category_id, token) =>
  client.post(
    "/class/update",
    {
      id: class_id,
      school_id,
      title,
      active,
      category_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const updateClassTeacher = (class_id, school_id, class_teacher_id, token) =>
  client.post(
    "/class/teacher/update",
    {
      id: class_id,
      school_id,
      class_teacher_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const updateClassCategory = (categoryId, school_id, title, token) =>
  client.post(
    "/class/category/update",
    {
      id: categoryId,
      school_id,
      title,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const deleteClassCategory = (category_id, school_id, token) =>
  client.post(
    "/class/category/delete",
    {
      id: category_id,
      school_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const deleteClass = (class_id, school_id, token) =>
  client.post(
    "/class/delete",
    {
      id: class_id,
      school_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const assignClassesToStaff = (class_ids, staff_id, school_id, token) =>
  client.post(
    "/staff/class/assign",
    {
      class_ids,
      id: staff_id,
      school_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const postionClass = (
  school_id,
  class_id,
  session_id,
  term_id,
  subjects_to_assess,
  token
) =>
  client.post(
    "/class/assessment/position",
    {
      class_id,
      session_id,
      term_id,
      subjects_to_assess,
      school_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getSingleClass = (class_id, school_id, token) =>
  client.get(`/${school_id}/class/${class_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getSingleCategory = (category_id, school_id, token) =>
  client.get(`/${school_id}/category/${category_id}/class`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getNumOfStudents = (class_id, school_id, token) =>
  client.get(`/${school_id}/stat/class/${class_id}/students`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getNumOfClassSubjects = (class_id, school_id, token) =>
  client.get(`/${school_id}/stat/class/${class_id}/subjects`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getAssessmentPercentage = (class_id, school_id, token) =>
  client.get(`/${school_id}/stat/class/${class_id}/assessment`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getStudentsInClassPerformance = (
  class_id,
  school_id,
  termId,
  sessionId,
  limit = null,
  token
) => {
  const params = {};

  if (termId) {
    params.termId = termId;
  }

  if (limit) {
    params.limit = limit;
  }

  if (sessionId) {
    params.sessionId = sessionId;
  }

  return client.get(`/${school_id}/class/${class_id}/performance/students`, {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const getNumberOfStudentsByGender = (
  class_id,
  school_id,
  termId,
  sessionId,
  token
) => {
  const params = {};

  if (termId) {
    params.termId = termId;
  }

  if (sessionId) {
    params.sessionId = sessionId;
  }

  return client.get(`/${school_id}/stat/class/${class_id}/gender/students`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const getSubjectsInClass = (class_id, school_id, limit, token) => {
  const params = {};
  if (limit) {
    params.limit = limit;
  }
  return client.get(`/${school_id}/class/${class_id}/subjects`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export default {
  getAssessmentPercentage,
  getNumOfClassSubjects,
  getNumOfStudents,
  addClass,
  getClasses,
  toggleClassVisibility,
  updateClass,
  getSingleClass,
  deleteClass,
  getStudentsInClassPerformance,
  getNumberOfStudentsByGender,
  getSubjectsInClass,
  assignClassesToStaff,
  postionClass,
  addClassCategory,
  updateClassCategory,
  deleteClassCategory,
  getClassCategories,
  getSingleCategory,
  updateClassTeacher,
};
