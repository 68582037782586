import { useContext, useRef, useState, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { v4 as uuidv4 } from "uuid";

// css
import "../students/Students.css";

// api
import classApi from "../../api/Classes";
import schoolApi from "../../api/School";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { AuthContext } from "../Root/ProtectedRoute";
import { FaMinus, FaPlus } from "react-icons/fa";
import { empty, prepareResponseData, reIndex } from "../../Utilities/utils";
import InputFieldChange from "../../components/form/InputFieldChange";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const required = "This field is required!";

const validationSchemaAcademicDates = Yup.object().shape({
  closing_date: Yup.string().required(required),
  opening_date: Yup.string().required(required),
});

const AcademicSettings = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const _closingDate =
    !empty(user) && !empty(user.closingDate) ? user.closingDate : "";
  const _openingDate =
    !empty(user) && !empty(user.openingDate) ? user.openingDate : "";
  const initialValuesAcademicDates = {
    closing_date: _closingDate,
    opening_date: _openingDate,
  };
  let scoreGrades =
    !empty(user) && !empty(user.scoreGrades) ? user.scoreGrades : [];
  let [reIndexedScoreGrades, setReIndexedScoreGrades] = useState(
    !empty(scoreGrades) ? reIndex(scoreGrades, "categoryId") : {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const toastTR = useRef(null);
  const [isRemovalModalVisible, setIsRemovalModalVisible] = useState(false);
  const [activeGrade, setActiveGrade] = useState({ id: "", categoryId: "" });
  const [classCategories, setClassCategories] = useState([]);

  useEffect(() => {
    getClassCategories();
  }, []);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getClassCategories = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClassCategories(schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setClassCategories([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setClassCategories(data);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitAcademicDates = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        responseDailog("error", "Error Alert", "Something went wrong.");
      }
      const closingDate = !empty(values.closing_date)
        ? values.closing_date
        : "";
      const openingDate = !empty(values.opening_date)
        ? values.opening_date
        : "";
      await updateSchoolSettings("Academic dates", closingDate, openingDate);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateSchoolSettings = async (
    type = "",
    closingDate = "",
    openingDate = "",
    remarks = []
  ) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.updateSchoolSettings(
        closingDate,
        openingDate,
        remarks,
        schoolId,
        type,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      } else {
        return responseDailog(
          "success",
          "Success",
          `${type} updated successfully.`
        );
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    }
  };

  const handleRemarkSubmit = async () => {
    try {
      setIsLoading(true);
      await updateSchoolSettings("Remarks", "", "", reIndexedScoreGrades);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const addInputGroup = (categoryId) => {
    setReIndexedScoreGrades((prevState) => {
      // Make a shallow copy of the previous state
      const newState = { ...prevState };

      // Check if the category exists in the state
      if (!newState[categoryId]) {
        newState[categoryId] = { categoryId, remarks: [] };
      }

      // Add a new remark group with a unique ID
      let newRemarks = [
        {
          id: uuidv4(),
          grade: "",
          remark: "",
          min: "",
          max: "",
          comment: "",
        },
        ...newState[categoryId].remarks,
      ];

      // Update the state with the new remarks
      newState[categoryId].remarks = newRemarks;

      return newState;
    });
  };

  const promptRemoval = () => {
    const id =
      !empty(activeGrade) && !empty(activeGrade.id) ? activeGrade.id : "";
    const categoryId =
      !empty(activeGrade) && !empty(activeGrade.categoryId)
        ? activeGrade.categoryId
        : "";
    setReIndexedScoreGrades((prevState) => {
      // Make a shallow copy of the previous state
      const newState = { ...prevState };

      // Check if the category exists in the state
      if (!newState[categoryId] || empty(newState[categoryId].remarks)) {
        return newState;
      }

      // remove a  remark group with a unique ID
      const newRemarks = newState[categoryId].remarks.filter(
        (group) => group.id !== id
      );

      // Update the state with the new remarks
      newState[categoryId].remarks = newRemarks;

      return newState;
    });
    setIsRemovalModalVisible(false);
  };

  const promptRemovalModal = (id, categoryId) => {
    setIsRemovalModalVisible(true);
    setActiveGrade({ id, categoryId });
  };

  const handleInputChange = (id, field, value, categoryId) => {
    setReIndexedScoreGrades((prevState) => {
      // Make a shallow copy of the previous state
      const newState = { ...prevState };

      // Check if the category exists in the state
      if (!newState[categoryId] || empty(newState[categoryId].remarks)) {
        return newState;
      }

      // Update the specific remark group with the new value
      let newRemarks = newState[categoryId].remarks.map((group) =>
        group.id === id ? { ...group, [field]: value } : group
      );

      // Update the state with the new remarks
      newState[categoryId].remarks = newRemarks;

      return newState;
    });
  };

  const removalModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={() => setIsRemovalModalVisible(false)}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={promptRemoval}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader title="Academic Settings" />
            <div className="form-container mt-30">
              <Formik
                enableReinitialize
                initialValues={initialValuesAcademicDates}
                validationSchema={validationSchemaAcademicDates}
                onSubmit={handleSubmitAcademicDates}
              >
                {({ values, handleChange }) => (
                  <Form style={{ width: "100%" }}>
                    <div
                      style={{ marginTop: 30, fontSize: 18, color: "#633ccd" }}
                    >
                      Academic Dates
                    </div>
                    <div
                      style={{ width: "100%", display: "flex", columnGap: 12 }}
                    >
                      <InputFieldChange
                        placeholder="Enter Closing Date"
                        name="closing_date"
                        type="date"
                        height={50}
                        required={true}
                        labelTitle="Closing Date"
                        value={values.closing_date}
                        onChange={handleChange}
                      />
                      <InputFieldChange
                        placeholder="Enter Opening Date"
                        name="opening_date"
                        type="date"
                        required={true}
                        height={50}
                        labelTitle="Opening Date"
                        value={values.opening_date}
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <ButtonIcon
                        height={45}
                        marginTop={2}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={100}
                        borderColor="#633ccd"
                        buttonText="Update"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
              <div
                style={{
                  marginTop: 50,
                  fontSize: 22,
                  color: "#633ccd",
                  fontWeight: "bold",
                }}
              >
                Grading Remarks
              </div>
              <p className="mt-5 mb-30">
                <em>
                  <strong>Note:</strong> If you do not intend to set grading for
                  other sections, the general section can suffice.
                </em>
              </p>
              {classCategories.map((data, key) => {
                const categoryId =
                  !empty(data) && !empty(data._id) ? data._id : "";
                const title =
                  !empty(data) && !empty(data.title) ? data.title : "";

                return (
                  <Formik
                    key={key}
                    initialValues={{}}
                    onSubmit={handleRemarkSubmit}
                  >
                    <Form style={{ width: "100%", marginBottom: 50 }}>
                      <div
                        style={{
                          fontSize: 16,
                          color: "#633ccd",
                        }}
                        className="flex space-between"
                      >
                        <span>{title}</span>
                      </div>
                      <p className="mt-10">
                        <em>
                          <strong>Note:</strong> Click the button below to add
                          grades and the corresponding remarks to them. (This is
                          for the secondary school section)
                        </em>
                      </p>
                      <ButtonIcon
                        height={30}
                        marginTop={2}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={20}
                        borderColor="#633ccd"
                        icon={<FaPlus />}
                        onClick={() => addInputGroup(categoryId)}
                      />
                      {!empty(reIndexedScoreGrades[categoryId]?.remarks) && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <ButtonIcon
                            height={45}
                            marginTop={2}
                            color="#ffffff"
                            backgroundColor="#633ccd"
                            width={100}
                            borderColor="#633ccd"
                            buttonText="Update"
                            type="submit"
                          />
                        </div>
                      )}
                      {reIndexedScoreGrades[categoryId]?.remarks?.map(
                        (group, index) => (
                          <div
                            key={group.id}
                            style={{
                              border: "dotted 1px #633ccd",
                              marginBottom: 20,
                              marginTop: 10,
                              display: "flex",
                              flexDirection: "row",
                              columnGap: 12,
                              padding: "0 25px",
                            }}
                          >
                            <div
                              style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "95%",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  columnGap: 12,
                                  alignItems: "center",
                                  marginBottom: 2,
                                }}
                              >
                                <InputFieldChange
                                  placeholder="E.g A, B, C etc"
                                  name={`grade-${index}`}
                                  type="text"
                                  height={50}
                                  required={true}
                                  labelTitle="Grade"
                                  value={!empty(group.grade) ? group.grade : ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      group.id,
                                      "grade",
                                      e.target.value,
                                      categoryId
                                    )
                                  }
                                />
                                <InputFieldChange
                                  placeholder="E.g Excellent, Poor etc"
                                  name={`remark-${index}`}
                                  type="text"
                                  height={50}
                                  required={true}
                                  value={
                                    !empty(group.remark) ? group.remark : ""
                                  }
                                  labelTitle="Remark"
                                  onChange={(e) =>
                                    handleInputChange(
                                      group.id,
                                      "remark",
                                      e.target.value,
                                      categoryId
                                    )
                                  }
                                />
                                <InputFieldChange
                                  placeholder="E.g 40, 65 etc"
                                  name={`min-${index}`}
                                  height={50}
                                  value={!empty(group.min) ? group.min : ""}
                                  required={true}
                                  labelTitle="Lower Score Limit"
                                  onChange={(e) =>
                                    handleInputChange(
                                      group.id,
                                      "min",
                                      e.target.value,
                                      categoryId
                                    )
                                  }
                                />
                                <InputFieldChange
                                  placeholder="E.g 59, 44 etc"
                                  name={`max-${index}`}
                                  height={50}
                                  value={!empty(group.max) ? group.max : ""}
                                  required={true}
                                  labelTitle="Upper Score Limit"
                                  onChange={(e) =>
                                    handleInputChange(
                                      group.id,
                                      "max",
                                      e.target.value,
                                      categoryId
                                    )
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  columnGap: 12,
                                  alignItems: "center",
                                  marginBottom: 12,
                                }}
                              >
                                <InputFieldChange
                                  placeholder="E.g Good result, keep it up etc"
                                  name={`comment-${index}`}
                                  height={100}
                                  as="textarea"
                                  row={9}
                                  cols={8}
                                  required={true}
                                  value={
                                    !empty(group.comment) ? group.comment : ""
                                  }
                                  labelTitle="Comment"
                                  onChange={(e) =>
                                    handleInputChange(
                                      group.id,
                                      "comment",
                                      e.target.value,
                                      categoryId
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <ButtonIcon
                              height={210}
                              marginTop={7}
                              color="#ffffff"
                              backgroundColor="#e65061"
                              width="5%"
                              borderColor="#e65061"
                              icon={<FaMinus />}
                              onClick={() =>
                                promptRemovalModal(group.id, categoryId)
                              }
                            />
                          </div>
                        )
                      )}
                    </Form>
                  </Formik>
                );
              })}
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />

        <Dialog
          visible={isRemovalModalVisible}
          style={{ width: "32rem", zIndex: 999999 }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm Action"
          modal
          footer={removalModalFooter}
          onHide={() => setIsRemovalModalVisible(false)}
        >
          <div
            className="confirmation-content"
            style={{ marginTop: 20, display: "flex", alignItems: "center" }}
          >
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
            />
            {<span>Are you sure you want to remove this Grade?</span>}
          </div>
        </Dialog>
      </AppWrapper>
    </>
  );
};

export default AcademicSettings;
