import axios from "axios";
import jwtDecode from "jwt-decode";
import { empty } from "../Utilities/utils";
export const token = () => {
  try {
    const storedToken = localStorage.getItem("schoolToken");
    if (!empty(storedToken)) {
      const decodedToken = jwtDecode(storedToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${storedToken}`;
      return { decodedToken, storedToken };
    }
    return { decodedToken: {}, storedToken: "" };
  } catch (error) {
    return false;
  }
};
