import "./Sidebar.css";
import {
  FaTimes,
  FaPowerOff,
  FaSchool,
  FaUserGraduate,
  FaUsers,
  FaUserTie,
  FaTh,
  FaBook,
  FaLayerGroup,
  FaListAlt,
  FaCheckSquare,
  FaBookOpen,
  FaCog,
  FaSwatchbook,
  FaObjectGroup,
  FaBox,
  FaChalkboard,
  FaMoneyBillAlt,
  FaBookmark,
  FaEnvelope,
  FaBell,
  FaUserCheck,
  FaHouseUser,
  FaBookReader,
  FaClock,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { API_BASE_URL } from "../../config/config";

// hooks
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty } from "../../Utilities/utils";

// images
import appLogo from "../../assets/logo/blue-logo.png";

const Sidebar = ({ sidebarOpen, toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const schoolAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";
  const reverseSidebar = () => {
    toggleSidebar();
  };

  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebar_title mt-20">
        <div className="flex flex-center" style={{ width: "100%" }}>
          <img
            style={{ width: 60, height: "auto" }}
            src={!empty(schoolAvatar) ? API_BASE_URL + schoolAvatar : appLogo}
            alt="logo"
          />
        </div>
        <FaTimes id="sidebar_close_icon" onClick={toggleSidebar} />
      </div>

      <div className="sidebar_menu">
        {/* sidebar link */}
        <div className="separator">Home</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/dashboard"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaTh className="sidebar_link_icon" />
              <span className="sidebar_link_text">Dashboard</span>
            </div>
          )}
        </NavLink>

        <div className="separator">Users</div>
        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/students"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserGraduate className="sidebar_link_icon" />
              <div className="sidebar_link_text">Students</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/staff"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserTie className="sidebar_link_icon" />
              <div className="sidebar_link_text">Staff</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        {/* <NavLink
          onClick={() => reverseSidebar()}
          to="/parent"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUsers className="sidebar_link_icon" />
              <div className="sidebar_link_text">Parent</div>
            </div>
          )}
        </NavLink> */}

        <div className="separator">Academics</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/attendance/dashboard"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link " : "sidebar_link"}>
              <FaUserCheck className="sidebar_link_icon" />
              <div className="sidebar_link_text">Attendance</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/classes"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaHouseUser className="sidebar_link_icon" />
              <div className="sidebar_link_text">Classes</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/class/categories"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBox className="sidebar_link_icon" />
              <div className="sidebar_link_text">Class Categories</div>
            </div>
          )}
        </NavLink>

        <NavLink
          onClick={() => reverseSidebar()}
          to="/subjects"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBookOpen className="sidebar_link_icon" />
              <div className="sidebar_link_text">Subjects</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          // to="/disciplines"
          to=""
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link " : "sidebar_link"}>
              <FaClock className="sidebar_link_icon" />
              <div className="sidebar_link_text">Time Table </div>
              <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/disciplines"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link " : "sidebar_link"}>
              <FaBookReader className="sidebar_link_icon" />
              <div className="sidebar_link_text">Disciplines</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/sessions"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaLayerGroup className="sidebar_link_icon" />
              <div className="sidebar_link_text">Sessions</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/terms"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaListAlt className="sidebar_link_icon" />
              <div className="sidebar_link_text">Terms</div>
            </div>
          )}
        </NavLink>

        <div className="separator">Assessment</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/set"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBook className="sidebar_link_icon" />
              <div className="sidebar_link_text">Assessment</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/position"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaSwatchbook className="sidebar_link_icon" />
              <div className="sidebar_link_text">Position</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/result-checker"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaCheckSquare className="sidebar_link_icon" />
              <div className="sidebar_link_text">Result Checker</div>
            </div>
          )}
        </NavLink>

        <div className="separator">Misc</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link " : "sidebar_link"}>
              <FaChalkboard className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Blackboard
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <FaMoneyBillAlt className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Finance
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <FaBookmark className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Activities
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>

        <div className="separator">Messages</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <FaEnvelope className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Chat
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <FaBell className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Notifications
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>

        <div className="separator">Settings</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaCog className="sidebar_link_icon" />
              <div className="sidebar_link_text">Settings</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        <div className="sidebar_logout mb-20">
          <FaPowerOff className="sidebar_logout_icon" />
          <NavLink to="/logout" className="sidebar_logout_text">
            Logout
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
