import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import colors from "../../../config/colors";

Chart.register(...registerables);

const DoughnutChart = ({
  doughnutData,
  label1 = "Male",
  label2 = "Female",
}) => {
  const data = {
    labels: [
      `${label1}(` + doughnutData[0] + ")",
      `${label2}(` + doughnutData[1] + ")",
    ],
    datasets: [
      {
        data: doughnutData,
        backgroundColor: [colors.primary, colors.orange],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Doughnut
      data={data}
      options={{
        plugins: {
          legend: {
            display: true,
          },
        },
      }}
    />
  );
};

export default DoughnutChart;
