import { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { token } from "../../api/ReturnToken";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import disciplineApi from "../../api/Disciplines";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import FullPageLoader from "../../components/loader/FullPageLoader";
import InputField from "../../components/form/InputField";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  title: Yup.string().required(required),
});

const AddUpdateDiscipline = ({ ...props }) => {
  const [initialValues, setInitialValues] = useState({
    title: "",
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { disciplineId } = useParams();
  const toastTR = useRef(null);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    if (disciplineId) {
      getDisciplineDetails();
    }
  }, [disciplineId]);

  const getDisciplineDetails = async () => {
    setIsLoading(true);
    try {
      const { decodedToken, storedToken } = await token();
      const schoolId =
        !empty(decodedToken) && !empty(decodedToken._id)
          ? decodedToken._id
          : "";
      const response = await disciplineApi.getSingleDiscipline(
        disciplineId,
        schoolId,
        storedToken
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Failed to get class details."
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        const data = response_data.response;
        setInitialValues({
          title: !empty(data.title) ? data.title : "",
        });
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }
      const { decodedToken, storedToken } = await token();
      const schoolId =
        !empty(decodedToken) && !empty(decodedToken._id)
          ? decodedToken._id
          : "";
      const title = !empty(values) && !empty(values.title) ? values.title : "";
      let response;
      let disciplineAdded = false;
      let disciplineUpdated = false;
      if (!disciplineId) {
        response = await disciplineApi.addDiscipline(
          schoolId,
          title,
          storedToken
        );
        disciplineAdded = true;
      } else {
        response = await disciplineApi.updateDiscipline(
          disciplineId,
          schoolId,
          title,
          storedToken
        );
        disciplineUpdated = true;
      }
      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        if (
          !empty(response_data) &&
          !empty(response_data.statusCodeType) &&
          response_data.statusCodeType === "unauthorized"
        ) {
          return navigate("/");
        }
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }

      navigate("/disciplines", {
        state: { disciplineAdded, disciplineUpdated },
      });
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader
              title={
                !empty(disciplineId) ? "Update Discipline" : "Add Discipline"
              }
            />
            <div className="form-container mt-30">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form style={{ width: "100%" }}>
                  <div
                    style={{ width: "100%", display: "flex", columnGap: 12 }}
                  >
                    <InputField
                      placeholder="Enter Discipline Title E.g Art, Science etc"
                      name="title"
                      height={50}
                      labelTitle={
                        <>
                          Title <span className="required">*</span>
                        </>
                      }
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ButtonIcon
                      height={45}
                      marginTop={5}
                      color="#ffffff"
                      backgroundColor="#633ccd"
                      width={300}
                      borderColor="#633ccd"
                      buttonText={
                        disciplineId ? "Update Discipline" : "Add Discipline"
                      }
                      type="submit"
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default AddUpdateDiscipline;
