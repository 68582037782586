import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "../staff/Staff.css";

// api
import studentApi from "../../api/Student";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import StatCard from "../../components/statcard/StatCard";
import {
  FaBookReader,
  FaChartArea,
  FaChartLine,
  FaEdit,
  FaStar,
  FaUserGraduate,
} from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import Underline from "../../components/others/Underline";
import { colors } from "../../Utilities/colors";
import BoxLoading from "../../components/skeleton/BoxLoading";
import ItemLoading from "../../components/skeleton/ItemLoading";
import TableLoading from "../../components/skeleton/TableLoading";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const StudentsProfile = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const [studentData, setStudentData] = useState({});
  const [classTitle, setClassTitle] = useState("");
  const [numOfSubjects, setNumOfSubjects] = useState(0);
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [assessmentPercentage, setAssessmentPercentage] = useState(0);
  const [percentageLoading, setPercentageLoading] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const { studentId } = useParams();
  const toastTR = useRef(null);

  if (!studentId) {
    navigate("/404");
  }

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    getStudentPercentage();
    getStudentDetails();

    if (!empty(location) && !empty(location.state)) {
      navigate(null, {
        replace: true,
        state: { studentAdded: false, studentUpdated: false },
      });
    }
  }, [studentId]);

  const getStudentDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.getStudentProfile(
        studentId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      const studentDetails =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const title =
        !empty(response_data) && !empty(studentDetails.classTitle)
          ? studentDetails.classTitle
          : [];
      const subjects =
        !empty(studentDetails) && !empty(studentDetails.assignedSubjects)
          ? studentDetails.assignedSubjects
          : [];
      setAssignedSubjects(subjects);
      setClassTitle(title);
      setNumOfSubjects(subjects.length);
      setStudentData(studentDetails);

      // check if a new student was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const studentAdded = !empty(paramState.studentAdded)
          ? paramState.studentAdded
          : false;
        const studentUpdated = !empty(paramState.studentUpdated)
          ? paramState.studentUpdated
          : false;
        if (studentAdded === true || studentUpdated === true) {
          const actionType = studentAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Student ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { studentAdded: false, studentUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getStudentPercentage = async () => {
    setPercentageLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.getStudentPercentage(
        studentId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      setAssessmentPercentage(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setPercentageLoading(false);
    }
  };

  const firstName =
    !empty(studentData) && !empty(studentData.firstName)
      ? studentData.firstName
      : "";
  const lastName =
    !empty(studentData) && !empty(studentData.lastName)
      ? studentData.lastName
      : "";
  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);
  const middleName =
    !empty(studentData) && !empty(studentData.middleName)
      ? studentData.middleName
      : "";
  const gender =
    !empty(studentData) && !empty(studentData.gender) ? studentData.gender : "";
  const regNo =
    !empty(studentData) && !empty(studentData.regNo) ? studentData.regNo : "";
  const address =
    !empty(studentData) && !empty(studentData.address)
      ? studentData.address
      : "";
  const stateOfResidence =
    !empty(studentData) && !empty(studentData.stateOfResidence)
      ? studentData.stateOfResidence
      : "";
  const lgaOfResidence =
    !empty(studentData) && !empty(studentData.lgaOfResidence)
      ? studentData.lgaOfResidence
      : "";
  const stateOfOrigin =
    !empty(studentData) && !empty(studentData.stateOfOrigin)
      ? studentData.stateOfOrigin
      : "";
  const lgaOfOrigin =
    !empty(studentData) && !empty(studentData.lgaOfOrigin)
      ? studentData.lgaOfOrigin
      : "";
  const nationality =
    !empty(studentData) && !empty(studentData.nationality)
      ? studentData.nationality
      : "";
  const religion =
    !empty(studentData) && !empty(studentData.religion)
      ? studentData.religion
      : "";
  const dateOfBirth =
    !empty(studentData) && !empty(studentData.dateOfBirth)
      ? studentData.dateOfBirth
      : "";
  const active =
    !empty(studentData) && !empty(studentData.active) ? studentData.active : "";
  const phoneNumber =
    !empty(studentData) && !empty(studentData.phoneNumber)
      ? studentData.phoneNumber
      : "";
  const email =
    !empty(studentData) && !empty(studentData.email) ? studentData.email : "";
  const previousSchool =
    !empty(studentData) && !empty(studentData.previous_school)
      ? studentData.previous_school
      : "";

  //function to show delete modal
  const showDeleteModal = () => {
    setModalVisible(true);
  };

  //function to hide confirm modal
  const hideDeleteModal = () => {
    setModalVisible(false);
  };

  const deleteStudent = () => {};

  const modalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideDeleteModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={deleteStudent}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Student Profile" />
          {/* end menu header */}
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor="#f27c1b"
                        title="Class Title"
                        entry={classTitle}
                        icon={<FaUserGraduate className="stat_card_icon" />}
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor="#633ccd"
                        title="Subjects"
                        entry={numOfSubjects}
                        icon={<FaBookReader className="stat_card_icon" />}
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !percentageLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor="#e65061"
                        title="Assessment"
                        entry={assessmentPercentage + "%"}
                        icon={<FaChartLine className="stat_card_icon" />}
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#98c38b"
                      title="Attendance"
                      entry="N/A"
                      icon={<FaChartArea className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Student Bio-Data"
                        rightIcon={
                          <NavLink to={`/student/${studentId}/update`}>
                            <FaEdit
                              style={{ color: "#4338CA", cursor: "pointer" }}
                            />
                          </NavLink>
                        }
                      />
                      <div className="dashboard_school_list">
                        <div className="avatar-container">
                          <div className="avatar-box">
                            <Avatar
                              label={lastNameInitial + firstNameInitial}
                              shape="circle"
                              style={{ width: 150, height: 150, fontSize: 70 }}
                              className="p-overlay-badge"
                            >
                              <Badge
                                style={{
                                  position: "absolute",
                                  top: 22,
                                  right: 22,
                                  width: 15,
                                  height: 15,
                                  backgroundColor: active
                                    ? colors.success
                                    : colors.danger,
                                }}
                                className="avatar-badge"
                              />
                            </Avatar>
                          </div>
                          <div className="information-container">
                            <div
                              style={{
                                marginTop: 50,
                                fontSize: 18,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              Personal Information
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Registration Number:{" "}
                              </strong>
                              <span>
                                {!isLoading ? regNo : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">First Name: </strong>
                              <span>
                                {!isLoading ? firstName : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Last Name: </strong>
                              <span>
                                {!isLoading ? lastName : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Middle Name: </strong>
                              <span>
                                {!isLoading ? middleName : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Gender: </strong>
                              <span>
                                {!isLoading ? gender : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Address: </strong>
                              <span>
                                {!isLoading ? address : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                State of Residence:{" "}
                              </strong>
                              <span>
                                {!isLoading ? (
                                  stateOfResidence
                                ) : (
                                  <ItemLoading />
                                )}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                L.G.A of Residence:{" "}
                              </strong>
                              <span>
                                {!isLoading ? lgaOfResidence : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                State of Origin:{" "}
                              </strong>
                              <span>
                                {!isLoading ? stateOfOrigin : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                L.G.A of Origin:{" "}
                              </strong>
                              <span>
                                {!isLoading ? lgaOfOrigin : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Nationality: </strong>
                              <span>
                                {!isLoading ? nationality : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Religion: </strong>
                              <span>
                                {!isLoading ? religion : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Date of Birth: </strong>
                              <span>
                                {!isLoading ? dateOfBirth : <ItemLoading />}
                              </span>
                            </p>
                            <div
                              style={{
                                marginTop: 30,
                                fontSize: 18,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              Contact Information
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Phone Number 1:{" "}
                              </strong>
                              <span>
                                {!isLoading ? phoneNumber : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Email: </strong>
                              <span>
                                {!isLoading ? email : <ItemLoading />}
                              </span>
                            </p>
                            <div
                              style={{
                                marginTop: 30,
                                fontSize: 18,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              Academic Information
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Previous School:{" "}
                              </strong>
                              <span>
                                {!isLoading ? previousSchool : <ItemLoading />}
                              </span>
                            </p>
                          </div>
                          <div>
                            <div
                              style={{
                                marginTop: 30,
                                fontSize: 18,
                                marginBottom: 15,
                                color: colors.danger,
                              }}
                            >
                              <strong>Critical Area</strong>
                            </div>
                            <div>
                              <div
                                style={{
                                  marginTop: 30,
                                  fontSize: 18,
                                  marginBottom: 15,
                                  color: colors.primary,
                                }}
                              >
                                Promote Student
                                <Underline />
                              </div>
                              <span>
                                <strong>Note: </strong>This action cannot be
                                undone. Ensure that you intend to delete this
                                student before continuing.
                              </span>
                              <div className="mt-10">
                                <ButtonIcon
                                  borderColor={colors.primary}
                                  backgroundColor={colors.primary}
                                  color={colors.white}
                                  buttonText="Promote Student"
                                  onClick={showDeleteModal}
                                />
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  marginTop: 30,
                                  fontSize: 18,
                                  marginBottom: 15,
                                  color: colors.danger,
                                }}
                              >
                                Delete Student
                              </div>
                              <span>
                                <strong>Note: </strong>This action cannot be
                                undone. Ensure that you intend to delete this
                                student before continuing.
                              </span>
                              <div className="mt-10">
                                <ButtonIcon
                                  borderColor={colors.danger}
                                  backgroundColor={colors.danger}
                                  color="#ffffff"
                                  buttonText="Delete Student"
                                  onClick={showDeleteModal}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  addStyle="student_list_box"
                />
              </div>
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              <div className="main_right_boxes">
                <Card
                  children={
                    <>
                      <MainHeader title="Class Subjects" />
                      <div className="mt-10">
                        {!isLoading ? (
                          assignedSubjects.map((item) => {
                            return (
                              <p
                                key={
                                  !empty(item) && !empty(item._id)
                                    ? item._id
                                    : 0
                                }
                                className="mb-10 flex space-between bio-data-item"
                              >
                                <span>
                                  <FaStar
                                    color="#DAA520"
                                    style={{ marginRight: 8, fontSize: 12 }}
                                  />
                                  {!empty(item) && !empty(item.title)
                                    ? item.title
                                    : ""}
                                </span>
                              </p>
                            );
                          })
                        ) : (
                          <TableLoading rows={5} cols={1} header={false} />
                        )}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
              </div>
            </div>
            {/* end of right box */}
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>

      <Dialog
        visible={modalVisible}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={modalFooter}
        onHide={hideDeleteModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          <p>
            Are you sure you want to delete the student?
            <br />
            <em>
              <strong style={{ color: colors.danger }}>
                PS: This operation can not be undone.
              </strong>
            </em>
          </p>
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default StudentsProfile;
