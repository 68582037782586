import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData, reIndex } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "./Staff.css";

// api
import staffApi from "../../api/Staff";
import subjectApi from "../../api/Subject";
import classApi from "../../api/Classes";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import StatCard from "../../components/statcard/StatCard";
import {
  FaBookReader,
  FaChartArea,
  FaChartLine,
  FaCheck,
  FaEdit,
  FaRegPlusSquare,
  FaStar,
  FaTimes,
  FaUserGraduate,
} from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import Underline from "../../components/others/Underline";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ItemLoading from "../../components/skeleton/ItemLoading";
import BoxLoading from "../../components/skeleton/BoxLoading";
import TableLoading from "../../components/skeleton/TableLoading";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { colors } from "../../Utilities/colors";

const StaffProfile = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const [staffData, setStaffData] = useState({});
  const [numberOfClasses, setNumberOfClasses] = useState(0);
  const [numOfSubjects, setNumOfSubjects] = useState(0);
  const [isSubjectModal, setIsSubjectModal] = useState(false);
  const [selectedClassDetails, setSelectedClassDetails] = useState({});
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [authorizedSubjects, setAuthorizedSubjects] = useState([]);
  const [assessmentPercentage, setAssessmentPercentage] = useState(0);
  const [reIndexedSubjects, setReIndexedSubjects] = useState({});
  const [modalType, setModalType] = useState("");
  const [reIndexedClasses, setReIndexedClasses] = useState({});

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const { staffId } = useParams();
  const toastTR = useRef(null);

  if (!staffId) {
    navigate("/404");
  }

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    getStaffDetails();
    getSubjects();
    getClasses();
  }, [staffId]);

  const getStaffDetails = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getStaffProfile(staffId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      const staffDetails =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const classes =
        !empty(response_data) && !empty(staffDetails.assignedClasses)
          ? staffDetails.assignedClasses
          : [];
      const _authorizedSubjects =
        !empty(staffDetails) && !empty(staffDetails.authorizedSubjects)
          ? staffDetails.authorizedSubjects
          : [];

      const subjectIds = [];
      for (let i = 0; i < _authorizedSubjects.length; i++) {
        const ids =
          !empty(_authorizedSubjects[i]) &&
          !empty(_authorizedSubjects[i].subjectIds)
            ? _authorizedSubjects[i].subjectIds
            : "";
        subjectIds.push(...ids);
      }
      const subjectIdSet = new Set(subjectIds);

      setAuthorizedSubjects(_authorizedSubjects);
      setAssignedClasses(classes);
      setNumberOfClasses(classes.length);
      setNumOfSubjects(subjectIdSet.size);
      setStaffData(staffDetails);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjects = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSubjects(schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        setReIndexedSubjects({});
      } else {
        const results =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        const _reIndexedSubjects = !empty(results)
          ? reIndex(results, "_id")
          : {};
        setReIndexedSubjects(_reIndexedSubjects);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    }
  };

  const getClasses = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : [];
      const _reIndexedClasses = !empty(data) ? reIndex(data, "_id") : {};
      setReIndexedClasses(_reIndexedClasses);
    } catch (error) {}
  };

  const assignClassSubjectsToStaff = async () => {
    try {
      if (!actionLoading) setActionLoading(true);
      setIsSubjectModal(false);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const classId =
        !empty(selectedClassDetails) && !empty(selectedClassDetails._id)
          ? selectedClassDetails._id
          : "";
      const response = await staffApi.assignClassSubjectsToStaff(
        staffId,
        schoolId,
        classId,
        modalType,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }

      getStaffDetails();
      return responseDailog(
        "success",
        "Success",
        "Class(es) assigned successfully"
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setActionLoading(false);
    }
  };

  //function to show confirm modal
  const showSubjectModal = (data, type) => {
    setSelectedClassDetails(data);
    setModalType(type);
    setIsSubjectModal(true);
  };

  //function to hide confirm modal
  const hideSubjectModal = () => {
    setIsSubjectModal(false);
  };

  const subjectModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideSubjectModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={assignClassSubjectsToStaff}
      />
    </div>
  );

  const firstName =
    !empty(staffData) && !empty(staffData.firstName) ? staffData.firstName : "";
  const lastName =
    !empty(staffData) && !empty(staffData.lastName) ? staffData.lastName : "";
  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);
  const middleName =
    !empty(staffData) && !empty(staffData.middleName)
      ? staffData.middleName
      : "";
  const gender =
    !empty(staffData) && !empty(staffData.gender) ? staffData.gender : "";
  const title =
    !empty(staffData) && !empty(staffData.title) ? staffData.title : "";
  const address =
    !empty(staffData) && !empty(staffData.address) ? staffData.address : "";
  const stateOfResidence =
    !empty(staffData) && !empty(staffData.stateOfResidence)
      ? staffData.stateOfResidence
      : "";
  const lgaOfResidence =
    !empty(staffData) && !empty(staffData.lgaOfResidence)
      ? staffData.lgaOfResidence
      : "";
  const stateOfOrigin =
    !empty(staffData) && !empty(staffData.stateOfOrigin)
      ? staffData.stateOfOrigin
      : "";
  const lgaOfOrigin =
    !empty(staffData) && !empty(staffData.lgaOfOrigin)
      ? staffData.lgaOfOrigin
      : "";
  const nationality =
    !empty(staffData) && !empty(staffData.nationality)
      ? staffData.nationality
      : "";
  const religion =
    !empty(staffData) && !empty(staffData.religion) ? staffData.religion : "";
  const dateOfBirth =
    !empty(staffData) && !empty(staffData.dateOfBirth)
      ? staffData.dateOfBirth
      : "";
  const active =
    !empty(staffData) && !empty(staffData.active) ? staffData.active : "";
  const phoneNumber1 =
    !empty(staffData) && !empty(staffData.phoneNumber1)
      ? staffData.phoneNumber1
      : "";
  const phoneNumber2 =
    !empty(staffData) && !empty(staffData.phoneNumber2)
      ? staffData.phoneNumber2
      : "";
  const email =
    !empty(staffData) && !empty(staffData.email) ? staffData.email : "";
  const qualification =
    !empty(staffData) && !empty(staffData.qualification)
      ? staffData.qualification
      : "";
  const courseStudied =
    !empty(staffData) && !empty(staffData.courseStudied)
      ? staffData.courseStudied
      : "";

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Staff Profile" />
          {/* end menu header */}
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Classes"
                        entry={numberOfClasses}
                        icon={
                          <FaUserGraduate
                            size={12}
                            className="stat_card_icon"
                          />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Subjects"
                        entry={numOfSubjects}
                        icon={
                          <FaBookReader size={12} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor={colors.primary}
                      title="Assessment"
                      entry={assessmentPercentage + "%"}
                      icon={
                        <FaChartLine size={12} className="stat_card_icon" />
                      }
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor={colors.primary}
                      title="Attendance"
                      entry="N/A"
                      icon={
                        <FaChartArea size={12} className="stat_card_icon" />
                      }
                    />
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Staff Bio-Data"
                        rightIcon={
                          <NavLink to={`/staff/${staffId}/update`}>
                            <FaEdit
                              style={{ color: "#4338CA", cursor: "pointer" }}
                            />
                          </NavLink>
                        }
                      />
                      <div className="dashboard_school_list">
                        <div className="avatar-container">
                          <div className="avatar-box">
                            <Avatar
                              label={lastNameInitial + firstNameInitial}
                              shape="circle"
                              style={{ width: 150, height: 150, fontSize: 70 }}
                              className="p-overlay-badge"
                            >
                              <Badge
                                style={{
                                  position: "absolute",
                                  top: 22,
                                  right: 22,
                                  width: 15,
                                  height: 15,
                                  backgroundColor: "#e65061",
                                }}
                                className="avatar-badge"
                              />
                            </Avatar>
                          </div>
                          <div className="information-container">
                            <div
                              style={{
                                marginTop: 50,
                                fontSize: 18,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              Personal Information
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">First Name: </strong>
                              <span>
                                {!isLoading ? firstName : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Last Name: </strong>
                              <span>
                                {" "}
                                {!isLoading ? lastName : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Middle Name: </strong>
                              <span>
                                {!isLoading ? middleName : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Gender: </strong>
                              <span>
                                {!isLoading ? gender : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Title: </strong>
                              <span>
                                {!isLoading ? title : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Address: </strong>
                              <span>
                                {!isLoading ? address : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                State of Residence:{" "}
                              </strong>
                              <span>
                                {!isLoading ? (
                                  stateOfResidence
                                ) : (
                                  <ItemLoading />
                                )}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                L.G.A of Residence:{" "}
                              </strong>
                              <span>
                                {!isLoading ? lgaOfResidence : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                State of Origin:{" "}
                              </strong>
                              <span>
                                {!isLoading ? stateOfOrigin : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                L.G.A of Origin:{" "}
                              </strong>
                              <span>
                                {!isLoading ? lgaOfOrigin : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Nationality: </strong>
                              <span>
                                {!isLoading ? nationality : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Religion: </strong>
                              <span>
                                {!isLoading ? religion : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Date of Birth: </strong>
                              <span>
                                {!isLoading ? dateOfBirth : <ItemLoading />}
                              </span>
                            </p>
                            <div
                              style={{
                                marginTop: 30,
                                fontSize: 18,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              Contact Information
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Phone Number 1:{" "}
                              </strong>
                              <span>
                                {!isLoading ? phoneNumber1 : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Phone Number 2:{" "}
                              </strong>
                              <span>
                                {!isLoading ? phoneNumber2 : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Email: </strong>
                              <span>
                                {!isLoading ? email : <ItemLoading />}
                              </span>
                            </p>
                            <div
                              style={{
                                marginTop: 30,
                                fontSize: 18,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              Academic Information
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Qualification: </strong>
                              <span>
                                {!isLoading ? qualification : <ItemLoading />}
                              </span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Course Studied:{" "}
                              </strong>
                              <span>
                                {!isLoading ? courseStudied : <ItemLoading />}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  addStyle="student_list_box"
                />
              </div>
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              <div className="main_right_boxes">
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Assigned Subjects"
                        rightIcon={
                          <NavLink to={`/staff/${staffId}/subjects/assign`}>
                            <FaRegPlusSquare
                              style={{
                                color: colors.primary,
                                cursor: "pointer",
                              }}
                            />
                          </NavLink>
                        }
                      />
                      <div className="mt-10">
                        {!isLoading ? (
                          authorizedSubjects.map((data) => {
                            const _classId =
                              !empty(data) && !empty(data.classId)
                                ? data.classId
                                : "";
                            const _subjectIds =
                              !empty(data) && !empty(data.subjectIds)
                                ? data.subjectIds
                                : [];
                            const _classTitle =
                              reIndexedClasses?.[_classId]?.title || "";

                            const subjects = _subjectIds.map((item) => {
                              const title =
                                !empty(reIndexedSubjects) &&
                                !empty(reIndexedSubjects[item]) &&
                                !empty(reIndexedSubjects[item].title)
                                  ? reIndexedSubjects[item].title
                                  : "";
                              return title;
                            });

                            return (
                              <p
                                key={_classId}
                                className="mb-10 flex direction-column bio-data-item"
                              >
                                <span
                                  style={{
                                    color: "#633ccd",
                                  }}
                                >
                                  <FaStar
                                    style={{
                                      marginRight: 8,
                                      fontSize: 12,
                                    }}
                                    color="#DAA520"
                                  />
                                  {_classTitle}:
                                </span>
                                <p
                                  style={{
                                    cursor: "pointer",
                                    marginTop: 5,
                                  }}
                                >
                                  <ul className="ml-15">
                                    {subjects.map((data) => (
                                      <li className="mb-5">{data}</li>
                                    ))}
                                  </ul>
                                </p>
                              </p>
                            );
                          })
                        ) : (
                          <TableLoading rows={6} cols={1} header={false} />
                        )}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Assigned Class(es)"
                        rightIcon={
                          <NavLink to={`/staff/${staffId}/class/assign`}>
                            <FaRegPlusSquare
                              style={{
                                color: "#389d17",
                                cursor: "pointer",
                              }}
                            />
                          </NavLink>
                        }
                      />
                      <div className="mt-10">
                        {!isLoading ? (
                          assignedClasses.map((item) => {
                            return (
                              <p
                                key={
                                  !empty(item) && !empty(item._id)
                                    ? item._id
                                    : 0
                                }
                                className="mb-10 flex space-between bio-data-item"
                              >
                                <span>
                                  <FaStar
                                    style={{
                                      marginRight: 8,
                                      fontSize: 12,
                                    }}
                                    color="#DAA520"
                                  />
                                  {!empty(item) && !empty(item.title) ? (
                                    <NavLink
                                      style={{
                                        textDecoration: "none",
                                        color: "#633ccd",
                                      }}
                                      to={`/classes/${
                                        !empty(item) && !empty(item._id)
                                          ? item._id + "/profile"
                                          : ""
                                      }`}
                                    >
                                      {item.title}
                                    </NavLink>
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#633ccd",
                                  }}
                                >
                                  <NavLink>
                                    <FaCheck
                                      style={{
                                        cursor: "pointer",
                                        color: colors.success,
                                        marginRight: 8,
                                      }}
                                      onClick={() =>
                                        showSubjectModal(item, "assign")
                                      }
                                    />
                                  </NavLink>
                                  <NavLink>
                                    <FaTimes
                                      style={{
                                        cursor: "pointer",
                                        color: colors.danger,
                                      }}
                                      onClick={() =>
                                        showSubjectModal(item, "remove")
                                      }
                                    />
                                  </NavLink>
                                </span>
                              </p>
                            );
                          })
                        ) : (
                          <TableLoading rows={6} cols={1} header={false} />
                        )}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
              </div>
            </div>
            {/* end of right box */}
          </div>
        </main>
        {actionLoading && <FullPageLoader visible={actionLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>

      <Dialog
        visible={isSubjectModal}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={subjectModalFooter}
        onHide={hideSubjectModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to{" "}
              {modalType === "assign" ? (
                <span style={{ color: colors.success }}>{modalType}</span>
              ) : (
                <span style={{ color: colors.danger }}>{modalType}</span>
              )}{" "}
              the subjects in{" "}
              <strong>
                {!empty(selectedClassDetails) &&
                !empty(selectedClassDetails.title)
                  ? selectedClassDetails.title
                  : "NA"}
              </strong>{" "}
              {modalType === "assign" ? "to " : "from "} staff,{" "}
              {selectedClassDetails && (
                <b>
                  {lastName} {firstName} {middleName}
                </b>
              )}
              ?
            </span>
          }
        </div>
      </Dialog>
    </>
  );
};

export default StaffProfile;
