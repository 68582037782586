import "./Sidebar.css";
import {
  FaTimes,
  FaPowerOff,
  FaUserGraduate,
  FaUserTie,
  FaTh,
  FaBook,
  FaLayerGroup,
  FaListAlt,
  FaCheckSquare,
  FaBookOpen,
  FaCog,
  FaSwatchbook,
  FaBox,
  FaChalkboard,
  FaMoneyBillAlt,
  FaBookmark,
  FaEnvelope,
  FaBell,
  FaUserCheck,
  FaHouseUser,
  FaBookReader,
  FaClock,
  FaHome,
  FaUserTimes,
  FaHistory,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { API_BASE_URL } from "../../config/config";

// hooks
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty } from "../../Utilities/utils";

// images
import appLogo from "../../assets/logo/blue-logo.png";

const AttendanceSidebar = ({ sidebarOpen, toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const schoolAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";
  const reverseSidebar = () => {
    toggleSidebar();
  };

  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebar_title mt-20">
        <div className="flex flex-center" style={{ width: "100%" }}>
          <img
            style={{ width: 60, height: "auto" }}
            src={!empty(schoolAvatar) ? API_BASE_URL + schoolAvatar : appLogo}
            alt="logo"
          />
        </div>
        <FaTimes id="sidebar_close_icon" onClick={toggleSidebar} />
      </div>

      <div className="sidebar_menu">
        {/* sidebar link */}
        <div className="separator">Home</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/dashboard"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaTh className="sidebar_link_icon" />
              <span className="sidebar_link_text">Dashboard</span>
            </div>
          )}
        </NavLink>

        <div className="separator">Attendance</div>
        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/attendance/dashboard"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaHome className="sidebar_link_icon" />
              <div className="sidebar_link_text">Home</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/attendance/check-in"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserCheck className="sidebar_link_icon" />
              <div className="sidebar_link_text">Check-In</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/attendance/check-out"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link " : "sidebar_link"}>
              <FaUserTimes className="sidebar_link_icon" />
              <div className="sidebar_link_text">Check-Out</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/attendance/history"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaHistory className="sidebar_link_icon" />
              <div className="sidebar_link_text">History</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        <div className="sidebar_logout mb-20">
          <FaPowerOff className="sidebar_logout_icon" />
          <NavLink to="/logout" className="sidebar_logout_text">
            Logout
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AttendanceSidebar;
