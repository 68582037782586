import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import * as Yup from "yup";

// css
import "./Students.css";

// api
import studentApi from "../../api/Student";
import classApi from "../../api/Classes";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import StudentsTable from "../../components/tables/primeTable/students/StudentsTable";
import TableLoading from "../../components/skeleton/TableLoading";
import FullPageLoader from "../../components/loader/FullPageLoader";
import colors from "../../config/colors";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { Form, Formik } from "formik";
import SelectField from "../../components/form/SelectField";
import InputField from "../../components/form/InputField";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  classId: Yup.string().required(required),
  comment: Yup.string().required(required),
});

const initialValues = {
  classId: "",
  comment: "",
};

const Student = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const getValues = useParams();
  const classId =
    !empty(getValues) && !empty(getValues.classId) ? getValues.classId : "";
  const isPromotionList =
    !empty(getValues) && !empty(getValues.promotion) ? true : false;
  const promotion =
    !empty(getValues) && !empty(getValues.promotion) ? getValues.promotion : "";
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [studentData, setStudentData] = useState([]);
  const [studentToToggleDialog, setStudentToToggleDialog] = useState(false);
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [promotionModalOpen, setPromotionModalOpen] = useState(false);
  const [selectedStudentDetail, setSelectedStudentDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [search, setSearch] = useState("");
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [studentIds, setStudentIds] = useState([]);
  const [classData, setClassData] = useState([]);
  const [classTitle, setClassTitle] = useState({});

  // check column
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isPromotionList) {
      getClasses();
    }
  }, [isPromotionList]);

  useEffect(() => {
    // fetch student
    try {
      getStudents();
      getClassDetails();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load student. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { studentAdded: false, studentUpdated: false },
        });
      }
    }
  }, [first, rows, search, classId, user]);

  // function to get all classes
  const getClasses = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClassData([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setClassData(data);
      }
    } catch (error) {}
  };

  const getClassDetails = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSingleClass(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data) ||
        !response_data.response
      ) {
        return setClassTitle("");
      }
      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const title = !empty(data) && !empty(data.title) ? data.title : "";
      return setClassTitle(title);
    } catch (error) {}
  };

  // function to get all student
  const getStudents = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const page = first / rows;
      let response;
      if (!empty(classId)) {
        response = await studentApi.getStudentsInClass(
          schoolId,
          page,
          rows,
          search,
          classId,
          "list",
          token
        );
      } else {
        response = await studentApi.getStudents(
          schoolId,
          page,
          rows,
          search,
          "list",
          token
        );
      }
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudentData([]);
      } else {
        const results =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setStudentData(results);
        setTotalRecords(!empty(response_data.count) ? response_data.count : 0);
        const _studentIds = [];
        if (!isPromotionList) {
          for (let i = 0; i < results.length; i++) {
            const paid =
              !empty(results[i]) &&
              !empty(results[i].paid) &&
              results[i].paid === "Yes"
                ? true
                : false;
            const active =
              !empty(results[i]) &&
              !empty(results[i].active) &&
              results[i].active
                ? true
                : false;
            if (!paid && active) {
              const id =
                !empty(results[i]) && !empty(results[i]._id)
                  ? results[i]._id
                  : "";
              _studentIds.push(id);
            }
          }
        }
        setStudentIds(_studentIds);
      }

      // check if a new student was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const studentAdded = !empty(paramState.studentAdded)
          ? paramState.studentAdded
          : false;
        const studentUpdated = !empty(paramState.studentUpdated)
          ? paramState.studentUpdated
          : false;
        if (studentAdded === true || studentUpdated === true) {
          const actionType = studentAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Student ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { studentAdded: false, studentUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  //function to toggle student visibility
  const toggleStudentVisiblity = async () => {
    try {
      if (studentToToggleDialog) setStudentToToggleDialog(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const active =
        !empty(selectedStudentDetail) && !empty(selectedStudentDetail.active)
          ? "Yes"
          : "No";
      const studentId =
        !empty(selectedStudentDetail) && !empty(selectedStudentDetail._id)
          ? selectedStudentDetail._id
          : "";
      const response = await studentApi.toggleStudentVisibility(
        studentId,
        active,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to ${
                !empty(selectedStudentDetail) &&
                !empty(selectedStudentDetail.active) &&
                selectedStudentDetail.active === false
                  ? "enable"
                  : "disable"
              } student with name, ${
                !empty(selectedStudentDetail.lastName) &&
                !empty(selectedStudentDetail.firstName)
                  ? `${selectedStudentDetail.lastName} ${selectedStudentDetail.firstName}`
                  : ""
              }`;
        return responseDailog("error", "Something went wrong1", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Student, ${
          !empty(selectedStudentDetail.lastName) &&
          !empty(selectedStudentDetail.firstName)
            ? `${selectedStudentDetail.lastName} ${selectedStudentDetail.firstName}`
            : ""
        }, was ${
          !empty(selectedStudentDetail) &&
          !empty(selectedStudentDetail.active) &&
          selectedStudentDetail.active === true
            ? "disabled"
            : "enabled"
        } successfully!`
      );
      return getStudents();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to activate student on payment
  const activateStudent = async (studentIds) => {
    try {
      if (activationModalOpen) setActivationModalOpen(false);
      if (!isActionLoading) setIsActionLoading(true);
      if (empty(studentIds)) {
        return responseDailog(
          "error",
          "Error Alert",
          "There are no selected students."
        );
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.activateStudent(
        studentIds,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      const tag = `${studentIds.length > 0 ? "students" : "student"}`;
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to activate ${tag}`;
        return responseDailog("error", "Error Alert", error_response);
      }

      responseDailog("success", "Success", `Activated ${tag} successfully!`);
      setSelectedStudentIds([]);
      setShowAddButton(false);
      return getStudents();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to show confirm modal
  const showDisableConfirm = (data) => {
    setSelectedStudentDetail(data);
    setStudentToToggleDialog(true);
  };

  //function to hide confirm modal
  const hideDisableStudentDialog = () => {
    setStudentToToggleDialog(false);
  };

  // open activation modal
  const showActivationModal = (data = {}, type = "") => {
    if (!empty(selectedStudentDetail)) setSelectedStudentDetail({});
    if (type === "single") {
      const id = !empty(data) && !empty(data._id) ? data._id : "";
      setSelectedStudentIds([id]);
      setSelectedStudentDetail(data);
    }
    setActivationModalOpen(true);
  };
  // hide activation modal
  const hideActivationModal = () => {
    setActivationModalOpen(false);
  };

  //go to update student page
  const gotToUpdateStudent = (data) => {
    const studentId = !empty(data) && !empty(data._id) ? data._id : "";
    navigate(`/student/${studentId}/update`);
  };

  const updateSelectedStudentIds = (subjectId) => {
    try {
      let updatedStudentIds = [];

      const index = selectedStudentIds.indexOf(subjectId);
      if (index > -1) {
        updatedStudentIds = selectedStudentIds.filter((id) => id !== subjectId);
      } else {
        updatedStudentIds = [...selectedStudentIds, subjectId];
      }
      setSelectedStudentIds(updatedStudentIds);
      if (updatedStudentIds.length !== selectedStudentIds.length) {
        setChecked(false);
      } else {
        setChecked(true);
      }
      if (empty(updatedStudentIds)) {
        setShowAddButton(false);
      } else {
        setShowAddButton(true);
      }
    } catch (error) {}
  };

  const disableStudentDialogFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideDisableStudentDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={toggleStudentVisiblity}
      />
    </div>
  );

  const activateStudentDialogFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideActivationModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={() => {
          activateStudent(selectedStudentIds);
        }}
      />
    </div>
  );

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setFirst(0);
  };

  // open promotion modal
  const showPromotionModal = (data = {}, type = "") => {
    if (!empty(selectedStudentDetail)) setSelectedStudentDetail({});
    if (type === "single") {
      const id = !empty(data) && !empty(data._id) ? data._id : "";
      setSelectedStudentIds([id]);
      setSelectedStudentDetail(data);
    }
    setPromotionModalOpen(true);
  };

  // hide activation modal
  const hidePromotionModal = () => {
    setPromotionModalOpen(false);
  };

  const handleSubmit = async (values) => {
    try {
      if (promotionModalOpen) setPromotionModalOpen(false);
      if (!isActionLoading) setIsActionLoading(true);
      if (empty(studentIds)) {
        return responseDailog(
          "error",
          "Error Alert",
          "There are no selected students."
        );
      }
      const comment =
        !empty(values) && !empty(values.comment) ? values.comment : "";
      const _classId =
        !empty(values) && !empty(values.classId) ? values.classId : "";
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.promoteStudent(
        selectedStudentIds,
        schoolId,
        comment,
        _classId,
        classId,
        token
      );
      const response_data = prepareResponseData(response);
      const tag = `${selectedStudentIds.length > 0 ? "students" : "student"}`;
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to promote the selected ${tag}`;
        return responseDailog("error", "Error Alert", error_response);
      }

      responseDailog("success", "Success", `Promoted ${tag} successfully!`);
      setSelectedStudentIds([]);
      setShowAddButton(false);
      return getStudents();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              button={empty(classId) ? true : false}
              buttonText="Add Student"
              link="/student/add"
              title={
                !empty(classTitle) ? "Students in " + classTitle : `Students`
              }
            />
            {/* end of header */}

            {showAddButton && !isPromotionList ? (
              <div className="flex flex-end">
                <ButtonIcon
                  borderColor={colors.primary}
                  backgroundColor={colors.primary}
                  color="#ffffff"
                  buttonText="Activate Students"
                  marginRight={1}
                  width={160}
                  height={30}
                  marginBottom={2}
                  onClick={() => showActivationModal()}
                />
              </div>
            ) : showAddButton && isPromotionList ? (
              <div className="flex flex-end">
                <ButtonIcon
                  borderColor={colors.primary}
                  backgroundColor={colors.primary}
                  color="#ffffff"
                  buttonText="Promote Students"
                  marginRight={1}
                  width={160}
                  height={30}
                  marginBottom={2}
                  onClick={() => showPromotionModal()}
                />
              </div>
            ) : (
              <div></div>
            )}

            {/* table start */}
            {!isLoading ? (
              <StudentsTable
                students={studentData}
                confirmDisableSelected={showDisableConfirm}
                goToUpdateStudentScreen={gotToUpdateStudent}
                onPageChange={onPageChange}
                loading={isLoading}
                rows={rows}
                totalRecords={totalRecords}
                first={first}
                search={search}
                isClassList={!empty(classId) ? true : false}
                onSearchChange={onSearchChange}
                goToActivateStudent={(data) =>
                  showActivationModal(data, "single")
                }
                promoteStudent={(data) => showPromotionModal(data, "single")}
                setChecked={setChecked}
                setShowAddButton={setShowAddButton}
                showAddButton={showAddButton}
                checked={checked}
                setSelectedStudentIds={setSelectedStudentIds}
                selectedStudentIds={selectedStudentIds}
                updateSelectedStudentIds={updateSelectedStudentIds}
                studentIds={studentIds}
                isPromotionList={!empty(promotion) ? true : false}
                activateEmpty={empty(studentIds) ? true : false}
              />
            ) : (
              <TableLoading rows={50} cols={7} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Dialog
        visible={studentToToggleDialog}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={disableStudentDialogFooter}
        onHide={hideDisableStudentDialog}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to{" "}
              {!empty(selectedStudentDetail) &&
              !empty(selectedStudentDetail.active) &&
              selectedStudentDetail.active ? (
                <span style={{ color: "red" }}>
                  <strong>disable</strong>
                </span>
              ) : (
                <span style={{ color: "green" }}>
                  <strong>enable</strong>
                </span>
              )}{" "}
              the selected student -{" "}
              <strong>
                {!empty(selectedStudentDetail) &&
                !empty(selectedStudentDetail.title)
                  ? selectedStudentDetail.title
                  : ""}
              </strong>
              {selectedStudentDetail && (
                <b>
                  {!empty(selectedStudentDetail.name)
                    ? selectedStudentDetail.name
                    : ""}
                </b>
              )}
              ?
            </span>
          }
        </div>
      </Dialog>

      <Dialog
        visible={activationModalOpen}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={activateStudentDialogFooter}
        onHide={hideActivationModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <>
              <p>
                Are you sure you want to <strong>activate</strong> this student?{" "}
                <br />
                <br />
                By clicking "Yes", you are confirming that{" "}
                <strong>
                  {!empty(selectedStudentDetail) &&
                  !empty(selectedStudentDetail.firstName) &&
                  !empty(selectedStudentDetail.lastName)
                    ? selectedStudentDetail.lastName +
                      " " +
                      selectedStudentDetail.firstName +
                      " has"
                    : "the selected students have"}
                </strong>{" "}
                made portal payment for this term.
                <br />
                <br />
                <em style={{ color: colors.danger }}>
                  <strong>Note:</strong> This action cannot be undone.
                </em>
              </p>
            </>
          }
        </div>
      </Dialog>

      <Dialog
        visible={promotionModalOpen}
        className="promotion-modal"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm Promotion"
        modal
        onHide={hidePromotionModal}
      >
        <div
          className="confirmation-content"
          style={{ display: "flex", alignItems: "center" }}
        >
          {
            <>
              <div className="form-container">
                <div>
                  <p>
                    <strong>Current Class:</strong> {classTitle}
                  </p>
                  <br></br>
                  <p className="flex align-center">
                    You are about to promote the selected student(s). Ensure
                    that you are promoting the right students to the right
                    class.
                  </p>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit, values, handleChange }) => (
                    <Form style={{ width: "100%" }}>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          columnGap: 12,
                        }}
                      >
                        <SelectField
                          labelTitle="Promote to"
                          placeholder="Select class"
                          name="classId"
                          required={true}
                          options={classData}
                          height={50}
                          valueKey="id"
                          selectedOption={values.classId}
                          handleChangeFunc={handleChange}
                          customPlaceholder="Graduated"
                          customValue="graduated"
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          columnGap: 12,
                          alignItems: "center",
                          marginBottom: 12,
                        }}
                      >
                        <InputField
                          placeholder="Enter Comment"
                          name="comment"
                          height={180}
                          as="textarea"
                          row={9}
                          cols={8}
                          value=""
                          labelTitle="Comment"
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ButtonIcon
                          height={45}
                          marginTop={5}
                          color="#ffffff"
                          backgroundColor="#633ccd"
                          width={300}
                          borderColor="#633ccd"
                          buttonText="Promote"
                          type="submit"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default Student;
