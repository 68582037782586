import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import sessionApi from "../../api/Session";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import SessionTable from "../../components/tables/primeTable/session/SessionTable";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import TableLoading from "../../components/skeleton/TableLoading";

const Sessions = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [sessionData, setsessionData] = useState([]);
  const [sessionToToggleDialog, setSessionToToggleDialog] = useState(false);
  const [selectedSessionDetail, setSelectedSessionDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  useEffect(() => {
    // fetch sessions
    try {
      getSessions();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load sessions. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, { replace: true, state: { sessionAdded: false } });
      }
    }
  }, [user]);

  const getSessions = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await sessionApi.getSessions(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setsessionData([]);
      } else {
        setsessionData(response_data.response);
      }

      // check if a new session was added
      const paramState = !empty(location.state) ? location.state : {};
      const sessionAdded = !empty(paramState.sessionAdded)
        ? paramState.sessionAdded
        : false;
      const sessionUpdated = !empty(paramState.sessionUpdated)
        ? paramState.sessionUpdated
        : false;
      if (sessionAdded === true || sessionUpdated === true) {
        const actionType = sessionAdded ? "added" : "updated";
        responseDailog(
          "success",
          "Success",
          `Session ${actionType} successfully!`
        );
        if (!empty(location) && !empty(location.state)) {
          navigate(null, {
            replace: true,
            state: { sessionAdded: false, sessionUpdated: false },
          });
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong!`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  //function to disable school
  const toggleSessionVisiblity = async () => {
    try {
      if (sessionToToggleDialog) setSessionToToggleDialog(false);
      if (!isActionLoading) setIsActionLoading(true);
      const sessionId =
        !empty(selectedSessionDetail) && !empty(selectedSessionDetail._id)
          ? selectedSessionDetail._id
          : "";
      const active =
        !empty(selectedSessionDetail) &&
        !empty(selectedSessionDetail.active) &&
        selectedSessionDetail.active
          ? "Yes"
          : "No";
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await sessionApi.toggleSessionVisibility(
        schoolId,
        sessionId,
        active,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to ${
                !empty(selectedSessionDetail) &&
                !empty(selectedSessionDetail.active) &&
                selectedSessionDetail.active === false
                  ? "enable"
                  : "disable"
              } session with title, ${
                !empty(selectedSessionDetail.title)
                  ? selectedSessionDetail.title
                  : ""
              }`;
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Session, ${
          !empty(selectedSessionDetail.title) ? selectedSessionDetail.title : ""
        }, ${
          !empty(selectedSessionDetail) &&
          !empty(selectedSessionDetail.active) &&
          selectedSessionDetail.active === false
            ? "enable"
            : "disable"
        } successfully!`
      );
      return getSessions();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to show confirm modal
  const showDisableConfirm = (data) => {
    setSelectedSessionDetail(data);
    setSessionToToggleDialog(true);
  };

  //function to hide confirm modal
  const hideDisableSessionDialog = () => {
    setSessionToToggleDialog(false);
  };

  //go to update school page
  const gotToUpdateSession = (data) => {
    const sessionId = !empty(data) && !empty(data._id) ? data._id : "";
    navigate(`/session/update/${sessionId}`);
  };

  const disableSessionDialogFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideDisableSessionDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={toggleSessionVisiblity}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              button
              buttonText="Add Session"
              link="/session/add"
              title="Sessions"
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <SessionTable
                sessions={sessionData}
                confirmDisableSelected={showDisableConfirm}
                goToUpdateSessionScreen={gotToUpdateSession}
              />
            ) : (
              <TableLoading rows={10} cols={3} />
            )}
            {/* table end  */}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Dialog
        visible={sessionToToggleDialog}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={disableSessionDialogFooter}
        onHide={hideDisableSessionDialog}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to{" "}
              {!empty(selectedSessionDetail) &&
              !empty(selectedSessionDetail.active) &&
              selectedSessionDetail.active ? (
                <span style={{ color: "red" }}>
                  <strong>disable</strong>
                </span>
              ) : (
                <span style={{ color: "green" }}>
                  <strong>enable</strong>
                </span>
              )}{" "}
              the selected session -{" "}
              <strong>
                {!empty(selectedSessionDetail) &&
                !empty(selectedSessionDetail.title)
                  ? selectedSessionDetail.title
                  : ""}
              </strong>
              {selectedSessionDetail && (
                <b>
                  {!empty(selectedSessionDetail.name)
                    ? selectedSessionDetail.name
                    : ""}
                </b>
              )}
              ?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default Sessions;
