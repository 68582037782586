export const yearsData = [
  { _id: 0, title: "2023" },
  { _id: 1, title: "2024" },
  { _id: 2, title: "2025" },
  { _id: 3, title: "2026" },
  { _id: 4, title: "2027" },
  { _id: 5, title: "2028" },
  { _id: 6, title: "2029" },
  { _id: 7, title: "2030" },
  { _id: 8, title: "2031" },
  { _id: 9, title: "2032" },
  { _id: 10, title: "2033" },
  { _id: 11, title: "2034" },
  { _id: 12, title: "2035" },
];
