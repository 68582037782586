import client from "./Client";

const getSchoolData = (userId, token, sign) => {
  const params = {};
  if (sign) {
    params.sign = sign;
  }
  return client.get(`/details/${encodeURIComponent(userId)}`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export default {
  getSchoolData,
};
