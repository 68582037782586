import colors from "../../config/colors";
import "./StatCard.css";

const StatCard = ({
  bgColor = colors.primary,
  title,
  entry,
  icon,
  entrySize = 20,
}) => {
  return (
    <div className="stat_card">
      <div className="stat_card_details">
        <div className="icon_container">
          <div className="stat_card_icon_box" style={{ background: bgColor }}>
            {icon}
          </div>
        </div>
        <div className="stat_card_side_details">
          <span className="stat_card_entry" style={{ fontSize: entrySize }}>
            {entry}
          </span>
          <span className="stat_card_title">{title}</span>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
