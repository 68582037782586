import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate, useLocation, useParams, NavLink } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Checkbox } from "primereact/checkbox";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";
import "./Attendance.css";

// api
import subjectApi from "../../api/Subject";
import classApi from "../../api/Classes";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { FaPen, FaQrcode } from "react-icons/fa";
import Card from "../../components/card/Card";
import colors from "../../config/colors";
import AttendanceWrapper from "../../components/appWrapper/AttendanceWrapper";

const AttendanceCheckIn = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [subjectData, setSubjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [classSubjects, setClassSubjects] = useState([]);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
  const [subjectIds, setSubjectIds] = useState([]);
  const [classTitle, setClassTitle] = useState("");
  const [checked, setChecked] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const { classId } = useParams();
  if (!classId) {
    // navigate("/404");
  }

  useEffect(() => {
    // fetch subject
    getClassDetails();
  }, []);

  useEffect(() => {
    if (empty(selectedSubjectIds)) {
      setShowAddButton(false);
    } else {
      setShowAddButton(true);
    }
  }, [selectedSubjectIds]);

  const getSubjects = async (subjectsInClass) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSubjects(schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        setSubjectData([]);
      } else {
        const results =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        let updatedSubjectids = [];
        let updatedResults = [];
        for (let i = 0; i < results.length; i++) {
          const subjectId =
            !empty(results[i]) && !empty(results[i]._id) ? results[i]._id : "";
          updatedSubjectids.push(subjectId);
          results[i].added = subjectsInClass.includes(subjectId);
          updatedResults.push(results[i]);
        }
        setSubjectIds(updatedSubjectids);
        setSubjectData(updatedResults);
      }

      if (location.state) {
        const { subjectAdded = false, subjectUpdated = false } = location.state;
        if (subjectAdded || subjectUpdated) {
          const actionType = subjectAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Subject ${actionType} successfully!`
          );
          navigate(location.pathname, {
            replace: true,
            state: { subjectAdded: false, subjectUpdated: false },
          });
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    } finally {
      setIsLoading(false);
    }
  };

  const getClassDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSingleClass(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to fetch class details"
        );
      } else {
        const classTitle =
          !empty(response_data.response) && !empty(response_data.response.title)
            ? response_data.response.title
            : "";
        const subjectsInClass =
          !empty(response_data.response) &&
          !empty(response_data.response.subjects)
            ? response_data.response.subjects
            : [];
        setClassSubjects(subjectsInClass);
        setClassTitle(classTitle);

        getSubjects(subjectsInClass);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    }
  };

  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const updateSelectedSubjectIds = (
    subjectId,
    sideAction = false,
    add = false
  ) => {
    try {
      let updatedSubjects = [];
      if (sideAction) {
        let action = "add";
        if (add) {
          updatedSubjects = [...classSubjects, subjectId];
        } else {
          action = "remove";
          const index = classSubjects.indexOf(subjectId);
          if (index > -1) {
            updatedSubjects = classSubjects.filter((id) => id !== subjectId);
          }
        }
        toggleSubjectInClass(action, updatedSubjects, sideAction);
      } else {
        const index = selectedSubjectIds.indexOf(subjectId);
        if (index > -1) {
          updatedSubjects = selectedSubjectIds.filter((id) => id !== subjectId);
        } else {
          updatedSubjects = [...selectedSubjectIds, subjectId];
        }
        setSelectedSubjectIds(updatedSubjects);
        if (updatedSubjects.length !== subjectIds.length) {
          setChecked(false);
        } else {
          setChecked(true);
        }
      }
    } catch (error) {}
  };

  const toggleSubjectInClass = async (
    action = "add",
    updatedSubjects = [],
    sideAction = false
  ) => {
    try {
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      let dataToUpdate = [];
      if (!sideAction) {
        if (action === "add") {
          if (
            selectedSubjectIds.every((item) => classSubjects.includes(item))
          ) {
            dataToUpdate = selectedSubjectIds;
          } else {
            dataToUpdate = [
              ...new Set([...classSubjects, ...selectedSubjectIds]),
            ];
          }
        } else {
          const filteredArray = classSubjects.filter(
            (item) => !selectedSubjectIds.includes(item)
          );
          dataToUpdate = filteredArray;
        }
      } else {
        dataToUpdate = updatedSubjects;
      }
      const response = await subjectApi.toggleSubjectsInClass(
        dataToUpdate,
        classId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      setSelectedSubjectIds([]);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Something went wrong",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Operation failed"
        );
      } else {
        await getClassDetails();
        responseDailog("success", "Success", "Operation successful");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  return (
    <>
      <AttendanceWrapper {...props}>
        <main>
          <div className="tableCard">
            <MainHeader title="Attendance Type" />
          </div>
          <div className="attendance-type-container">
            <NavLink
              to="/attendance/scanner"
              style={{ textDecoration: "none" }}
            >
              <Card
                children={
                  <>
                    <FaQrcode color={colors.primary} size={100} />
                    <p>QR-Code</p>
                  </>
                }
                addStyle="attendance-type-box"
              />
            </NavLink>
            <NavLink to="/attendance/manual" style={{ textDecoration: "none" }}>
              <Card
                children={
                  <>
                    <FaPen color={colors.primary} size={100} />
                    <p>Manual</p>
                  </>
                }
                addStyle="attendance-type-box"
              />
            </NavLink>
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AttendanceWrapper>

      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default AttendanceCheckIn;
