import "./ButtonIcon.css";
import Button from "@mui/material/Button";
import { FaListUl } from "react-icons/fa";
import { isString } from "../../../Utilities/utils";

function ButtonIcon({
  backgroundColor,
  borderColor,
  color,
  icon,
  marginTop,
  buttonText,
  width,
  height,
  onClick,
  type,
  marginRight,
  fontSize = 14,
  textMarginLeft,
  borderHover = true,
  marginBottom = 0,
  borderRadius = 24,
  iconMarginRight = 0,
}) {
  return (
    <Button
      type={type}
      sx={{
        backgroundColor,
        color,
        width,
        borderColor,
        marginTop,
        height,
        marginRight,
        borderRadius,
        marginBottom,
        textMarginLeft,
        textTransform: "none",
        "&:hover": {
          backgroundColor,
          opacity: 0.8,
          ...(borderHover && { borderColor: "transparent" }),
        },
      }}
      variant="outlined"
      onClick={onClick}
    >
      {buttonText === "View all" ? (
        <FaListUl style={{ fontSize }} />
      ) : (
        icon && (
          <span style={{ marginRight: iconMarginRight, marginTop: 3 }}>
            {icon}
          </span>
        )
      )}
      <span style={{ marginLeft: textMarginLeft }}>
        {buttonText && isString(buttonText)
          ? buttonText.charAt(0).toUpperCase() + buttonText.slice(1)
          : buttonText}
      </span>
    </Button>
  );
}

export default ButtonIcon;
