import { useContext, useRef, useState } from "react";
import {
  FaChartArea,
  FaChartLine,
  FaEye,
  FaUserCheck,
  FaUserCircle,
  FaUserFriends,
  FaUserGraduate,
  FaUserTie,
  FaUserTimes,
} from "react-icons/fa";

// api
import schoolApi from "../../api/School";

//css
import "../dashboard/Dashboard.css";

//components
import Card from "../../components/card/Card";
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import ListTitleSubtitle from "../../components/lists/listTitleSubtitle/ListTitleSubtitle";
import Barchart from "../../components/chart/barchart/Barchart";
import DoughnutChart from "../../components/chart/doughnut/DoughnutChart";
import StatCard from "../../components/statcard/StatCard";
import AppCalendar from "../../components/calendar/AppCalendar";

//data
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import BoxLoading from "../../components/skeleton/BoxLoading";
import BarChartLoading from "../../components/skeleton/BarChartLoading";
import DonutChartLoading from "../../components/skeleton/DonutChartLoading";
import TableLoading from "../../components/skeleton/TableLoading";
import colors from "../../config/colors";
import AttendanceWrapper from "../../components/appWrapper/AttendanceWrapper";

const AttendanceDashboard = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const [value, onChange] = useState(new Date());
  const [noOfStudentsInSchool, setNoOfStudentsInSchool] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [noOfSubjectsInSchool, setNoOfSubjectsInSchool] = useState(0);
  const [noOfStaffInSchool, setNoOfStaffInSchool] = useState(0);
  const [noOfClasses, setNoOfClasses] = useState(0);
  const [studentPerformance, setStudentPerformance] = useState(0);
  const [noOfStudentsGender, setNoOfStudentsGender] = useState(0);
  const [staffGender, setStaffGender] = useState(0);
  const [recentlyRegisteredStudents, setRecentlyRegisteredStudent] = useState(
    []
  );
  const [recentlyRegisteredStaff, setRecentlyRegisteredStaff] = useState([]);

  // stat status
  const [noOfStudentsLoading, setNoOfStudentsLoading] = useState(false);
  const [noOfSubjectsLoading, setNoOfSubjectsLoading] = useState(false);
  const [noOfStaffLoading, setNoOfStaffLoading] = useState(false);
  const [noOfClassesLoading, setNoOfClassesLoading] = useState(false);
  const [assessmentPerformanceLoading, setAssessmentPerformanceLoading] =
    useState(false);
  const [assessmentDataLoading, setAssessmentDataLoading] = useState(false);
  const [noOfStudentsByGenderLoading, setNoOfStudentsByGenderLoading] =
    useState(false);
  const [noOfStaffByGenderLoading, setNoOfStaffByGenderLoading] =
    useState(false);
  const [
    recentlyRegisteredStudentsLoading,
    setRecentlyRegisteredStudentsLoading,
  ] = useState(false);
  const [recentlyRegisteredStaffLoading, setRecentlyRegisteredStaffLoading] =
    useState(false);

  const assessmentData = [];
  const [chartData, setChartData] = useState({
    labels: assessmentData.map((data) => {
      const classTitle = !empty(data) && !empty(data.title) ? data.title : "";
      return classTitle;
    }),
    datasets: [
      {
        label: "Class Performance",
        data: assessmentData.map((data) => {
          const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
          return sum;
        }),
        backgroundColor: colors.charcoal,
        borderRadius: 12,
      },
    ],
  });
  const toastTR = useRef(null);

  useEffect(() => {
    getNumOfStudents();
    getNoOfSubjects();
    getNoOfStaff();
    getNoOfClasses();
    getStudentAssessmentPerformance();
    getSchoolAssessmentsData();
    getNoOfStudentsByGender();
    getNoOfStaffByGender();
    getRecentlyRegisterdStudents();
    getRecentlyRegisterdStaff();
  }, [user]);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getNumOfStudents = async () => {
    setNoOfStudentsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getNumberOfStudentsInSchool(
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setNoOfStudentsInSchool(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog(
        "error",
        "Attendance of Students",
        "Something went wrong."
      );
    } finally {
      setNoOfStudentsLoading(false);
    }
  };

  const getSchoolAssessmentsData = async () => {
    setAssessmentDataLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getSchoolAssessmentsData(
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data?.response
      ) {
        return;
      }
      const _assessmentData =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : [];
      setChartData({
        labels: _assessmentData.map((data) => {
          const classTitle =
            !empty(data) && !empty(data.title) ? data.title : "";
          return classTitle;
        }),
        datasets: [
          {
            label: "Class Performance",
            data: _assessmentData.map((data) => {
              const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
              return sum;
            }),
            backgroundColor: _assessmentData.map((data) => {
              const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
              let colorCode = colors.primary;
              if (sum < 50) {
                colorCode = colors.orange;
              }
              return colorCode;
            }),
            borderRadius: 12,
          },
        ],
      });
    } catch (error) {
      responseDailog("error", "Assessment Data", "Something went wrong.");
    } finally {
      setAssessmentDataLoading(false);
    }
  };

  const getNoOfSubjects = async () => {
    setNoOfSubjectsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getNumberOfSubjectsInSchool(
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setNoOfSubjectsInSchool(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog(
        "error",
        "Attendance of Students",
        "Something went wrong."
      );
    } finally {
      setNoOfSubjectsLoading(false);
    }
  };

  const getNoOfStudentsByGender = async () => {
    setNoOfStudentsByGenderLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getNoOfSchoolStudentsByGender(
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setNoOfStudentsGender(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog(
        "error",
        "Attendance of Students",
        "Something went wrong."
      );
    } finally {
      setNoOfStudentsByGenderLoading(false);
    }
  };

  const getNoOfStaff = async () => {
    setNoOfStaffLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getNumberOfStaff(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setNoOfStaffInSchool(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog(
        "error",
        "Attendance of Students",
        "Something went wrong."
      );
    } finally {
      setNoOfStaffLoading(false);
    }
  };

  const getNoOfClasses = async () => {
    setNoOfClassesLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getNumberOfClasses(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setNoOfClasses(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog(
        "error",
        "Attendance of Students",
        "Something went wrong."
      );
    } finally {
      setNoOfClassesLoading(false);
    }
  };

  const getStudentAssessmentPerformance = async () => {
    setAssessmentPerformanceLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getStudentAssessmentPerformance(
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setStudentPerformance(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog(
        "error",
        "Attendance of Students",
        "Something went wrong."
      );
    } finally {
      setAssessmentPerformanceLoading(false);
    }
  };

  const getNoOfStaffByGender = async () => {
    setNoOfStaffByGenderLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getNoOfSchoolStaffByGender(
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setStaffGender(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog(
        "error",
        "Attendance of Students",
        "Something went wrong."
      );
    } finally {
      setNoOfStaffByGenderLoading(false);
    }
  };

  const getRecentlyRegisterdStudents = async () => {
    setRecentlyRegisteredStudentsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getRecentlyRegisteredStudents(
        schoolId,
        0,
        10,
        "",
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setRecentlyRegisteredStudent(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog(
        "error",
        "Recently Registered Students",
        "Something went wrong."
      );
    } finally {
      setRecentlyRegisteredStudentsLoading(false);
    }
  };

  const getRecentlyRegisterdStaff = async () => {
    setRecentlyRegisteredStaffLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getRecentlyRegisteredStaff(
        schoolId,
        0,
        10,
        "",
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setRecentlyRegisteredStaff(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog(
        "error",
        "Recently Registered Staff",
        "Something went wrong."
      );
    } finally {
      setRecentlyRegisteredStaffLoading(false);
    }
  };

  return (
    <>
      <AttendanceWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Attendance" />
          {/* end menu header */}
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    !noOfStudentsLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Students Present"
                        entry={noOfStudentsInSchool}
                        icon={
                          <FaUserCheck size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !noOfStaffLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Students Absent"
                        entry={noOfStaffInSchool}
                        icon={
                          <FaUserTimes size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !noOfStudentsLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Staff Present"
                        entry={noOfStudentsInSchool}
                        icon={
                          <FaUserTie size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !noOfStaffLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Staff Absent"
                        entry={noOfStaffInSchool}
                        icon={
                          <FaUserTimes size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              <div className="top_stat_box">
                <Card
                  children={
                    !noOfStudentsLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Percentage of Students Present"
                        entry={noOfStudentsInSchool}
                        icon={
                          <FaUserCheck size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !noOfStaffLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Percentage of Students Absent"
                        entry={noOfStaffInSchool}
                        icon={
                          <FaUserTimes size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !noOfStudentsLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Percentage of Staff Present"
                        entry={noOfStudentsInSchool}
                        icon={
                          <FaUserTie size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !noOfStaffLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Percentage of Staff Absent"
                        entry={noOfStaffInSchool}
                        icon={
                          <FaUserTimes size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader title="Class Performance" />
                      {!assessmentDataLoading ? (
                        <Barchart data={chartData} />
                      ) : (
                        <BarChartLoading />
                      )}
                    </>
                  }
                  addStyle="barchart"
                />

                <div className="inner_chart_box">
                  <Card
                    children={
                      <>
                        <MainHeader title="Attendance of Students By Gender" />
                        {!noOfStudentsByGenderLoading ? (
                          <DoughnutChart
                            doughnutData={[
                              !empty(noOfStudentsGender) &&
                              !empty(noOfStudentsGender.male)
                                ? noOfStudentsGender.male
                                : 0,
                              !empty(noOfStudentsGender) &&
                              !empty(noOfStudentsGender.female)
                                ? noOfStudentsGender.female
                                : 0,
                            ]}
                          />
                        ) : (
                          <DonutChartLoading />
                        )}
                      </>
                    }
                    id="chart_box1"
                  />
                  <Card
                    children={
                      <>
                        <MainHeader title="Attendance of Staff By Gender" />
                        {!noOfStaffByGenderLoading ? (
                          <DoughnutChart
                            doughnutData={[
                              !empty(staffGender) && !empty(staffGender.male)
                                ? staffGender.male
                                : 0,
                              !empty(staffGender) && !empty(staffGender.female)
                                ? staffGender.female
                                : 0,
                            ]}
                          />
                        ) : (
                          <DonutChartLoading />
                        )}
                      </>
                    }
                    id="chart_box1"
                  />
                </div>
                <div className="inner_chart_box">
                  <Card
                    children={
                      <>
                        <MainHeader title="Recent Check-Ins" />
                        <div className="dashboard_school_list">
                          {!recentlyRegisteredStudentsLoading ? (
                            recentlyRegisteredStudents.map((student) => {
                              const firstName =
                                !empty(student) && !empty(student.firstName)
                                  ? student.firstName
                                  : "";
                              const lastName =
                                !empty(student) && !empty(student.lastName)
                                  ? student.lastName
                                  : "";
                              const middleName =
                                !empty(student) && !empty(student.middleName)
                                  ? student.middleName
                                  : "";
                              return (
                                <ListTitleSubtitle
                                  key={
                                    !empty(student) && !empty(student._id)
                                      ? student._id
                                      : ""
                                  }
                                  img={
                                    !empty(student) && !empty(student.avatar)
                                      ? student.avatar
                                      : ""
                                  }
                                  title={`${lastName} ${firstName} ${middleName}`}
                                  date={
                                    !empty(student) &&
                                    !empty(student.dateRegistered)
                                      ? student.dateRegistered
                                      : ""
                                  }
                                  subtitle={
                                    !empty(student) && !empty(student.class)
                                      ? student.class
                                      : ""
                                  }
                                  link={`/student/${
                                    !empty(student) && !empty(student._id)
                                      ? student._id
                                      : ""
                                  }/profile`}
                                />
                              );
                            })
                          ) : (
                            <TableLoading rows={20} cols={1} header={false} />
                          )}
                        </div>
                      </>
                    }
                    id="chart_box2"
                  />
                  <Card
                    children={
                      <>
                        <MainHeader title="Recent Check-Outs" />
                        <div className="dashboard_school_list">
                          {!recentlyRegisteredStaffLoading ? (
                            recentlyRegisteredStaff.map((staff, index) => {
                              const firstName =
                                !empty(staff) && !empty(staff.firstName)
                                  ? staff.firstName
                                  : "";
                              const lastName =
                                !empty(staff) && !empty(staff.lastName)
                                  ? staff.lastName
                                  : "";
                              const middleName =
                                !empty(staff) && !empty(staff.middleName)
                                  ? staff.middleName
                                  : "";
                              return (
                                <ListTitleSubtitle
                                  key={
                                    !empty(staff) && !empty(staff._id)
                                      ? staff._id
                                      : ""
                                  }
                                  img={
                                    !empty(staff) && !empty(staff.avatar)
                                      ? staff.avatar
                                      : ""
                                  }
                                  title={`${lastName} ${firstName} ${middleName}`}
                                  date={
                                    !empty(staff) &&
                                    !empty(staff.dateRegistered)
                                      ? staff.dateRegistered
                                      : ""
                                  }
                                  subtitle={
                                    !empty(staff) && !empty(staff.email)
                                      ? staff.email
                                      : ""
                                  }
                                  link={`/staff/${
                                    !empty(staff) && !empty(staff._id)
                                      ? staff._id
                                      : ""
                                  }/profile`}
                                />
                              );
                            })
                          ) : (
                            <TableLoading rows={20} cols={1} header={false} />
                          )}
                        </div>
                      </>
                    }
                    id="chart_box2"
                  />
                </div>
              </div>
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              {/* calendar */}
              <Card
                children={
                  <AppCalendar
                    onChange={onChange}
                    value={value}
                    onClickDay={(value) => alert(value)}
                  />
                }
                addStyle="card_adjust"
              />

              {/* end of calendar */}
            </div>
          </div>
          {/* end of right box */}
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </AttendanceWrapper>
    </>
  );
};

export default AttendanceDashboard;
