import client from "./Client";

const addSubject = (school_id, title, code, active, discipline, token) =>
  client.post(
    "/subject/add",
    {
      school_id,
      title,
      code,
      active,
      discipline,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getSubjects = (schoolId, token) =>
  client.get(`/${schoolId}/subjects`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const toggleSubjectVisibility = (subject_id, active, school_id, token) =>
  client.post(
    `/subject/visibility/toggle`,
    {
      school_id,
      subject_id,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const toggleSubjectsInClass = (subject_ids, class_id, school_id, token) =>
  client.post(
    `/class/subjects/toggle`,
    {
      school_id,
      id: class_id,
      subject_ids,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const toggleStaffSubjects = (
  re_indexed_authorized_subjects,
  staff_id,
  school_id,
  token
) =>
  client.post(
    `/staff/subjects/assign`,
    {
      re_indexed_authorized_subjects,
      id: staff_id,
      school_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const updateSubject = (
  subject_id,
  school_id,
  title,
  code,
  active,
  discipline_id,
  token
) =>
  client.post(
    "/subject/update",
    {
      subject_id,
      school_id,
      title,
      code,
      active,
      discipline_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const deleteSubject = (subject_id, school_id, token) =>
  client.post(
    "/subject/delete",
    {
      subject_id,
      school_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getSingleSubject = (subject_id, school_id, token) =>
  client.get(`/${school_id}/subject/${subject_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

export default {
  addSubject,
  getSubjects,
  toggleSubjectVisibility,
  updateSubject,
  getSingleSubject,
  deleteSubject,
  toggleSubjectsInClass,
  toggleStaffSubjects,
};
