import React from "react";

import "./ErrorPages.css";

function NotFound() {
  return (
    <div className="not-found">
      <p className="not-found-title">Error 404</p>
      <p>The page or resource was not found</p>
    </div>
  );
}

export default NotFound;
