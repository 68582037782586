import React, { useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "../schools/SchoolsTable.css";
import { empty } from "../../../../Utilities/utils";
import { useNavigate } from "react-router-dom";
import colors from "../../../../config/colors";
import { Checkbox } from "primereact/checkbox";
import { FaCheck } from "react-icons/fa";

export default function StudentsTable({
  students,
  confirmDisableSelected,
  goToUpdateStudentScreen,
  getStudentReportScreen,
  onPageChange,
  loading,
  rows = 50,
  totalRecords,
  first,
  search = "",
  onSearchChange,
  assessment = false,
  openAssessmentModal,
  recordScores = false,
  reportSheet = false,
  isClassList = false,
  isPromotionList = false,
  goToActivateStudent,
  selectedStudentIds = [],
  setShowAddButton,
  setSelectedStudentIds,
  setChecked,
  updateSelectedStudentIds,
  checked,
  studentIds,
  promoteStudent,
  activateEmpty,
}) {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedStudent, setSelectedStudent] = useState(null);

  const getSeverity = (status) => {
    switch (status) {
      case false:
        return "danger";

      case true:
        return "success";
      default:
        return "danger";
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          !empty(rowData) && !empty(rowData.active) ? "ACTIVE" : "IN-ACTIVE"
        }
        severity={getSeverity(
          !empty(rowData) && !empty(rowData.active) ? rowData.active : false
        )}
      />
    );
  };

  // go to profile
  const goToProfile = (rowData) => {
    const studentId = !empty(rowData) && !empty(rowData._id) ? rowData._id : "";
    navigate(`/student/${studentId}/profile`);
  };

  const renderHeader = () => {
    return (
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={search}
          onChange={onSearchChange}
          placeholder="Student Search"
        />
      </span>
    );
  };

  const actionBodyTemplate = (rowData) => {
    const hasPaid =
      !empty(rowData) && !empty(rowData.paid) && rowData.paid === "Yes"
        ? true
        : false;
    return (
      <div style={{ display: "flex", gap: 8 }}>
        {assessment ? (
          <>
            {recordScores && (
              <Button
                icon="pi pi-cloud-upload"
                style={{
                  width: 35,
                  height: 35,
                  backgroundColor: "transparent",
                  color: "#22C55E",
                  borderColor: "#22C55E",
                  borderWidth: 1,
                  borderRadius: "50%",
                }}
                onClick={() => openAssessmentModal(rowData)}
              />
            )}
            {reportSheet && (
              <Button
                icon="pi pi-id-card"
                style={{
                  width: 35,
                  height: 35,
                  backgroundColor: "transparent",
                  color: "#6366F1",
                  borderColor: "#6366F1",
                  borderWidth: 1,
                  borderRadius: "50%",
                }}
                onClick={() => getStudentReportScreen(rowData)}
              />
            )}
          </>
        ) : hasPaid && !isPromotionList ? (
          <>
            {/* go to profile */}
            <Button
              icon="pi pi-eye"
              style={{
                width: 35,
                height: 35,
                backgroundColor: "transparent",
                color: "#22C55E",
                borderColor: "#22C55E",
                borderWidth: 1,
                borderRadius: "50%",
              }}
              onClick={() => goToProfile(rowData)}
            />
            {/* go to update student */}
            <Button
              icon="pi pi-pencil"
              style={{
                width: 35,
                height: 35,
                backgroundColor: "transparent",
                color: "#633ccd",
                borderColor: "#633ccd",
                borderWidth: 1,
                borderRadius: "50%",
              }}
              onClick={() => goToUpdateStudentScreen(rowData)}
            />
            {/* toggle visibility */}
            <Button
              icon={
                !empty(rowData) &&
                !empty(rowData.active) &&
                rowData.active === true
                  ? "pi pi-times"
                  : "pi pi-check"
              }
              style={{
                backgroundColor: "transparent",
                width: 35,
                height: 35,
                color:
                  !empty(rowData) &&
                  !empty(rowData.active) &&
                  rowData.active === true
                    ? "#e65061"
                    : "#389d17",
                borderColor:
                  !empty(rowData) &&
                  !empty(rowData.active) &&
                  rowData.active === true
                    ? "#e65061"
                    : "#389d17",
                borderWidth: 1,
                borderRadius: "50%",
              }}
              onClick={() => confirmDisableSelected(rowData)}
            />
          </>
        ) : isPromotionList ? (
          // promote student
          <Button
            icon="pi pi-forward"
            style={{
              width: 35,
              height: 35,
              backgroundColor: "transparent",
              color: colors.primary,
              borderColor: colors.primary,
              borderWidth: 1,
              borderRadius: "50%",
            }}
            onClick={() => promoteStudent(rowData)}
          />
        ) : (
          // activate student
          <>
            <Button
              icon="pi pi-wallet"
              style={{
                width: 35,
                height: 35,
                backgroundColor: "transparent",
                color: colors.danger,
                borderColor: colors.danger,
                borderWidth: 1,
                borderRadius: "50%",
              }}
              onClick={() => goToActivateStudent(rowData)}
            />
            {/* toggle visibility */}
            <Button
              icon={
                !empty(rowData) &&
                !empty(rowData.active) &&
                rowData.active === true
                  ? "pi pi-times"
                  : "pi pi-check"
              }
              style={{
                backgroundColor: "transparent",
                width: 35,
                height: 35,
                color:
                  !empty(rowData) &&
                  !empty(rowData.active) &&
                  rowData.active === true
                    ? "#e65061"
                    : "#389d17",
                borderColor:
                  !empty(rowData) &&
                  !empty(rowData.active) &&
                  rowData.active === true
                    ? "#e65061"
                    : "#389d17",
                borderWidth: 1,
                borderRadius: "50%",
              }}
              onClick={() => confirmDisableSelected(rowData)}
            />
          </>
        )}
      </div>
    );
  };
  const skeletonTemplate = () => {
    return <Skeleton width="100%" height="1.4rem" />;
  };

  const checkBoxBodyTemplate = (rowData) => {
    const subjectId = !empty(rowData) && !empty(rowData._id) ? rowData._id : "";
    const hasPaid =
      !empty(rowData) && !empty(rowData.paid) && rowData.paid === "Yes"
        ? true
        : false;
    return !hasPaid || isPromotionList ? (
      <Checkbox
        checked={selectedStudentIds.includes(subjectId)}
        onChange={() => {
          updateSelectedStudentIds(subjectId);
        }}
      />
    ) : (
      <FaCheck color={colors.success} />
    );
  };

  const header = renderHeader();

  return (
    <div className="datatable">
      <DataTable
        value={students}
        paginator
        rows={rows}
        header={header}
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        selection={selectedStudent}
        onSelectionChange={(e) => setSelectedStudent(e.value)}
        selectionMode="single"
        dataKey="_id"
        stateStorage="session"
        stateKey="dt-state-demo-local"
        emptyMessage="No students found."
        tableStyle={{ minWidth: "100%" }}
        onPage={onPageChange}
        lazy={true}
        totalRecords={totalRecords}
        first={first}
      >
        {isClassList && !activateEmpty && (
          <Column
            field="_id"
            header={
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  setChecked(e.checked);
                  if (e.checked) {
                    setShowAddButton(true);
                    setSelectedStudentIds(studentIds);
                  } else {
                    setSelectedStudentIds([]);
                    setShowAddButton(false);
                  }
                }}
              />
            }
            style={{ width: "5%" }}
            body={checkBoxBodyTemplate}
          />
        )}
        <Column
          field="regNo"
          header="Reg. No."
          sortable
          filterPlaceholder="Search"
          style={{ width: "10%" }}
          body={(rowData) => rowData.regNo}
        ></Column>
        <Column
          field="lastName"
          header="Last Name"
          sortable
          filterPlaceholder="Search"
          style={{ width: "15%" }}
          body={(rowData) => rowData.lastName}
        ></Column>
        <Column
          field="firstName"
          header="First Name"
          sortable
          filterPlaceholder="Search"
          style={{ width: "15%" }}
          body={(rowData) => rowData.firstName}
        ></Column>
        <Column
          field="middleName"
          header="Middle Name"
          sortable
          filterPlaceholder="Middle name"
          style={{ width: "15%" }}
          body={(rowData) => rowData.middleName}
        ></Column>
        {!isClassList && (
          <Column
            field="class"
            header="Class"
            sortable
            filterPlaceholder="Class"
            style={{ width: "15%" }}
            body={(rowData) => rowData.class}
          ></Column>
        )}
        <Column
          field="status"
          header="Status"
          body={statusBodyTemplate}
          sortable
          filterMenuStyle={{ width: "14rem" }}
          style={{ width: "10%" }}
        ></Column>
        <Column
          header="Action"
          body={actionBodyTemplate}
          exportable={false}
          style={{ width: "15%" }}
        ></Column>
      </DataTable>
    </div>
  );
}
