import client from "./Client";

const addStaff = (
  school_id,
  first_name,
  last_name,
  middle_name,
  gender,
  title,
  address,
  state_of_residence,
  lga_of_residence,
  state_of_origin,
  lga_of_origin,
  nationality,
  email,
  phone_number1,
  phone_number2,
  qualification,
  course_studied,
  religion,
  date_of_birth,
  active,
  token
) =>
  client.post(
    "/staff/register",
    {
      school_id,
      first_name,
      last_name,
      middle_name,
      gender,
      title,
      address,
      state_of_residence,
      lga_of_residence,
      state_of_origin,
      lga_of_origin,
      nationality,
      email,
      phone_number1,
      phone_number2,
      qualification,
      course_studied,
      religion,
      date_of_birth,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getStaff = (schoolId, token) =>
  client.get(`/staff/${schoolId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const toggleStaffVisibility = (staffId, active, schoolId, token) =>
  client.post(
    `/staff/visibility/toggle`,
    {
      id: schoolId,
      staff_id: staffId,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const updateStaff = (
  staff_id,
  school_id,
  first_name,
  last_name,
  middle_name,
  gender,
  title,
  address,
  state_of_residence,
  lga_of_residence,
  state_of_origin,
  lga_of_origin,
  nationality,
  email,
  phone_number1,
  phone_number2,
  qualification,
  course_studied,
  religion,
  date_of_birth,
  active,
  token
) =>
  client.post(
    "/staff/update",
    {
      staff_id,
      school_id,
      first_name,
      last_name,
      middle_name,
      gender,
      title,
      address,
      state_of_residence,
      lga_of_residence,
      state_of_origin,
      lga_of_origin,
      nationality,
      email,
      phone_number1,
      phone_number2,
      qualification,
      course_studied,
      religion,
      date_of_birth,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const assignClassSubjectsToStaff = (
  staff_id,
  school_id,
  class_id,
  type,
  token
) =>
  client.post(
    "/staff/class/subjects/assign",
    {
      staff_id,
      school_id,
      class_id,
      type,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getSingleStaff = (staffId, schoolId, token) =>
  client.get(`/${schoolId}/staff/${staffId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getStaffProfile = (staffId, schoolId, token) =>
  client.get(`/${schoolId}/staff/${staffId}/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

export default {
  addStaff,
  getStaff,
  toggleStaffVisibility,
  updateStaff,
  getSingleStaff,
  getStaffProfile,
  assignClassSubjectsToStaff,
};
