import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { empty, prepareResponseData, reIndex } from "../../Utilities/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { AuthContext } from "../Root/ProtectedRoute";
import * as Yup from "yup";

// css
import "../students/Students.css";

// api
import subjectApi from "../../api/Subject";
import staffApi from "../../api/Staff";
import classApi from "../../api/Classes";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import TableLoading from "../../components/skeleton/TableLoading";
import MultiSelectField from "../../components/form/MultiSelectField";
import { Form, Formik } from "formik";
import { isArray } from "lodash";

const validationSchema = Yup.object().shape({
  categories: Yup.array().required("This field is required!"),
  classes: Yup.array().optional(),
});

const AssignSubjectToStaff = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  //ref
  const toastTR = useRef(null);
  // states
  const [subjectData, setSubjectData] = useState([]);
  const [initialValues, setInitialValues] = useState({
    categories: [],
    classes: [],
  });
  const [reIndexedSubjectData, setReIndexedSubjectData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
  const [subjectIds, setSubjectIds] = useState([]);
  const [staffTitle, setStaffTitle] = useState("");
  const [checked, setChecked] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [classCategories, setClassCategories] = useState([]);
  const [classes, setClasses] = useState([]);
  const [reIndexedClasses, setReIndexedClasses] = useState({});
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [defaultCategory, setDefaultClassCategory] = useState({});
  const [classIds, setClassIds] = useState([]);
  const [authorizedSubjects, setAuthorizedSubjects] = useState([]);
  const [reIndexedAuthorizedSubjects, setReIndexAuthorizedSubjects] = useState(
    {}
  );
  const { staffId } = useParams();
  if (!staffId) {
    navigate("/schools/404");
  }

  useEffect(() => {
    getClassCategories();
    getClasses();
    getStaffDetails();
  }, []);

  useEffect(() => {}, [classes, subjectData]);

  useEffect(() => {
    if (empty(selectedSubjectIds)) {
      setShowAddButton(false);
    } else {
      setShowAddButton(true);
    }
  }, [selectedSubjectIds]);

  const getClasses = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : [];
      setClasses(data);
      const _reIndexedClasses = !empty(data) ? reIndex(data, "_id") : {};
      setReIndexedClasses(_reIndexedClasses);

      const _classIds = data.map((item) => {
        const id = !empty(item) && !empty(item._id) ? item._id : "";
        return id;
      });
      setClassIds(_classIds);
    } catch (error) {}
  };

  const getClassCategories = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClassCategories(schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setClassCategories([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setClassCategories(data);

        const defaultCategory = data.find((item) => {
          const title = !empty(item) && !empty(item.title) ? item.title : "";
          return title === "ALL (DEFAULT)";
        });

        setDefaultClassCategory(defaultCategory);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjects = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSubjects(schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        setSubjectData([]);
      } else {
        const results =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setSubjectData(results);
        const _reIndexedSubjects = !empty(results)
          ? reIndex(results, "_id")
          : {};
        setReIndexedSubjectData(_reIndexedSubjects);
        const _subjectIds = results.map((data) => {
          const id = !empty(data) && !empty(data._id) ? data._id : "";
          return id;
        });
        setSubjectIds(_subjectIds);
      }

      if (location.state) {
        const { subjectAdded = false, subjectUpdated = false } = location.state;
        if (subjectAdded || subjectUpdated) {
          const actionType = subjectAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Subject ${actionType} successfully!`
          );
          navigate(location.pathname, {
            replace: true,
            state: { subjectAdded: false, subjectUpdated: false },
          });
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    } finally {
      setIsLoading(false);
    }
  };

  const getStaffDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getSingleStaff(staffId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to fetch staff details"
        );
      } else {
        const data = !empty(response_data.response)
          ? response_data.response
          : {};
        let staffTitle = "";
        let staffLastName = "";
        let staffFirstName = "";
        let _authorizedSubjects = [];
        if (!empty(data)) {
          staffTitle = !empty(data.title) ? data.title : "";
          staffLastName = !empty(data.lastName) ? data.lastName : "";

          staffFirstName = !empty(data.firstName) ? data.firstName : "";
          _authorizedSubjects = !empty(data.authorizedSubjects)
            ? data.authorizedSubjects
            : [];
        }
        const reIndexedAuthorizedSubjects = !empty(_authorizedSubjects)
          ? reIndex(_authorizedSubjects, "classId")
          : {};
        setReIndexAuthorizedSubjects(reIndexedAuthorizedSubjects);
        setAuthorizedSubjects(_authorizedSubjects);
        setStaffTitle(staffTitle);
        setFirstName(staffFirstName);
        setLastName(staffLastName);
        getSubjects();
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    }
  };

  const handleSubmit = () => {
    if (classIds.length === 0) {
      setSelectedClasses([]);
    }
    for (let i = 0; i < classIds.length; i++) {
      if (!empty(reIndexedAuthorizedSubjects[classIds[i]])) {
        const subjectIds = !empty(
          reIndexedAuthorizedSubjects[classIds[i]].subjectIds
        )
          ? reIndexedAuthorizedSubjects[classIds[i].subjectids]
          : [];
        subjectIds.push(classIds[i]);
        reIndexedAuthorizedSubjects[classIds[i].subjectIds] = [
          ...new Set(subjectIds),
        ];
      }
    }
  };

  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const updateSelectedSubjectIds = (subjectId) => {
    try {
      let updatedSubjects = [];

      const index = selectedSubjectIds.indexOf(subjectId);
      if (index > -1) {
        updatedSubjects = selectedSubjectIds.filter((id) => id !== subjectId);
      } else {
        updatedSubjects = [...selectedSubjectIds, subjectId];
      }
      setSelectedSubjectIds(updatedSubjects);
      if (updatedSubjects.length !== selectedSubjectIds.length) {
        setChecked(false);
      } else {
        setChecked(true);
      }
    } catch (error) {}
  };

  const toggleStaffSubjects = async () => {
    try {
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      for (let i = 0; i < selectedClasses.length; i++) {
        if (!empty(reIndexedAuthorizedSubjects[selectedClasses[i]])) {
          const data = reIndexedAuthorizedSubjects[selectedClasses[i]];
          let subjectIdsToUpdate = [];
          const _subjectIds = !empty(data.subjectIds) ? data.subjectIds : [];
          if (selectedSubjectIds.every((item) => _subjectIds.includes(item))) {
            subjectIdsToUpdate = selectedSubjectIds;
          } else {
            subjectIdsToUpdate = [
              ...new Set([..._subjectIds, ...selectedSubjectIds]),
            ];
          }
          if (empty(subjectIdsToUpdate)) {
            delete reIndexedAuthorizedSubjects[selectedClasses[i]];
          } else {
            reIndexedAuthorizedSubjects[selectedClasses[i]].subjectIds =
              subjectIdsToUpdate;
          }
        } else {
          reIndexedAuthorizedSubjects[selectedClasses[i]] = {
            classId: selectedClasses[i],
            subjectIds: selectedSubjectIds,
          };
        }
      }

      const response = await subjectApi.toggleStaffSubjects(
        reIndexedAuthorizedSubjects,
        staffId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      setSelectedSubjectIds([]);
      setChecked(false);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Something went wrong",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Operation failed"
        );
      } else {
        await getStaffDetails();
        responseDailog("success", "Success", "Operation successful");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleClassCategoryOnChange = (name, newValue, setFieldValue) => {
    try {
      setFieldValue("classes", []);
      const categoryIds = !empty(newValue) && isArray(newValue) ? newValue : [];
      const categoriedClasses = classes.reduce((result, item) => {
        const { categoryId } = item;
        if (!result[categoryId]) {
          result[categoryId] = [];
        }
        result[categoryId].push(item);
        return result;
      }, {});
      setFieldValue(name, categoryIds);
      let _selectedClasses = [];
      for (let i = 0; i < categoryIds.length; i++) {
        const defaultCategoryId =
          !empty(defaultCategory) && !empty(defaultCategory._id)
            ? defaultCategory._id
            : "";
        if (categoryIds[i] === defaultCategoryId) {
          _selectedClasses = classIds;
        } else {
          if (!empty(categoriedClasses[categoryIds[i]])) {
            const categoryBlock = categoriedClasses[categoryIds[i]];
            const _classIds = categoryBlock.map((item) => {
              const id = !empty(item) && !empty(item._id) ? item._id : "";
              return id;
            });
            _selectedClasses.push(..._classIds);
          }
        }
      }
      setSelectedClasses(_selectedClasses);
      setFieldValue("classes", _selectedClasses);
    } catch (error) {}
  };

  const handleClassOnChange = (name, newValue, setFieldValue) => {
    try {
      setFieldValue(name, []);
      const classIds = !empty(newValue) && isArray(newValue) ? newValue : [];
      setSelectedClasses(classIds);
      setFieldValue(name, classIds);

      let classSubjectIdsIntersection = [];
      for (let i = 0; i < classIds.length; i++) {
        const _subjectIds =
          !empty(reIndexedAuthorizedSubjects) &&
          !empty(reIndexedAuthorizedSubjects[classIds[i]]) &&
          !empty(reIndexedAuthorizedSubjects[classIds[i]].subjectIds)
            ? reIndexedAuthorizedSubjects[classIds[i]].subjectIds
            : [];

        let newSubjectIds = [];
        if (i > 0) {
          const subjectIdsSet = new Set(_subjectIds);

          // Filter the first array to only include elements that are in the Set
          newSubjectIds = classSubjectIdsIntersection.filter((item) =>
            subjectIdsSet.has(item)
          );

          classSubjectIdsIntersection = newSubjectIds;
        } else {
          classSubjectIdsIntersection = _subjectIds;
        }
      }

      setSelectedSubjectIds(classSubjectIdsIntersection);
    } catch (error) {}
  };

  const checkBoxBodyTemplate = (rowData) => {
    const subjectId = !empty(rowData) && !empty(rowData._id) ? rowData._id : "";
    return (
      <Checkbox
        checked={selectedSubjectIds.includes(subjectId)}
        onChange={() => updateSelectedSubjectIds(subjectId)}
      />
    );
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            <MainHeader
              redirect={false}
              title={`Update Staff Subject(s) - ${staffTitle} ${lastName} ${firstName}`}
            />
            <div className="mt-10">
              <p className="fs-18 mb-5" style={{ color: "#633ccd" }}>
                <strong>Assigned Subjects: </strong>
              </p>
              {authorizedSubjects.map((data) => {
                const _classId =
                  !empty(data) && !empty(data.classId) ? data.classId : "";
                const _subjectIds =
                  !empty(data) && !empty(data.subjectIds)
                    ? data.subjectIds
                    : [];
                const _classTitle = reIndexedClasses?.[_classId]?.title || "";

                const subjects = _subjectIds.map((item) => {
                  return reIndexedSubjectData?.[item]?.title || "";
                });

                return (
                  <div key={_classId} className="flex mb-5">
                    <p style={{ width: 120 }}>
                      <strong>{_classTitle}: </strong>
                    </p>
                    <span>{subjects.join(", ")}</span>
                  </div>
                );
              })}
            </div>

            <div className="category_class_container">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, handleChange, setFieldValue }) => (
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        columnGap: 12,
                        justifyContent: "flex-start",
                        marginBottom: 12,
                      }}
                    >
                      <MultiSelectField
                        name="categories"
                        required={true}
                        labelTitle="Select Class Category"
                        placeholder="Select Class Category"
                        options={classCategories}
                        valueKey="title"
                        selectedOptions={values.categories}
                        multiClassName="class_category_multiselect"
                        onChange={(e) =>
                          handleClassCategoryOnChange(
                            "categories",
                            e.value,
                            setFieldValue
                          )
                        }
                      />
                      <MultiSelectField
                        labelTitle="Classes"
                        required={true}
                        placeholder="Select Classes"
                        name="classes"
                        options={classes}
                        multiClassName="category_class_multiselect"
                        selectedOptions={values.classes}
                        valueKey="title"
                        onChange={(e) =>
                          handleClassOnChange("classes", e.value, setFieldValue)
                        }
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="table_button_container my-20">
              <span>
                <strong>No. of Selected Subjects: </strong>
                {selectedSubjectIds.length}
              </span>
              {showAddButton ? (
                <div className="flex flex-end">
                  <ButtonIcon
                    borderColor="#633ccd"
                    backgroundColor="#633ccd"
                    color="#ffffff"
                    buttonText="Update"
                    marginRight={1}
                    width={70}
                    height={30}
                    onClick={() => toggleStaffSubjects()}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {!isLoading ? (
              <DataTable value={subjectData} tableStyle={{ minWidth: "30rem" }}>
                <Column
                  field="_id"
                  header={
                    <Checkbox
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.checked);
                        if (e.checked) {
                          setShowAddButton(true);
                          setSelectedSubjectIds(subjectIds);
                        } else {
                          setSelectedSubjectIds([]);
                        }
                      }}
                    />
                  }
                  body={checkBoxBodyTemplate}
                />
                <Column field="title" header="Title" />
                <Column field="code" header="Code" />
              </DataTable>
            ) : (
              <TableLoading rows={20} cols={4} />
            )}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default AssignSubjectToStaff;
