import { useState } from "react";
import { Routes, Route } from "react-router-dom";

// screens
import Dashboard from "../dashboard/Dashboard";
import ErrorBoundary from "../../components/error/AppErrorBoundary";
import TwoFactorAuthentication from "../authentication/TwoFactorAuthentication";
import ProtectedRoute from "./ProtectedRoute";
import ForgotPassword from "../authentication/ForgotPassword";
import Signin from "../authentication/Signin";
import Students from "../students/Students";
import SchoolStaff from "../staff/SchoolStaff";
import AddUpdateSession from "../session/AddUpdateSession";
import Terms from "../term/Terms";
import AddUpdateSchoolStaff from "../staff/AddUpdateSchoolStaff";
import Sessions from "../session/Sessions";
import SchoolClasses from "../classes/SchoolClasses";
import AddUpdateClasses from "../classes/AddUpdateClasses";
import Subjects from "../subjects/Subjects";
import AddUpdateSubject from "../subjects/AddUpdateSubjects";
import AddUpdateStudent from "../students/AddUpdateStudent";
import ClassProfile from "../classes/ClassProfile";
import AddSubjectsToClass from "../classes/AddSubjectsToClass";
import StudentsPerformance from "../classes/StudentsPerformance";
import StaffProfile from "../staff/StaffProfile";
import AssignSubjectToStaff from "../staff/AssignSubjectToStaff";
import AssignClassToStaff from "../staff/AssignClassToStaff";
import StudentsProfile from "../students/StudentsProfile";
import SetAssessment from "../assessment/SetAssessment";
import RecordAssessment from "../assessment/RecordAssessment";
import Settings from "../settings/Settings";
import AcademicSettings from "../settings/AcademicSettings";
import PositionAssessment from "../assessment/PositionAssessment";
import SubjectsToAssess from "../assessment/SubjectsToAssess";
import ReportSheet from "../assessment/ReportSheet";
import ResultChecker from "../assessment/ResultCheck";
import Index from "../Index/Index";
import LogOut from "../../Utilities/logout";
import ResetPassword from "../authentication/ResetPassword";
import NotFound from "../error_pages/NotFound";
import UnauthorizedPage from "../error_pages/UnauthorizedPage";
import AddUpdateClassCategories from "../classes/AddUpdateClassCategories";
import ClassCategories from "../classes/ClassCategories";
import Disciplines from "../disciplines/Disciplines";
import UpdateClassTeachers from "../settings/UpdateClassTeachers";
import AddUpdateDiscipline from "../disciplines/AddUpdateDiscipline";
import AttendanceDashboard from "../attendance/AttendanceDashboard";
import AttendanceCheckIn from "../attendance/AttendanceCheckIn";
import AttendanceScanner from "../attendance/AttendanceScanner";

const Root = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="container">
      <Routes>
        {/* authentication. Non protected */}
        <Route
          path="/authentication/two-factor"
          element={<TwoFactorAuthentication />}
          errorElement={<ErrorBoundary />}
        />
        <Route path="/" element={<Signin />} errorElement={<ErrorBoundary />} />
        <Route element={<Index />} errorElement={<ErrorBoundary />} path="/" />
        <Route
          element={<LogOut />}
          errorElement={<ErrorBoundary />}
          path="/logout"
        />
        <Route
          element={<ForgotPassword />}
          errorElement={<ErrorBoundary />}
          path="/forgot-password"
        />
        <Route
          element={<ResetPassword />}
          errorElement={<ErrorBoundary />}
          path="/reset-password"
        />
        {/* protected route */}
        <Route element={<ProtectedRoute />} errorElement={<ErrorBoundary />}>
          <Route
            element={
              <Dashboard
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            path="/dashboard"
          />
          <Route
            path="/students/class/:classId?"
            element={
              <Students
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/students/class/:classId/:promotion"
            element={
              <Students
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/disciplines"
            element={
              <Disciplines
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/discipline/add"
            element={
              <AddUpdateDiscipline
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/discipline/:disciplineId/update"
            element={
              <AddUpdateDiscipline
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/staff/:staffId/subjects/assign"
            element={
              <AssignSubjectToStaff
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/attendance/dashboard"
            element={
              <AttendanceDashboard
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/attendance/check-in"
            element={
              <AttendanceCheckIn
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/attendance/scanner"
            element={
              <AttendanceScanner
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/settings/class/teachers"
            element={
              <UpdateClassTeachers
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/staff/:staffId/class/assign"
            element={
              <AssignClassToStaff
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/staff/:staffId/profile"
            element={
              <StaffProfile
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/student/:studentId/profile"
            element={
              <StudentsProfile
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/set"
            element={
              <SetAssessment
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/result-checker"
            element={
              <ResultChecker
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/report-sheet"
            element={
              <ReportSheet
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/subjects"
            element={
              <SubjectsToAssess
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/position"
            element={
              <PositionAssessment
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/record"
            element={
              <RecordAssessment
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/settings/academic"
            element={
              <AcademicSettings
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/settings"
            element={
              <Settings
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/class/:classId/subjects/add"
            element={
              <AddSubjectsToClass
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/students"
            element={
              <Students
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/class/:classId/students/performance"
            element={
              <StudentsPerformance
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/student/add"
            element={
              <AddUpdateStudent
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/student/:studentId/update"
            element={
              <AddUpdateStudent
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/staff"
            element={
              <SchoolStaff
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/staff/add"
            element={
              <AddUpdateSchoolStaff
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/staff/:staffId/update"
            element={
              <AddUpdateSchoolStaff
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/session/add"
            element={
              <AddUpdateSession
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/session/update/:sessionId"
            element={
              <AddUpdateSession
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/class/categories"
            element={
              <ClassCategories
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/class/categories/add"
            element={
              <AddUpdateClassCategories
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/class/category/:categoryId/update"
            element={
              <AddUpdateClassCategories
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/sessions"
            element={
              <Sessions
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/terms"
            element={
              <Terms sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/classes"
            element={
              <SchoolClasses
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/class/add"
            element={
              <AddUpdateClasses
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/class/:classId/update"
            element={
              <AddUpdateClasses
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/classes/:classId/profile"
            element={
              <ClassProfile
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/subjects"
            element={
              <Subjects
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/subject/add"
            element={
              <AddUpdateSubject
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/subject/:subjectId/update"
            element={
              <AddUpdateSubject
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
        </Route>
        {/* authentication. Non protected */}
        <Route path="*" element={<NotFound />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
      </Routes>
    </div>
  );
};

export default Root;
