export const yesNo = [
  {
    _id: 0,
    title: "Yes",
  },
  {
    _id: 1,
    title: "No",
  },
];
