import client from "./Client";

const addSession = (schoolId, title, active, token) =>
  client.post(
    "/session/create",
    {
      school_id: schoolId,
      title: title,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const updateSession = (sessionId, schoolId, title, active, token) =>
  client.post(
    "/session/update",
    {
      session_id: sessionId,
      school_id: schoolId,
      title: title,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const toggleSessionVisibility = (schoolId, sessionId, active, token) =>
  client.post(
    "/session/visibility/toggle",
    {
      school_id: schoolId,
      session_id: sessionId,
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getSessions = (schoolId, token) =>
  client.get(`/sessions/${schoolId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getSession = (sessionId, schoolId, token) =>
  client.get(`/${schoolId}/sessions/${sessionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getActiveSession = (schoolId, token) =>
  client.get(`/${schoolId}/sessions/active`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

export default {
  addSession,
  getSession,
  getSessions,
  updateSession,
  toggleSessionVisibility,
  getActiveSession,
};
