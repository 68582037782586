import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import classApi from "../../api/Classes";

// data
import { yesNo } from "../../data/yesNo";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import FullPageLoader from "../../components/loader/FullPageLoader";
import InputField from "../../components/form/InputField";
import { AuthContext } from "../Root/ProtectedRoute";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  title: Yup.string().required(required),
  active: Yup.string().required(required),
  category_id: Yup.string().required(required),
});

const AddUpdateClasses = ({ ...props }) => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    active: "",
    category_id: "",
  });
  const navigate = useNavigate();
  const { user, token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { classId } = useParams();
  const toastTR = useRef(null);
  const [categories, setClassCategories] = useState([]);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    getClassCategories();
    if (classId) {
      getClassDetails();
    }
  }, [classId]);

  const getClassDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSingleClass(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        const classData = response_data.response;
        setInitialValues({
          title: !empty(classData.title) ? classData.title : "",
          active:
            !empty(classData.active) && classData.active === true
              ? "Yes"
              : "No",
          category_id: !empty(classData.categoryId) ? classData.categoryId : "",
        });
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getClassCategories = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClassCategories(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClassCategories([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setClassCategories(data);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const fields = ["title", "active", "category_id"];
      const class_details = {};
      if (!empty(values)) {
        _.forEach(fields, (data) => {
          class_details[data] =
            !empty(data) && !empty(values[data]) ? values[data] : "";
        });
      }
      let response;
      let classAdded = false;
      let classUpdated = false;
      if (!classId) {
        response = await classApi.addClass(
          schoolId,
          ...Object.values(class_details),
          token
        );
        classAdded = true;
      } else {
        response = await classApi.updateClass(
          classId,
          schoolId,
          ...Object.values(class_details),
          token
        );
        classUpdated = true;
      }
      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        if (
          !empty(response_data) &&
          !empty(response_data.statusCodeType) &&
          response_data.statusCodeType === "unauthorized"
        ) {
          return navigate("/");
        }
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }

      navigate("/classes", {
        state: { classAdded, classUpdated },
      });
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader
              title={!empty(classId) ? "Update Class" : "Add Class"}
            />
            <div className="form-container mt-30">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, handleChange }) => (
                  <Form style={{ width: "100%" }}>
                    <div>
                      <em>
                        <strong>Note: *</strong> Please enter the correct
                        details for the classs
                      </em>
                    </div>
                    <div
                      style={{ width: "100%", display: "flex", columnGap: 12 }}
                    >
                      <InputField
                        placeholder="Enter Class Title"
                        name="title"
                        height={50}
                        labelTitle={
                          <>
                            Title <span className="required">*</span>
                          </>
                        }
                      />
                      <SelectField
                        labelTitle={
                          <>
                            Category <span className="required">*</span>
                          </>
                        }
                        placeholder="Class Category"
                        name="category_id"
                        options={categories}
                        height={50}
                        valueKey="id"
                        selectedOption={values.category_id}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div
                      style={{ width: "50%", display: "flex", columnGap: 12 }}
                    >
                      <SelectField
                        labelTitle={
                          <>
                            Activate Class <span className="required">*</span>
                          </>
                        }
                        placeholder="Activate this Class?"
                        name="active"
                        options={yesNo}
                        height={50}
                        valueKey="active"
                        selectedOption={values.active}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonIcon
                        height={45}
                        marginTop={5}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={300}
                        borderColor="#633ccd"
                        buttonText={classId ? "Update Class" : "Add Class"}
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default AddUpdateClasses;
