import React from "react";
import { Skeleton } from "primereact/skeleton";
export default function BarChartLoading() {
  return (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          columnGap: 12,
          alignItems: "flex-end",
        }}
      >
        <Skeleton width="3%" height="200px"></Skeleton>
        <Skeleton width="3%" height="250px"></Skeleton>
        <Skeleton width="3%" height="150px"></Skeleton>
        <Skeleton width="3%" height="300px"></Skeleton>
        <Skeleton width="3%" height="180px"></Skeleton>
        <Skeleton width="3%" height="230px"></Skeleton>
        <Skeleton width="3%" height="210px"></Skeleton>
        <Skeleton width="3%" height="180px"></Skeleton>
        <Skeleton width="3%" height="260px"></Skeleton>
        <Skeleton width="3%" height="150px"></Skeleton>
        <Skeleton width="3%" height="300px"></Skeleton>
        <Skeleton width="3%" height="180px"></Skeleton>
        <Skeleton width="3%" height="230px"></Skeleton>
        <Skeleton width="3%" height="210px"></Skeleton>
        <Skeleton width="3%" height="180px"></Skeleton>
        <Skeleton width="3%" height="260px"></Skeleton>
        <Skeleton width="3%" height="210px"></Skeleton>
        <Skeleton width="3%" height="180px"></Skeleton>
        <Skeleton width="3%" height="260px"></Skeleton>
        <Skeleton width="3%" height="150px"></Skeleton>
        <Skeleton width="3%" height="180px"></Skeleton>
        <Skeleton width="3%" height="260px"></Skeleton>
        <Skeleton width="3%" height="150px"></Skeleton>
        <Skeleton width="3%" height="300px"></Skeleton>
        <Skeleton width="3%" height="180px"></Skeleton>
        <Skeleton width="3%" height="230px"></Skeleton>
      </div>
    </div>
  );
}
