import React from "react";
import { Skeleton } from "primereact/skeleton";
export default function DonutChartLoading() {
  return (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          columnGap: 12,
        }}
      >
        <Skeleton width="40%" height="1rem"></Skeleton>
        <Skeleton width="40%" height="1rem"></Skeleton>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton shape="circle" size="18rem" className="mr-2"></Skeleton>
      </div>
    </div>
  );
}
