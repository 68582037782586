export const NigeriaStatesAndLGA = [
  {
    _id: 1,
    title: "Abia",
    lgas: [
      { _id: 1, title: "Aba North" },
      { _id: 2, title: "Aba South" },
      { _id: 3, title: "Arochukwu" },
      { _id: 4, title: "Bende" },
      { _id: 5, title: "Ikwuano" },
      { _id: 6, title: "Isiala Ngwa North" },
      { _id: 7, title: "Isiala Ngwa South" },
      { _id: 8, title: "Isuikwuato" },
      { _id: 9, title: "Obi Ngwa" },
      { _id: 10, title: "Ohafia" },
      { _id: 11, title: "Osisioma" },
      { _id: 12, title: "Ugwunagbo" },
      { _id: 13, title: "Ukwa East" },
      { _id: 14, title: "Ukwa West" },
      { _id: 15, title: "Umuahia North" },
      { _id: 16, title: "Umuahia South" },
      { _id: 17, title: "Umu Nneochi" },
    ],
  },
  {
    _id: 2,
    title: "Adamawa",
    lgas: [
      { _id: 1, title: "Demsa" },
      { _id: 2, title: "Fufure" },
      { _id: 3, title: "Ganye" },
      { _id: 4, title: "Girei" },
      { _id: 5, title: "Gombi" },
      { _id: 6, title: "Guyuk" },
      { _id: 7, title: "Hong" },
      { _id: 8, title: "Jada" },
      { _id: 9, title: "Lamurde" },
      { _id: 10, title: "Madagali" },
      { _id: 11, title: "Maiha" },
      { _id: 12, title: "Mayo Belwa" },
      { _id: 13, title: "Michika" },
      { _id: 14, title: "Mubi North" },
      { _id: 15, title: "Mubi South" },
      { _id: 16, title: "Numan" },
      { _id: 17, title: "Shelleng" },
      { _id: 18, title: "Song" },
      { _id: 19, title: "Toungo" },
      { _id: 20, title: "Yola North" },
      { _id: 21, title: "Yola South" },
    ],
  },
  {
    _id: 3,
    title: "Akwa Ibom",
    lgas: [
      { _id: 1, title: "Abak" },
      { _id: 2, title: "Eastern Obolo" },
      { _id: 3, title: "Eket" },
      { _id: 4, title: "Esit Eket" },
      { _id: 5, title: "Essien Udim" },
      { _id: 6, title: "Etim Ekpo" },
      { _id: 7, title: "Etinan" },
      { _id: 8, title: "Ibeno" },
      { _id: 9, title: "Ibesikpo Asutan" },
      { _id: 10, title: "Ibiono-Ibom" },
      { _id: 11, title: "Ika" },
      { _id: 12, title: "Ikono" },
      { _id: 13, title: "Ikot Abasi" },
      { _id: 14, title: "Ikot Ekpene" },
      { _id: 15, title: "Ini" },
      { _id: 16, title: "Itu" },
      { _id: 17, title: "Mbo" },
      { _id: 18, title: "Mkpat-Enin" },
      { _id: 19, title: "Nsit-Atai" },
      { _id: 20, title: "Nsit-Ibom" },
      { _id: 21, title: "Nsit-Ubium" },
      { _id: 22, title: "Obot Akara" },
      { _id: 23, title: "Okobo" },
      { _id: 24, title: "Onna" },
      { _id: 25, title: "Oron" },
      { _id: 26, title: "Oruk Anam" },
      { _id: 27, title: "Udung-Uko" },
      { _id: 28, title: "Ukanafun" },
      { _id: 29, title: "Uruan" },
      { _id: 30, title: "Urue-Offong/Oruko" },
      { _id: 31, title: "Uyo" },
    ],
  },
  {
    _id: 4,
    title: "Anambra",
    lgas: [
      { _id: 1, title: "Aguata" },
      { _id: 2, title: "Anambra East" },
      { _id: 3, title: "Anambra West" },
      { _id: 4, title: "Anaocha" },
      { _id: 5, title: "Awka North" },
      { _id: 6, title: "Awka South" },
      { _id: 7, title: "Ayamelum" },
      { _id: 8, title: "Dunukofia" },
      { _id: 9, title: "Ekwusigo" },
      { _id: 10, title: "Idemili North" },
      { _id: 11, title: "Idemili South" },
      { _id: 12, title: "Ihiala" },
      { _id: 13, title: "Njikoka" },
      { _id: 14, title: "Nnewi North" },
      { _id: 15, title: "Nnewi South" },
      { _id: 16, title: "Ogbaru" },
      { _id: 17, title: "Onitsha North" },
      { _id: 18, title: "Onitsha South" },
      { _id: 19, title: "Orumba North" },
      { _id: 20, title: "Orumba South" },
      { _id: 21, title: "Oyi" },
    ],
  },
  {
    _id: 5,
    title: "Bauchi",
    lgas: [
      { _id: 1, title: "Alkaleri" },
      { _id: 2, title: "Bauchi" },
      { _id: 3, title: "Bogoro" },
      { _id: 4, title: "Damban" },
      { _id: 5, title: "Darazo" },
      { _id: 6, title: "Dass" },
      { _id: 7, title: "Gamawa" },
      { _id: 8, title: "Ganjuwa" },
      { _id: 9, title: "Giade" },
      { _id: 10, title: "Itas/Gadau" },
      { _id: 11, title: "Jama'are" },
      { _id: 12, title: "Katagum" },
      { _id: 13, title: "Kirfi" },
      { _id: 14, title: "Misau" },
      { _id: 15, title: "Ningi" },
      { _id: 16, title: "Shira" },
      { _id: 17, title: "Tafawa Balewa" },
      { _id: 18, title: "Toro" },
      { _id: 19, title: "Warji" },
      { _id: 20, title: "Zaki" },
    ],
  },
  {
    _id: 6,
    title: "Bayelsa",
    lgas: [
      { _id: 1, title: "Brass" },
      { _id: 2, title: "Ekeremor" },
      { _id: 3, title: "Kolokuma/Opokuma" },
      { _id: 4, title: "Nembe" },
      { _id: 5, title: "Ogbia" },
      { _id: 6, title: "Sagbama" },
      { _id: 7, title: "Southern Ijaw" },
      { _id: 8, title: "Yenagoa" },
    ],
  },
  {
    _id: 7,
    title: "Benue",
    lgas: [
      { _id: 1, title: "Ado" },
      { _id: 2, title: "Agatu" },
      { _id: 3, title: "Apa" },
      { _id: 4, title: "Buruku" },
      { _id: 5, title: "Gboko" },
      { _id: 6, title: "Guma" },
      { _id: 7, title: "Gwer East" },
      { _id: 8, title: "Gwer West" },
      { _id: 9, title: "Katsina-Ala" },
      { _id: 10, title: "Konshisha" },
      { _id: 11, title: "Kwande" },
      { _id: 12, title: "Logo" },
      { _id: 13, title: "Makurdi" },
      { _id: 14, title: "Obi" },
      { _id: 15, title: "Ogbadibo" },
      { _id: 16, title: "Ohimini" },
      { _id: 17, title: "Oju" },
      { _id: 18, title: "Okpokwu" },
      { _id: 19, title: "Otukpo" },
      { _id: 20, title: "Tarka" },
      { _id: 21, title: "Ukum" },
      { _id: 22, title: "Ushongo" },
      { _id: 23, title: "Vandeikya" },
    ],
  },
  {
    _id: 8,
    title: "Borno",
    lgas: [
      { _id: 1, title: "Abadam" },
      { _id: 2, title: "Askira/Uba" },
      { _id: 3, title: "Bama" },
      { _id: 4, title: "Bayo" },
      { _id: 5, title: "Biu" },
      { _id: 6, title: "Chibok" },
      { _id: 7, title: "Damboa" },
      { _id: 8, title: "Dikwa" },
      { _id: 9, title: "Gubio" },
      { _id: 10, title: "Guzamala" },
      { _id: 11, title: "Gwoza" },
      { _id: 12, title: "Hawul" },
      { _id: 13, title: "Jere" },
      { _id: 14, title: "Kaga" },
      { _id: 15, title: "Kala/Balge" },
      { _id: 16, title: "Konduga" },
      { _id: 17, title: "Kukawa" },
      { _id: 18, title: "Kwaya Kusar" },
      { _id: 19, title: "Mafa" },
      { _id: 20, title: "Magumeri" },
      { _id: 21, title: "Maiduguri" },
      { _id: 22, title: "Marte" },
      { _id: 23, title: "Mobbar" },
      { _id: 24, title: "Monguno" },
      { _id: 25, title: "Ngala" },
      { _id: 26, title: "Nganzai" },
      { _id: 27, title: "Shani" },
    ],
  },
  {
    _id: 9,
    title: "Cross River",
    lgas: [
      { _id: 1, title: "Abi" },
      { _id: 2, title: "Akamkpa" },
      { _id: 3, title: "Akpabuyo" },
      { _id: 4, title: "Bakassi" },
      { _id: 5, title: "Bekwarra" },
      { _id: 6, title: "Biase" },
      { _id: 7, title: "Boki" },
      { _id: 8, title: "Calabar Municipal" },
      { _id: 9, title: "Calabar South" },
      { _id: 10, title: "Etung" },
      { _id: 11, title: "Ikom" },
      { _id: 12, title: "Obanliku" },
      { _id: 13, title: "Obubra" },
      { _id: 14, title: "Obudu" },
      { _id: 15, title: "Odukpani" },
      { _id: 16, title: "Ogoja" },
      { _id: 17, title: "Yakurr" },
      { _id: 18, title: "Yala" },
    ],
  },
  {
    _id: 10,
    title: "Delta",
    lgas: [
      { _id: 1, title: "Aniocha North" },
      { _id: 2, title: "Aniocha South" },
      { _id: 3, title: "Bomadi" },
      { _id: 4, title: "Burutu" },
      { _id: 5, title: "Ethiope East" },
      { _id: 6, title: "Ethiope West" },
      { _id: 7, title: "Ika North East" },
      { _id: 8, title: "Ika South" },
      { _id: 9, title: "Isoko North" },
      { _id: 10, title: "Isoko South" },
      { _id: 11, title: "Ndokwa East" },
      { _id: 12, title: "Ndokwa West" },
      { _id: 13, title: "Okpe" },
      { _id: 14, title: "Oshimili North" },
      { _id: 15, title: "Oshimili South" },
      { _id: 16, title: "Patani" },
      { _id: 17, title: "Sapele" },
      { _id: 18, title: "Udu" },
      { _id: 19, title: "Ughelli North" },
      { _id: 20, title: "Ughelli South" },
      { _id: 21, title: "Ukwuani" },
      { _id: 22, title: "Uvwie" },
      { _id: 23, title: "Warri North" },
      { _id: 24, title: "Warri South" },
      { _id: 25, title: "Warri South West" },
    ],
  },
  {
    _id: 11,
    title: "Ebonyi",
    lgas: [
      { _id: 1, title: "Abakaliki" },
      { _id: 2, title: "Afikpo North" },
      { _id: 3, title: "Afikpo South" },
      { _id: 4, title: "Ebonyi" },
      { _id: 5, title: "Ezza North" },
      { _id: 6, title: "Ezza South" },
      { _id: 7, title: "Ikwo" },
      { _id: 8, title: "Ishielu" },
      { _id: 9, title: "Ivo" },
      { _id: 10, title: "Izzi" },
      { _id: 11, title: "Ohaukwu" },
      { _id: 12, title: "Onicha" },
    ],
  },
  {
    _id: 12,
    title: "Edo",
    lgas: [
      { _id: 1, title: "Akoko-Edo" },
      { _id: 2, title: "Egor" },
      { _id: 3, title: "Esan Central" },
      { _id: 4, title: "Esan North-East" },
      { _id: 5, title: "Esan South-East" },
      { _id: 6, title: "Esan West" },
      { _id: 7, title: "Etsako Central" },
      { _id: 8, title: "Etsako East" },
      { _id: 9, title: "Etsako West" },
      { _id: 10, title: "Igueben" },
      { _id: 11, title: "Ikpoba Okha" },
      { _id: 12, title: "Oredo" },
      { _id: 13, title: "Orhionmwon" },
      { _id: 14, title: "Ovia North-East" },
      { _id: 15, title: "Ovia South-West" },
      { _id: 16, title: "Owan East" },
      { _id: 17, title: "Owan West" },
      { _id: 18, title: "Uhunmwonde" },
    ],
  },
  {
    _id: 13,
    title: "Ekiti",
    lgas: [
      { _id: 1, title: "Ado Ekiti" },
      { _id: 2, title: "Efon" },
      { _id: 3, title: "Ekiti East" },
      { _id: 4, title: "Ekiti South-West" },
      { _id: 5, title: "Ekiti West" },
      { _id: 6, title: "Emure" },
      { _id: 7, title: "Gbonyin" },
      { _id: 8, title: "Ido Osi" },
      { _id: 9, title: "Ijero" },
      { _id: 10, title: "Ikere" },
      { _id: 11, title: "Ikole" },
      { _id: 12, title: "Ilejemeje" },
      { _id: 13, title: "Irepodun/Ifelodun" },
      { _id: 14, title: "Ise/Orun" },
      { _id: 15, title: "Moba" },
      { _id: 16, title: "Oye" },
    ],
  },
  {
    _id: 14,
    title: "Enugu",
    lgas: [
      { _id: 1, title: "Aninri" },
      { _id: 2, title: "Awgu" },
      { _id: 3, title: "Enugu East" },
      { _id: 4, title: "Enugu North" },
      { _id: 5, title: "Enugu South" },
      { _id: 6, title: "Ezeagu" },
      { _id: 7, title: "Igbo Etiti" },
      { _id: 8, title: "Igbo Eze North" },
      { _id: 9, title: "Igbo Eze South" },
      { _id: 10, title: "Isi Uzo" },
      { _id: 11, title: "Nkanu East" },
      { _id: 12, title: "Nkanu West" },
      { _id: 13, title: "Nsukka" },
      { _id: 14, title: "Oji River" },
      { _id: 15, title: "Udenu" },
      { _id: 16, title: "Udi" },
      { _id: 17, title: "Uzo-Uwani" },
    ],
  },
  {
    _id: 15,
    title: "Gombe",
    lgas: [
      { _id: 1, title: "Akko" },
      { _id: 2, title: "Balanga" },
      { _id: 3, title: "Billiri" },
      { _id: 4, title: "Dukku" },
      { _id: 5, title: "Funakaye" },
      { _id: 6, title: "Gombe" },
      { _id: 7, title: "Kaltungo" },
      { _id: 8, title: "Kwami" },
      { _id: 9, title: "Nafada" },
      { _id: 10, title: "Shongom" },
      { _id: 11, title: "Yamaltu/Deba" },
    ],
  },
  {
    _id: 16,
    title: "Imo",
    lgas: [
      { _id: 1, title: "Aboh Mbaise" },
      { _id: 2, title: "Ahiazu Mbaise" },
      { _id: 3, title: "Ehime Mbano" },
      { _id: 4, title: "Ezinihitte" },
      { _id: 5, title: "Ideato North" },
      { _id: 6, title: "Ideato South" },
      { _id: 7, title: "Ihitte/Uboma" },
      { _id: 8, title: "Ikeduru" },
      { _id: 9, title: "Isiala Mbano" },
      { _id: 10, title: "Isu" },
      { _id: 11, title: "Mbaitoli" },
      { _id: 12, title: "Ngor Okpala" },
      { _id: 13, title: "Njaba" },
      { _id: 14, title: "Nkwerre" },
      { _id: 15, title: "Nwangele" },
      { _id: 16, title: "Obowo" },
      { _id: 17, title: "Oguta" },
      { _id: 18, title: "Ohaji/Egbema" },
      { _id: 19, title: "Okigwe" },
      { _id: 20, title: "Onuimo" },
      { _id: 21, title: "Orlu" },
      { _id: 22, title: "Orsu" },
      { _id: 23, title: "Oru East" },
      { _id: 24, title: "Oru West" },
      { _id: 25, title: "Owerri Municipal" },
      { _id: 26, title: "Owerri North" },
      { _id: 27, title: "Owerri West" },
    ],
  },
  {
    _id: 17,
    title: "Jigawa",
    lgas: [
      { _id: 1, title: "Auyo" },
      { _id: 2, title: "Babura" },
      { _id: 3, title: "Biriniwa" },
      { _id: 4, title: "Birnin Kudu" },
      { _id: 5, title: "Buji" },
      { _id: 6, title: "Dutse" },
      { _id: 7, title: "Gagarawa" },
      { _id: 8, title: "Garki" },
      { _id: 9, title: "Gumel" },
      { _id: 10, title: "Guri" },
      { _id: 11, title: "Gwaram" },
      { _id: 12, title: "Gwiwa" },
      { _id: 13, title: "Hadejia" },
      { _id: 14, title: "Jahun" },
      { _id: 15, title: "Kafin Hausa" },
      { _id: 16, title: "Kaugama" },
      { _id: 17, title: "Kazaure" },
      { _id: 18, title: "Kiri Kasama" },
      { _id: 19, title: "Kiyawa" },
      { _id: 20, title: "Maigatari" },
      { _id: 21, title: "Malam Madori" },
      { _id: 22, title: "Miga" },
      { _id: 23, title: "Ringim" },
      { _id: 24, title: "Roni" },
      { _id: 25, title: "Sule Tankarkar" },
      { _id: 26, title: "Taura" },
      { _id: 27, title: "Yankwashi" },
    ],
  },
  {
    _id: 18,
    title: "Kaduna",
    lgas: [
      { _id: 1, title: "Birnin Gwari" },
      { _id: 2, title: "Chikun" },
      { _id: 3, title: "Giwa" },
      { _id: 4, title: "Igabi" },
      { _id: 5, title: "Ikara" },
      { _id: 6, title: "Jaba" },
      { _id: 7, title: "Jema'a" },
      { _id: 8, title: "Kachia" },
      { _id: 9, title: "Kaduna North" },
      { _id: 10, title: "Kaduna South" },
      { _id: 11, title: "Kagarko" },
      { _id: 12, title: "Kajuru" },
      { _id: 13, title: "Kaura" },
      { _id: 14, title: "Kauru" },
      { _id: 15, title: "Kubau" },
      { _id: 16, title: "Kudan" },
      { _id: 17, title: "Lere" },
      { _id: 18, title: "Makarfi" },
      { _id: 19, title: "Sabon Gari" },
      { _id: 20, title: "Sanga" },
      { _id: 21, title: "Soba" },
      { _id: 22, title: "Zangon Kataf" },
      { _id: 23, title: "Zaria" },
    ],
  },
  {
    _id: 19,
    title: "Kano",
    lgas: [
      { _id: 1, title: "Ajingi" },
      { _id: 2, title: "Albasu" },
      { _id: 3, title: "Bagwai" },
      { _id: 4, title: "Bebeji" },
      { _id: 5, title: "Bichi" },
      { _id: 6, title: "Bunkure" },
      { _id: 7, title: "Dala" },
      { _id: 8, title: "Dambatta" },
      { _id: 9, title: "Dawakin Kudu" },
      { _id: 10, title: "Dawakin Tofa" },
      { _id: 11, title: "Doguwa" },
      { _id: 12, title: "Fagge" },
      { _id: 13, title: "Gabasawa" },
      { _id: 14, title: "Garko" },
      { _id: 15, title: "Garun Mallam" },
      { _id: 16, title: "Gaya" },
      { _id: 17, title: "Gezawa" },
      { _id: 18, title: "Gwale" },
      { _id: 19, title: "Gwarzo" },
      { _id: 20, title: "Kabo" },
      { _id: 21, title: "Kano Municipal" },
      { _id: 22, title: "Karaye" },
      { _id: 23, title: "Kibiya" },
      { _id: 24, title: "Kiru" },
      { _id: 25, title: "Kumbotso" },
      { _id: 26, title: "Kunchi" },
      { _id: 27, title: "Kura" },
      { _id: 28, title: "Madobi" },
      { _id: 29, title: "Makoda" },
      { _id: 30, title: "Minjibir" },
      { _id: 31, title: "Nasarawa" },
      { _id: 32, title: "Rano" },
      { _id: 33, title: "Rimin Gado" },
      { _id: 34, title: "Rogo" },
      { _id: 35, title: "Shanono" },
      { _id: 36, title: "Sumaila" },
      { _id: 37, title: "Takai" },
      { _id: 38, title: "Tarauni" },
      { _id: 39, title: "Tofa" },
      { _id: 40, title: "Tsanyawa" },
      { _id: 41, title: "Tudun Wada" },
      { _id: 42, title: "Ungogo" },
      { _id: 43, title: "Warawa" },
      { _id: 44, title: "Wudil" },
    ],
  },
  {
    _id: 20,
    title: "Katsina",
    lgas: [
      { _id: 1, title: "Bakori" },
      { _id: 2, title: "Batagarawa" },
      { _id: 3, title: "Batsari" },
      { _id: 4, title: "Baure" },
      { _id: 5, title: "Bindawa" },
      { _id: 6, title: "Charanchi" },
      { _id: 7, title: "Dandume" },
      { _id: 8, title: "Danja" },
      { _id: 9, title: "Dan Musa" },
      { _id: 10, title: "Daura" },
      { _id: 11, title: "Dutsi" },
      { _id: 12, title: "Dutsin Ma" },
      { _id: 13, title: "Faskari" },
      { _id: 14, title: "Funtua" },
      { _id: 15, title: "Ingawa" },
      { _id: 16, title: "Jibia" },
      { _id: 17, title: "Kafur" },
      { _id: 18, title: "Kaita" },
      { _id: 19, title: "Kankara" },
      { _id: 20, title: "Kankia" },
      { _id: 21, title: "Katsina" },
      { _id: 22, title: "Kurfi" },
      { _id: 23, title: "Kusada" },
      { _id: 24, title: "Mai'Adua" },
      { _id: 25, title: "Malumfashi" },
      { _id: 26, title: "Mani" },
      { _id: 27, title: "Mashi" },
      { _id: 28, title: "Matazu" },
      { _id: 29, title: "Musawa" },
      { _id: 30, title: "Rimi" },
      { _id: 31, title: "Sabuwa" },
      { _id: 32, title: "Safana" },
      { _id: 33, title: "Sandamu" },
      { _id: 34, title: "Zango" },
    ],
  },

  {
    _id: 21,
    title: "Kebbi",
    lgas: [
      { _id: 1, title: "Aleiro" },
      { _id: 2, title: "Arewa Dandi" },
      { _id: 3, title: "Argungu" },
      { _id: 4, title: "Augie" },
      { _id: 5, title: "Bagudo" },
      { _id: 6, title: "Birnin Kebbi" },
      { _id: 7, title: "Bunza" },
      { _id: 8, title: "Dandi" },
      { _id: 9, title: "Fakai" },
      { _id: 10, title: "Gwandu" },
      { _id: 11, title: "Jega" },
      { _id: 12, title: "Kalgo" },
      { _id: 13, title: "Koko/Besse" },
      { _id: 14, title: "Maiyama" },
      { _id: 15, title: "Ngaski" },
      { _id: 16, title: "Sakaba" },
      { _id: 17, title: "Shanga" },
      { _id: 18, title: "Suru" },
      { _id: 19, title: "Danko-Wasagu" },
      { _id: 20, title: "Yauri" },
      { _id: 21, title: "Zuru" },
    ],
  },
  {
    _id: 22,
    title: "Kogi",
    lgas: [
      { _id: 1, title: "Adavi" },
      { _id: 2, title: "Ajaokuta" },
      { _id: 3, title: "Ankpa" },
      { _id: 4, title: "Bassa" },
      { _id: 5, title: "Dekina" },
      { _id: 6, title: "Ibaji" },
      { _id: 7, title: "Idah" },
      { _id: 8, title: "Igalamela-Odolu" },
      { _id: 9, title: "Ijumu" },
      { _id: 10, title: "Kabba/Bunu" },
      { _id: 11, title: "Kogi" },
      { _id: 12, title: "Lokoja" },
      { _id: 13, title: "Mopa-Muro" },
      { _id: 14, title: "Ofu" },
      { _id: 15, title: "Ogori/Magongo" },
      { _id: 16, title: "Okehi" },
      { _id: 17, title: "Okene" },
      { _id: 18, title: "Olamaboro" },
      { _id: 19, title: "Omala" },
      { _id: 20, title: "Yagba East" },
      { _id: 21, title: "Yagba West" },
    ],
  },
  {
    _id: 23,
    title: "Kwara",
    lgas: [
      { _id: 1, title: "Asa" },
      { _id: 2, title: "Baruten" },
      { _id: 3, title: "Edu" },
      { _id: 4, title: "Ekiti" },
      { _id: 5, title: "Ifelodun" },
      { _id: 6, title: "Ilorin East" },
      { _id: 7, title: "Ilorin South" },
      { _id: 8, title: "Ilorin West" },
      { _id: 9, title: "Irepodun" },
      { _id: 10, title: "Isin" },
      { _id: 11, title: "Kaiama" },
      { _id: 12, title: "Moro" },
      { _id: 13, title: "Offa" },
      { _id: 14, title: "Oke Ero" },
      { _id: 15, title: "Oyun" },
      { _id: 16, title: "Pategi" },
    ],
  },
  {
    _id: 24,
    title: "Lagos",
    lgas: [
      { _id: 1, title: "Agege" },
      { _id: 2, title: "Ajeromi-Ifelodun" },
      { _id: 3, title: "Alimosho" },
      { _id: 4, title: "Amuwo-Odofin" },
      { _id: 5, title: "Apapa" },
      { _id: 6, title: "Badagry" },
      { _id: 7, title: "Epe" },
      { _id: 8, title: "Eti Osa" },
      { _id: 9, title: "Ibeju-Lekki" },
      { _id: 10, title: "Ifako-Ijaiye" },
      { _id: 11, title: "Ikeja" },
      { _id: 12, title: "Ikorodu" },
      { _id: 13, title: "Kosofe" },
      { _id: 14, title: "Lagos Island" },
      { _id: 15, title: "Lagos Mainland" },
      { _id: 16, title: "Mushin" },
      { _id: 17, title: "Ojo" },
      { _id: 18, title: "Oshodi-Isolo" },
      { _id: 19, title: "Shomolu" },
      { _id: 20, title: "Surulere" },
    ],
  },
  {
    _id: 25,
    title: "Nasarawa",
    lgas: [
      { _id: 1, title: "Akwanga" },
      { _id: 2, title: "Awe" },
      { _id: 3, title: "Doma" },
      { _id: 4, title: "Karu" },
      { _id: 5, title: "Keana" },
      { _id: 6, title: "Keffi" },
      { _id: 7, title: "Kokona" },
      { _id: 8, title: "Lafia" },
      { _id: 9, title: "Nasarawa" },
      { _id: 10, title: "Nasarawa Egon" },
      { _id: 11, title: "Obi" },
      { _id: 12, title: "Toto" },
      { _id: 13, title: "Wamba" },
    ],
  },
  {
    _id: 26,
    title: "Niger",
    lgas: [
      { _id: 1, title: "Agaie" },
      { _id: 2, title: "Agwara" },
      { _id: 3, title: "Bida" },
      { _id: 4, title: "Borgu" },
      { _id: 5, title: "Bosso" },
      { _id: 6, title: "Chanchaga" },
      { _id: 7, title: "Edati" },
      { _id: 8, title: "Gbako" },
      { _id: 9, title: "Gurara" },
      { _id: 10, title: "Katcha" },
      { _id: 11, title: "Kontagora" },
      { _id: 12, title: "Lapai" },
      { _id: 13, title: "Lavun" },
      { _id: 14, title: "Magama" },
      { _id: 15, title: "Mariga" },
      { _id: 16, title: "Mashegu" },
      { _id: 17, title: "Mokwa" },
      { _id: 18, title: "Muya" },
      { _id: 19, title: "Paikoro" },
      { _id: 20, title: "Rafi" },
      { _id: 21, title: "Rijau" },
      { _id: 22, title: "Shiroro" },
      { _id: 23, title: "Suleja" },
      { _id: 24, title: "Tafa" },
      { _id: 25, title: "Wushishi" },
    ],
  },
  {
    _id: 27,
    title: "Ogun",
    lgas: [
      { _id: 1, title: "Abeokuta North" },
      { _id: 2, title: "Abeokuta South" },
      { _id: 3, title: "Ado-Odo/Ota" },
      { _id: 4, title: "Egbado North" },
      { _id: 5, title: "Egbado South" },
      { _id: 6, title: "Ewekoro" },
      { _id: 7, title: "Ifo" },
      { _id: 8, title: "Ijebu East" },
      { _id: 9, title: "Ijebu North" },
      { _id: 10, title: "Ijebu North East" },
      { _id: 11, title: "Ijebu Ode" },
      { _id: 12, title: "Ikenne" },
      { _id: 13, title: "Imeko Afon" },
      { _id: 14, title: "Ipokia" },
      { _id: 15, title: "Obafemi-Owode" },
      { _id: 16, title: "Odeda" },
      { _id: 17, title: "Odogbolu" },
      { _id: 18, title: "Ogun Waterside" },
      { _id: 19, title: "Remo North" },
      { _id: 20, title: "Shagamu" },
    ],
  },
  {
    _id: 28,
    title: "Ondo",
    lgas: [
      { _id: 1, title: "Akoko North-East" },
      { _id: 2, title: "Akoko North-West" },
      { _id: 3, title: "Akoko South-West" },
      { _id: 4, title: "Akoko South-East" },
      { _id: 5, title: "Akure North" },
      { _id: 6, title: "Akure South" },
      { _id: 7, title: "Ese Odo" },
      { _id: 8, title: "Idanre" },
      { _id: 9, title: "Ifedore" },
      { _id: 10, title: "Ilaje" },
      { _id: 11, title: "Ile Oluji/Okeigbo" },
      { _id: 12, title: "Irele" },
      { _id: 13, title: "Odigbo" },
      { _id: 14, title: "Okitipupa" },
      { _id: 15, title: "Ondo East" },
      { _id: 16, title: "Ondo West" },
      { _id: 17, title: "Ose" },
      { _id: 18, title: "Owo" },
    ],
  },
  {
    _id: 29,
    title: "Osun",
    lgas: [
      { _id: 1, title: "Aiyedaade" },
      { _id: 2, title: "Aiyedire" },
      { _id: 3, title: "Atakunmosa East" },
      { _id: 4, title: "Atakunmosa West" },
      { _id: 5, title: "Boluwaduro" },
      { _id: 6, title: "Boripe" },
      { _id: 7, title: "Ede North" },
      { _id: 8, title: "Ede South" },
      { _id: 9, title: "Egbedore" },
      { _id: 10, title: "Ejigbo" },
      { _id: 11, title: "Ife Central" },
      { _id: 12, title: "Ife East" },
      { _id: 13, title: "Ife North" },
      { _id: 14, title: "Ife South" },
      { _id: 15, title: "Ifedayo" },
      { _id: 16, title: "Ifelodun" },
      { _id: 17, title: "Ila" },
      { _id: 18, title: "Ilesa East" },
      { _id: 19, title: "Ilesa West" },
      { _id: 20, title: "Irepodun" },
      { _id: 21, title: "Irewole" },
      { _id: 22, title: "Isokan" },
      { _id: 23, title: "Iwo" },
      { _id: 24, title: "Obokun" },
      { _id: 25, title: "Odo Otin" },
      { _id: 26, title: "Ola Oluwa" },
      { _id: 27, title: "Olorunda" },
      { _id: 28, title: "Oriade" },
      { _id: 29, title: "Orolu" },
      { _id: 30, title: "Osogbo" },
    ],
  },
  {
    _id: 30,
    title: "Oyo",
    lgas: [
      { _id: 1, title: "Afijio" },
      { _id: 2, title: "Akinyele" },
      { _id: 3, title: "Atiba" },
      { _id: 4, title: "Atisbo" },
      { _id: 5, title: "Egbeda" },
      { _id: 6, title: "Ibadan North" },
      { _id: 7, title: "Ibadan North-East" },
      { _id: 8, title: "Ibadan North-West" },
      { _id: 9, title: "Ibadan South-East" },
      { _id: 10, title: "Ibadan South-West" },
      { _id: 11, title: "Ibarapa Central" },
      { _id: 12, title: "Ibarapa East" },
      { _id: 13, title: "Ibarapa North" },
      { _id: 14, title: "Ido" },
      { _id: 15, title: "Irepo" },
      { _id: 16, title: "Iseyin" },
      { _id: 17, title: "Itesiwaju" },
      { _id: 18, title: "Iwajowa" },
      { _id: 19, title: "Kajola" },
      { _id: 20, title: "Lagelu" },
      { _id: 21, title: "Ogbomosho North" },
      { _id: 22, title: "Ogbomosho South" },
      { _id: 23, title: "Ogo Oluwa" },
      { _id: 24, title: "Olorunsogo" },
      { _id: 25, title: "Oluyole" },
      { _id: 26, title: "Ona Ara" },
      { _id: 27, title: "Orelope" },
      { _id: 28, title: "Ori Ire" },
      { _id: 29, title: "Oyo East" },
      { _id: 30, title: "Oyo West" },
      { _id: 31, title: "Saki East" },
      { _id: 32, title: "Saki West" },
      { _id: 33, title: "Surulere" },
    ],
  },
  {
    _id: 31,
    title: "Plateau",
    lgas: [
      { _id: 1, title: "Bokkos" },
      { _id: 2, title: "Barkin Ladi" },
      { _id: 3, title: "Bassa" },
      { _id: 4, title: "Jos East" },
      { _id: 5, title: "Jos North" },
      { _id: 6, title: "Jos South" },
      { _id: 7, title: "Kanam" },
      { _id: 8, title: "Kanke" },
      { _id: 9, title: "Langtang South" },
      { _id: 10, title: "Langtang North" },
      { _id: 11, title: "Mangu" },
      { _id: 12, title: "Mikang" },
      { _id: 13, title: "Pankshin" },
      { _id: 14, title: "Qua'an Pan" },
      { _id: 15, title: "Riyom" },
      { _id: 16, title: "Shendam" },
      { _id: 17, title: "Wase" },
    ],
  },
  {
    _id: 32,
    title: "Rivers",
    lgas: [
      { _id: 1, title: "Abua/Odual" },
      { _id: 2, title: "Ahoada East" },
      { _id: 3, title: "Ahoada West" },
      { _id: 4, title: "Akuku-Toru" },
      { _id: 5, title: "Andoni" },
      { _id: 6, title: "Asari-Toru" },
      { _id: 7, title: "Bonny" },
      { _id: 8, title: "Degema" },
      { _id: 9, title: "Eleme" },
      { _id: 10, title: "Emohua" },
      { _id: 11, title: "Etche" },
      { _id: 12, title: "Gokana" },
      { _id: 13, title: "Ikwerre" },
      { _id: 14, title: "Khana" },
      { _id: 15, title: "Obio/Akpor" },
      { _id: 16, title: "Ogba/Egbema/Ndoni" },
      { _id: 17, title: "Ogu/Bolo" },
      { _id: 18, title: "Okrika" },
      { _id: 19, title: "Omuma" },
      { _id: 20, title: "Opobo/Nkoro" },
      { _id: 21, title: "Oyigbo" },
      { _id: 22, title: "Port Harcourt" },
      { _id: 23, title: "Tai" },
    ],
  },
  {
    _id: 33,
    title: "Sokoto",
    lgas: [
      { _id: 1, title: "Binji" },
      { _id: 2, title: "Bodinga" },
      { _id: 3, title: "Dange Shuni" },
      { _id: 4, title: "Gada" },
      { _id: 5, title: "Goronyo" },
      { _id: 6, title: "Gudu" },
      { _id: 7, title: "Gwadabawa" },
      { _id: 8, title: "Illela" },
      { _id: 9, title: "Isa" },
      { _id: 10, title: "Kebbe" },
      { _id: 11, title: "Kware" },
      { _id: 12, title: "Rabah" },
      { _id: 13, title: "Sabon Birni" },
      { _id: 14, title: "Shagari" },
      { _id: 15, title: "Silame" },
      { _id: 16, title: "Sokoto North" },
      { _id: 17, title: "Sokoto South" },
      { _id: 18, title: "Tambuwal" },
      { _id: 19, title: "Tangaza" },
      { _id: 20, title: "Tureta" },
      { _id: 21, title: "Wamako" },
      { _id: 22, title: "Wurno" },
      { _id: 23, title: "Yabo" },
    ],
  },
  {
    _id: 34,
    title: "Taraba",
    lgas: [
      { _id: 1, title: "Ardo Kola" },
      { _id: 2, title: "Bali" },
      { _id: 3, title: "Donga" },
      { _id: 4, title: "Gashaka" },
      { _id: 5, title: "Gassol" },
      { _id: 6, title: "Ibi" },
      { _id: 7, title: "Jalingo" },
      { _id: 8, title: "Karim Lamido" },
      { _id: 9, title: "Kumi" },
      { _id: 10, title: "Lau" },
      { _id: 11, title: "Sardauna" },
      { _id: 12, title: "Takum" },
      { _id: 13, title: "Ussa" },
      { _id: 14, title: "Wukari" },
      { _id: 15, title: "Yorro" },
      { _id: 16, title: "Zing" },
    ],
  },
  {
    _id: 35,
    title: "Yobe",
    lgas: [
      { _id: 1, title: "Bade" },
      { _id: 2, title: "Bursari" },
      { _id: 3, title: "Damaturu" },
      { _id: 4, title: "Fika" },
      { _id: 5, title: "Fune" },
      { _id: 6, title: "Geidam" },
      { _id: 7, title: "Gujba" },
      { _id: 8, title: "Gulani" },
      { _id: 9, title: "Jakusko" },
      { _id: 10, title: "Karasuwa" },
      { _id: 11, title: "Machina" },
      { _id: 12, title: "Nangere" },
      { _id: 13, title: "Nguru" },
      { _id: 14, title: "Potiskum" },
      { _id: 15, title: "Tarmuwa" },
      { _id: 16, title: "Yunusari" },
      { _id: 17, title: "Yusufari" },
    ],
  },
  {
    _id: 36,
    title: "Zamfara",
    lgas: [
      { _id: 1, title: "Anka" },
      { _id: 2, title: "Bakura" },
      { _id: 3, title: "Birnin Magaji/Kiyaw" },
      { _id: 4, title: "Bukkuyum" },
      { _id: 5, title: "Bungudu" },
      { _id: 6, title: "Gummi" },
      { _id: 7, title: "Gusau" },
      { _id: 8, title: "Kaura Namoda" },
      { _id: 9, title: "Maradun" },
      { _id: 10, title: "Maru" },
      { _id: 11, title: "Shinkafi" },
      { _id: 12, title: "Talata Mafara" },
      { _id: 13, title: "Chafe" },
      { _id: 14, title: "Zurmi" },
    ],
  },
  {
    _id: 37,
    title: "Abuja (F.C.T)",
    lgas: [
      { _id: 1, title: "Abaji" },
      { _id: 2, title: "Bwari" },
      { _id: 3, title: "Gwagwalada" },
      { _id: 4, title: "Kuje" },
      { _id: 5, title: "Kwali" },
      { _id: 6, title: "Municipal Area Council" },
    ],
  },
];
