import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import disciplineApi from "../../api/Disciplines";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import TableLoading from "../../components/skeleton/TableLoading";
import { FaPlus } from "react-icons/fa";
import DisciplineTable from "../../components/tables/primeTable/disciplines/DisciplineTable";

const Disciplines = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [classCategoryData, setDisciplineData] = useState([]);
  const [disciplineModalVisibility, setDisciplineModalVisibility] =
    useState(false);
  const [selectedDisciplineDetail, setSelectedDisciplineDetail] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [modalType, setModalType] = useState("update");

  useEffect(() => {
    // fetch classes
    try {
      getDisciplines();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load classes. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { disciplineAdded: false, disciplineUpdated: false },
        });
      }
    }
  }, [user]);

  // function to get all disciplines
  const getDisciplines = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await disciplineApi.getDisciplines(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setDisciplineData([]);
      } else {
        setDisciplineData(response_data.response);
      }

      // check if a new class was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const disciplineAdded = !empty(paramState.disciplineAdded)
          ? paramState.disciplineAdded
          : false;
        const disciplineUpdated = !empty(paramState.disciplineUpdated)
          ? paramState.disciplineUpdated
          : false;
        if (disciplineAdded === true || disciplineUpdated === true) {
          const actionType = disciplineAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Discipline ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { disciplineAdded: false, disciplineUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  // delete discipline
  const deleteDiscipline = async () => {
    try {
      if (disciplineModalVisibility) setDisciplineModalVisibility(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const disciplineId =
        !empty(selectedDisciplineDetail) && !empty(selectedDisciplineDetail._id)
          ? selectedDisciplineDetail._id
          : "";
      const response = await disciplineApi.deleteDiscipline(
        disciplineId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to delete discipline";
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Discipline, ${
          !empty(selectedDisciplineDetail.title)
            ? selectedDisciplineDetail.title
            : ""
        }, was deleted successfully!`
      );
      return getDisciplines();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to hide confirm modal
  const hideDisciplineModal = () => {
    setDisciplineModalVisibility(false);
  };

  //go to update discipline page
  const goToUpdateDiscipline = (data) => {
    const disciplineId = !empty(data) && !empty(data._id) ? data._id : "";
    navigate(`/discipline/${disciplineId}/update`);
  };

  // delete discipline
  const showDeleteDisciplineModal = (data) => {
    setModalType("delete");
    setSelectedDisciplineDetail(data);
    setDisciplineModalVisibility(true);
  };

  const disciplineModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideDisciplineModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={deleteDiscipline}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              button
              buttonText="Add Discipline"
              link="/discipline/add"
              title="Disciplines"
              icon={<FaPlus />}
              iconMarginRight={5}
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <DisciplineTable
                classes={classCategoryData}
                goToUpdateDisciplineScreen={goToUpdateDiscipline}
                goToDeleteDisciplineScreen={showDeleteDisciplineModal}
              />
            ) : (
              <TableLoading rows={20} cols={3} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
      </AppWrapper>
      {isActionLoading && <FullPageLoader visible={isActionLoading} />}

      <Dialog
        visible={disciplineModalVisibility}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={disciplineModalFooter}
        onHide={hideDisciplineModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to delete the selected discipline?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default Disciplines;
