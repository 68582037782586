import client from "./Client";

const recordAssessment = (
  school_id,
  student_id,
  ca1 = 0,
  ca2 = 0,
  exam = 0,
  session_id,
  term_id,
  subject_id,
  token
) =>
  client.post(
    "/assessment/record",
    {
      school_id,
      student_id,
      ca1,
      ca2,
      exam,
      session_id,
      term_id,
      subject_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const addStudentPerformanceComment = (
  school_id,
  student_id,
  session_id = "",
  term_id = "",
  comment,
  token
) =>
  client.post(
    "/assessment/comment/update",
    {
      school_id,
      student_id,
      session_id,
      term_id,
      comment,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const removeAssessment = (
  school_id,
  student_id,
  session_id,
  term_id,
  subject_id,
  token
) =>
  client.post(
    "/assessment/remove",
    {
      school_id,
      student_id,
      session_id,
      term_id,
      subject_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const removeAssessments = (school_id, student_id, session_id, term_id, token) =>
  client.post(
    "/assessments/remove",
    {
      school_id,
      student_id,
      session_id,
      term_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

export default {
  recordAssessment,
  addStudentPerformanceComment,
  removeAssessment,
  removeAssessments,
};
